import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmationDialog } from 'components';
import {
    Button,
    Grid,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from '@material-ui/core';
import {
    addPushRequest,
    getPushRequest,
    periodicityListSelector,
    pushDataSelector,
    typesListSelector,
    updatePushRequest,
    deletePushRequest,
    runPushRequest
} from 'modules/pushes';
import {
    FormCore
} from 'components';
import style from './styles.scss';
import Select from 'react-select';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

class Push extends React.Component {
    state = {
        name: '',
        title: '',
        description: '',
        type_id: '',
        selectedOption: null,
        open_dialog: false,
        filters: {},
        device_type_id: 0,
        user_type: '',
        user_ids: '',
        dialog_func: ''
    };

    componentDidMount() {
        this.getPushData();
    }

    handleChangeForm = (data, name) => {
        const { ...state } = this.state;
        state[name] = data;
        this.setState({ ...state });
    };

    handleChangeInputs = ev => {
        this.handleChangeForm(ev.target.value, ev.target.name);
    };

    handlerCancelButton = () => {
        const { history } = this.props;
        history.push('/pushes');
    };

    handlerSubmit = () => {
        const { history, route, addPushRequest, updatePushRequest } = this.props;
        const {id, name, title, description, type_id, periodicity, device_type_id, user_type, user_ids} = this.state;
        let filtersData = {};
        if (parseInt(device_type_id) > 0) {
            filtersData.device_type_id = parseInt(device_type_id);
        }
        if (parseInt(user_type) === 1) {
            filtersData.is_master = 0;
        } else if(parseInt(user_type) === 2) {
            filtersData.is_master = 1;
        }
        if (user_ids) {
            filtersData.user_ids = user_ids;
        }
        filtersData = JSON.stringify(filtersData);
        if (!route.isAdd) {
            updatePushRequest({ id, title, description, type_id, periodicity, users_filter: filtersData}, { onSuccess: () => history.push('/pushes/') });
        }

        if (route.isAdd) {
            addPushRequest({ name, title, description, type_id, periodicity, users_filter: filtersData}, { onSuccess: () => history.push('/pushes/') });
        } else {
            updatePushRequest({ id, name, title, description, type_id, periodicity, users_filter: filtersData}, { onSuccess: () => history.push('/pushes/') });
        }
    };

    getPushData = () => {
        const {
            getPushRequest,
            route: { isAdd },
            match,
        } = this.props;
        if (!isAdd) {
            getPushRequest(match.params.id, {
                onSuccess: data =>
                {
                    this.setState({
                        ...data.data
                    });
                    const filters = JSON.parse(data.data.users_filter);
                    const device_type_id = filters.device_type_id ? filters.device_type_id : 0;
                    const user_ids = filters.user_ids ? filters.user_ids : '';
                    let user_type = 0;
                    if (filters.is_master === 1) {
                        user_type = 2
                    } else if (filters.is_master === 0) {
                        user_type = 1;
                    }

                    this.setState({device_type_id, user_type, user_ids});
                    this.setState({open_dialog: false});
                },
            });
        }
    };

    handleChangeSelect = (selectedOption, event) => {
        this.setState(
            { [event.name]: selectedOption.value }
        );
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };

    handleChangeRadio = ev => {
        this.setState({ [ev.target.name]:  parseInt(ev.target.value)});
    };

    render() {
        const {
            history,
            classes,
            permissions,
            route: { isAdd },
            types,
            periodicityData,
            updatePushRequest,
            deletePushRequest,
            runPushRequest
        } = this.props;

        const {
            id, name, title, description, type_id, periodicity, users_filter, is_active, is_running, deleted_at,
            device_type_id, user_type, user_ids,
            open_dialog, dialog_func
        } = this.state;

        const is_active_value = !is_active;

        return (
            <div className={classes.root}>
                <FormCore
                    permissions={permissions}
                    handlerSubmit={this.handlerSubmit}
                    handlerCancelButton={this.handlerCancelButton}
                    title={`Push ${isAdd ? 'creation' : 'edit'}  page`}
                >
                    <Grid container spacing={8} alignItems="center" justify="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                variant="outlined"
                                label="Push name"
                                name="name"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={name}
                                required
                                disabled={!permissions.update}
                            />
                            <TextField
                                variant="outlined"
                                label="Title"
                                name="title"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={title}
                                required
                                disabled={!permissions.update}
                            />

                            <TextField
                                variant="outlined"
                                label="Description"
                                name="description"
                                margin="normal"
                                multiline
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={description}
                                required
                                disabled={!permissions.update}
                            />

                            <div style={{ maxWidth: "60%"}}>
                                <h5>Type</h5>
                                <Select
                                    id="standard-name"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={types.find(item => item.value === type_id)}
                                    onChange={this.handleChangeSelect}
                                    options={types}
                                    label="Type"
                                    name="type_id"
                                    disabled={!permissions.update}
                                />
                            </div>

                            <div style={{ maxWidth: "60%"}}>
                                <h5>Periodicity</h5>
                                <Select
                                    id="standard-name"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={periodicityData.find(item => item.value === periodicity)}
                                    onChange={this.handleChangeSelect}
                                    options={periodicityData}
                                    label="Periodicity"
                                    name="periodicity"
                                    disabled={!permissions.update}
                                />
                            </div>
                            <h5>Users filter</h5>
                            <p>{users_filter}</p>
                            <Grid item xs={6}>
                                <FormControl component="fieldset" className={classes.formControl} onChange={this.handleChangeRadio}>
                                    <FormLabel component="legend">Device type</FormLabel>
                                    <RadioGroup aria-label="device_type_id" name="device_type_id" value={device_type_id} >
                                        <FormControlLabel value={0} control={<Radio />} label="NONE" />
                                        <FormControlLabel value={1} control={<Radio />} label="IOS" />
                                        <FormControlLabel value={2} control={<Radio />} label="Android" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl component="fieldset" className={classes.formControl} onChange={this.handleChangeRadio}>
                                    <FormLabel component="legend">Users/Masters</FormLabel>
                                    <RadioGroup aria-label="user_type" name="user_type" value={user_type} >
                                        <FormControlLabel value={0} control={<Radio />} label="None" />
                                        <FormControlLabel value={1} control={<Radio />} label="Users" />
                                        <FormControlLabel value={2} control={<Radio />} label="Masters" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    label="User ids"
                                    name="user_ids"
                                    margin="normal"
                                    multiline
                                    onChange={this.handleChangeInputs}
                                    classes={{ root: style.inputs }}
                                    value={user_ids}
                                    disabled={!permissions.update}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid item xs={6}>
                                <p>Status: {is_active ? 'ACTIVE' : 'DISABLED'}</p>
                                <Button
                                    variant="contained"
                                    color={is_active ? 'secondary' : 'primary'}
                                    className={classes.button}
                                    onClick={() => {this.setState({open_dialog: true, dialog_func: 'activate'})}}
                                >
                                    {is_active ? 'Disable' : 'Enable'}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <p>{deleted_at ? 'Already deleted' : ''}</p>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    className={classes.button}
                                    onClick={() => {this.setState({open_dialog: true, dialog_func: 'delete'})}}
                                    disabled={deleted_at}
                                >
                                    Delete
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <p>Processing: {is_running ? 'RUNNING' : ''}</p>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    className={classes.button}
                                    disabled={is_running || deleted_at}
                                    onClick={() => {this.setState({open_dialog: true, dialog_func: 'run'})}}
                                >
                                    Run
                                </Button>
                                <ConfirmationDialog
                                    open_dialog={open_dialog}
                                    cancelFunc={() => this.handleCloseDialog()}
                                    confirmationFunc={
                                        dialog_func === 'activate' ?
                                            () => updatePushRequest({id, is_active: is_active_value}, { onSuccess: () => {this.getPushData() }}) :
                                            (dialog_func === 'delete' ?
                                                () => deletePushRequest({id}, { onSuccess: () => history.push('/pushes/') }) :
                                                () => runPushRequest({id}, { onSuccess: () => {this.getPushData();}}))
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </FormCore>
            </div>
        );
    }
}

Push.propTypes = {
    history: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    updatePushRequest: PropTypes.func.isRequired,
    deletePushRequest: PropTypes.func.isRequired,
    getPushRequest: PropTypes.func.isRequired,
    runPushRequest: PropTypes.func.isRequired,
    addPushRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired,
    periodicityData: PropTypes.array.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    pushData: pushDataSelector(state),
    types: typesListSelector(state),
    periodicityData: periodicityListSelector(state)
});

const mapDispatchToProps = dispatch => ({
    addPushRequest: bindActionCreators(addPushRequest, dispatch),
    updatePushRequest: bindActionCreators(updatePushRequest, dispatch),
    getPushRequest: bindActionCreators(getPushRequest, dispatch),
    deletePushRequest: bindActionCreators(deletePushRequest, dispatch),
    runPushRequest: bindActionCreators(runPushRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Push));