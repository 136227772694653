import * as api_helpers from 'api';
import { createAction } from 'redux-actions';
import { all, put, takeLatest, delay } from 'redux-saga/effects';

import get from 'lodash/get';
const modules = 'chat';
const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = api_helpers;
const apiRoutes = new ApiRoutes();

export const INIT_LIST_CHAT = `${modules}/INIT_LIST_CHAT`;
export const GET_LIST_CHATS_REQUEST = `${modules}/GET_LIST_CHATS_REQUEST`;
export const OPEN_CHAT_BY_USER = `${modules}/OPEN_CHAT_BY_USER`;
export const LISTEN_CHAT = `${modules}/LISTEN_CHAT`;
export const STOP_LISTEN_CHAT = `${modules}/STOP_LISTEN_CHAT`;
export const CREATE_CHAT_REQUEST = `${modules}/CREATE_CHAT_REQUEST`;
export const SEND_MESSAGE_REQUEST = `${modules}/SEND_MESSAGE_REQUEST`;
export const GET_CHAT_BY_ID_REQUEST = `${modules}/GET_CHAT_BY_ID_REQUEST`;
export const GET_CHAT_BY_MASTER_ID_REQUEST = `${modules}/GET_CHAT_BY_MASTER_ID_REQUEST`;
export const DELETE_MESSAGE_REQUEST = `${modules}/DELETE_MESSAGE_REQUEST`;

//actions
export const openChat = createAction(OPEN_CHAT_BY_USER);
export const startListenChat = createAction(LISTEN_CHAT);
export const stopListenChat = createAction(STOP_LISTEN_CHAT);
export const initListChat = createAction(INIT_LIST_CHAT);

export const getChatsListRequest = actionCreator(GET_LIST_CHATS_REQUEST);
export const getChatByIdRequest = actionCreator(GET_CHAT_BY_ID_REQUEST);
export const getChatByMasterIdRequest = actionCreator(
    GET_CHAT_BY_MASTER_ID_REQUEST,
);
export const sendMessageRequest = actionCreator(SEND_MESSAGE_REQUEST);
export const createChatRequest = actionCreator(CREATE_CHAT_REQUEST);
export const deleteMessageRequest = actionCreator(DELETE_MESSAGE_REQUEST);


// sagas
const getChatsListSaga = function*(action) {
    const { payload } = action;
    while (true) {
        yield put(getChatsListRequest({...payload}));
        yield delay(12000);
    }
};

const getChatByIdSaga = function*(dispatch, action) {
    const { payload } = action;
    yield delay(3000);
    if (payload.id) {
        yield put(getChatByIdRequest({ ...payload, showLoader: false }));
        yield put(startListenChat({ id: payload.id }));
    }
};

const openChatSaga = function*(dispatch, action) {
    const { payload } = action;
    if (payload) {
        yield put(
            getChatByMasterIdRequest(
                { id: payload },
                {
                    onSuccess: (data) => {
                        const id = get(data, 'data.id');
                        if (id) {
                            history.push(`/chats/${id}`);
                        }
                    },
                    onFailure: () => {
                        dispatch(
                            createChatRequest(
                                { id: payload },
                                {
                                    onSuccess: (data) => {
                                        const id = get(data, 'data.id');
                                        if (id) {
                                            history.push(
                                                `/chats/${id}`,
                                            );
                                        }
                                    },
                                },
                            ),
                        );
                    },
                },
            ),
        );
    }
};

export const chatSaga = function*(dispatch) {
    yield all([
        takeLatest(INIT_LIST_CHAT, getChatsListSaga),
        takeLatest([LISTEN_CHAT, STOP_LISTEN_CHAT], getChatByIdSaga, dispatch),
        takeLatest(OPEN_CHAT_BY_USER, openChatSaga, dispatch),
    ]);
};

//routes
export const listSkillsRoutes = {};
apiRoutes.add(GET_LIST_CHATS_REQUEST, (data) => ({
    url: `admin/chats`,
    method: 'get',
    showLoaderFlag: false,
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));
apiRoutes.add(GET_CHAT_BY_ID_REQUEST, ({ id, showLoader = false }) => ({
    url: `admin/chats/${id}`,
    method: 'get',
    showLoaderFlag: showLoader,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));
apiRoutes.add(GET_CHAT_BY_MASTER_ID_REQUEST, ({ id }) => ({
    url: `/api/getChatByParams`,
    method: 'get',
    params: { companionId: id },
}));
apiRoutes.add(CREATE_CHAT_REQUEST, ({ id }) => ({
    url: `/api/createChat`,
    method: 'post',
    data: { companionId: id },
}));
apiRoutes.add(SEND_MESSAGE_REQUEST, ({ chatId, type, text, imageData }) => ({
    url: `admin/chats/${chatId}/message`,
    method: 'post',
    data: { chatId, type, text, imageData },
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_MESSAGE_REQUEST, ({ chatId, messageId }) => ({
    url: `admin/chats/${chatId}/message/${messageId}`,
    method: 'delete',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));
//selectors

export const getChatsListSelector = apiSelector(GET_LIST_CHATS_REQUEST/*, {
    resultPrepareCalback: (data) => {
        console.log('data');
        const allMessages = data.items ? data.items : [];
        const unreaded = allMessages.filter(
            (item) =>
                !item.last_message.is_read &&
                item.companion.id === item.last_message.sender_id,
        );
        const readed = allMessages
            .filter(
                (item) =>
                    item.last_message.is_read ||
                    item.companion.id !== item.last_message.sender_id,
            );
            /!*.map((item) => {
                const newItem = { ...item };
                newItem.last_message.is_read = -1;
                return newItem;
            });*!/
        const items = [...unreaded, ...readed];
        const size = data.size;
        return {items, size};
    },
}*/);

export const getChatByIdSelector = apiSelector(GET_CHAT_BY_ID_REQUEST/*,{
    resultPrepareCalback: (data) => get(data, 'result', {}),
}*/);
export const getChatByMasterIdSelector = apiSelector(
    GET_CHAT_BY_MASTER_ID_REQUEST,
);
export const sendMessageSelector = apiSelector(SEND_MESSAGE_REQUEST);
export const createChatSelector = apiSelector(CREATE_CHAT_REQUEST);
