import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';
import style from './style.scss';
const EditSave = ({
    onSave = () => {},
    value,
    children,
    onDelete = () => {},
    onSwitchEdit = () => {},
    showDelete = true,
    showEdit = true,
    edit = false,
    onChange = () => {},
    openHtmlEdit = false,
    labelText = '',
    ...props
}) => {
    const dispatch = useDispatch();
    const [str, strState] = React.useState(value);
    const [editState, switchEdit] = React.useState(false);
    const editor = React.useRef(null);
    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
    };
    React.useEffect(() => {
        switchEdit(edit);
    }, [edit]);
    React.useEffect(() => {
        onChange(str);
    }, [str]);

    return (
        <div className={[style.container, openHtmlEdit && editState ? style.popup : ''].join(' ')}>
            {openHtmlEdit && editState ? (
                <JoditEditor
                    ref={editor}
                    value={str}
                    config={config}
                    tabIndex={-1} // tabIndex of textarea
                    onBlur={ev => strState(ev.target.innerHTML)}
                    onChange={newContent => {}}
                />
            ) : (
                <label className={style.label}>
                    {labelText}
                    <input
                        className={style.input}
                        value={str}
                        onChange={ev => strState(ev.target.value)}
                        {...props}
                        disabled={!editState}
                    />
                </label>
            )}
            {showEdit && (
                <button
                    className={style.button}
                    onClick={() => {
                        if (editState) {
                            console.log(str);
                            onSave(str);
                        }

                        switchEdit(!editState);
                        onSwitchEdit(!editState);
                    }}
                >
                    {editState ? (
                        <SaveIcon className={style.icon} />
                    ) : (
                        <EditIcon className={style.icon} />
                    )}
                </button>
            )}
            {showDelete && (
                <button
                    className={style.button}
                    onClick={() => {
                        onDelete(dispatch);
                    }}
                >
                    <DeleteIcon className={style.icon} htmlColor="rgb(255, 13, 13)" />
                </button>
            )}
            <div>{children}</div>
        </div>
    );
};

EditSave.propTypes = {
    value: PropTypes.string,
};

export default EditSave;
