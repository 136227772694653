import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import UserSkillsRow from '../UserSkillsRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const UserSkills = ({
    rows, deleteSkillRequest, updateSkillRequest, skillsList, attachSkillRequest
}) => {
    const classes = useStyles();
    const user_id = 1;
    return (
        <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Title</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center" style={{ padding: "30px" }}>Parent</TableCell>
                        <TableCell align="center">From</TableCell>
                        <TableCell align="center">To</TableCell>
                        <TableCell align="center">Duration</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <UserSkillsRow
                            key={row.id}
                            row={row}
                            creation={false}
                            deleteSkillRequest={deleteSkillRequest}
                            updateSkillRequest={updateSkillRequest}
                            attachSkillRequest={attachSkillRequest}
                            skillsList={[]}
                        />
                    ))}
                    <UserSkillsRow
                        key={'create'}
                        row={{user_id}}
                        creation
                        deleteSkillRequest={deleteSkillRequest}
                        updateSkillRequest={updateSkillRequest}
                        attachSkillRequest={attachSkillRequest}
                        skillsList={skillsList}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
};
UserSkills.propTypes = {
    rows: PropTypes.array.isRequired,
    deleteSkillRequest: PropTypes.func.isRequired,
    updateSkillRequest: PropTypes.func.isRequired,
    attachSkillRequest: PropTypes.func.isRequired,
    skillsList: PropTypes.array.isRequired
};
export default UserSkills;