import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextMask from './TextMask.js';
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function FormattedInputs({ value, onChange, name, disabled }) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        textmask: value,
    });
    React.useEffect(() => {
        // setValues({ textmask: value });
        setValues({
            ...values,
            textmask: value,
        });
    }, [value]);
    const handleChange = nameField => event => {
        setValues({
            ...values,
            [nameField]: event.target.value,
        });
        onChange({ target: { value: event.target.value.replace(/\D/g, ''), name: name } });
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink focused htmlFor="formatted-text-mask-input">
                Phone
            </InputLabel>
            <Input
                disabled={disabled}
                value={values.textmask}
                name={name}
                onChange={handleChange('textmask')}
                id="formatted-text-mask-input"
                inputComponent={TextMask}
            />
        </FormControl>
    );
}
FormattedInputs.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};
