import { ROLES_KEYS } from 'config/roles';
import {
    LoginPage,
    UsersList,
    Home,
    User,
    RolesList,
    RoleEdit,
    CustomersList,
    Customer,
    UpdatePassword,
    Skill,
    SkillsList,
    Order,
    OrdersList,
    EventsList,
    FeedbacksList,
    Statistics,
    PushesList,
    Push,
    Chat,
    Advert,
    AdvertsList,
    FaqList,
    Salon,
    SalonsList
} from 'containers';
export const redirectAuthPath = '/login';

//* layout used for   set layouts for pages
//* if layout ===  false   we use page without layout
//* if layout is  react elemen - we use it
const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: Home,
        isPrivate: true,
        header: true,
        // layout: false,
    },
    {
        path: '/login',
        exact: true,
        component: LoginPage,
        isPrivate: false,
        header: true,
        layout: false,
    },
    {
        path: '/faq',
        exact: true,
        component: FaqList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.faq,
    },
    {
        path: '/update_password',
        exact: true,
        component: UpdatePassword,
        isPrivate: true,
        header: true,
        // layout: false,
    },
    {
        path: '/masters',
        exact: true,
        component: UsersList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.users,
    },
    {
        path: '/create-user',
        exact: true,
        component: User,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.users,
    },
    {
        path: '/master/:id',
        exact: true,
        component: User,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.users,
    },
    {
        path: '/roles-list',
        exact: true,
        component: RolesList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/role/:id',
        exact: true,
        component: RoleEdit,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/create-role',
        exact: true,
        component: RoleEdit,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/customers',
        exact: true,
        component: CustomersList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.customers,
    },
    {
        path: '/customer/:id',
        exact: true,
        component: Customer,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.customers,
    },
    {
        path: '/create-customer',
        exact: true,
        component: Customer,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.customers,
    },
    {
        path: '/skills',
        exact: true,
        component: SkillsList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.skills,
    },
    {
        path: '/skill/:id',
        exact: true,
        component: Skill,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.skills,
    },
    {
        path: '/create-skill',
        exact: true,
        component: Skill,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.skills,
    },
    {
        path: '/orders',
        exact: true,
        component: OrdersList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.orders,
    },
    {
        path: '/order/:id',
        exact: true,
        component: Order,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.orders,
    },
    {
        path: '/events',
        exact: true,
        component: EventsList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.events,
    },
    {
        path: '/feedbacks',
        exact: true,
        component: FeedbacksList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.feedbacks,
    },
    {
        path: '/statistics',
        exact: true,
        component: Statistics,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.statics,
    },
    {
        path: '/pushes',
        exact: true,
        component: PushesList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.pushes,
    },
    {
        path: '/push/:id',
        exact: true,
        component: Push,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.pushes,
    },
    {
        path: '/create-push',
        exact: true,
        component: Push,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.pushes,
    },
    {
        path: '/chats/',
        exact: true,
        component: Chat,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.chats,
    },
    {
        path: '/chats/:chatId',
        exact: true,
        component: Chat,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.chats,
    },
    {
        path: '/adverts',
        exact: true,
        component: AdvertsList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.adverts,
    },
    {
        path: '/advert/:id',
        exact: true,
        component: Advert,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.adverts,
    },
    {
        path: '/create-advert',
        exact: true,
        component: Advert,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.adverts,
    },
    {
        path: '/salons',
        exact: true,
        component: SalonsList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.salons,
    },
    {
        path: '/salon/:id',
        exact: true,
        component: Salon,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.salons,
    },
];
export default [].concat(mainRoutes);
