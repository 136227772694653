import * as apiHelpers from 'api';
const modules = 'orders';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_ORDERS_LIST_REQUEST = `${modules}/GET_ORDERS_LIST_REQUEST`;
export const GET_ORDER_REQUEST = `${modules}/GET_ORDER_REQUEST`;
export const UPDATE_ORDER_REQUEST = `${modules}/UPDATE_ORDER_REQUEST`;
export const DELETE_ORDERS_REQUEST = `${modules}/DELETE_ORDERS_REQUEST`;

export const getOrdersListRequest = actionCreator(GET_ORDERS_LIST_REQUEST);
export const getOrderRequest = actionCreator(GET_ORDER_REQUEST);
export const updateOrderRequest = actionCreator(UPDATE_ORDER_REQUEST);
export const deleteOrdersRequest = actionCreator(DELETE_ORDERS_REQUEST);

apiRoutes.add(GET_ORDERS_LIST_REQUEST, data => {
    // status_id = 1 - requests
    data.status_id = 1;
    return {
        url: `/admin/events`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(GET_ORDER_REQUEST, data => ({
    url: `/events/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_ORDER_REQUEST, data => ({
    url: `/admin/events/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

// TODO
apiRoutes.add(DELETE_ORDERS_REQUEST, data => {
    return {
        url: `/orders/${data}`,
        method: 'delete',
        params: { id: data },
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});


export const orderDataSelector = apiSelector(GET_ORDER_REQUEST, {
    initialData: {
        id: '',
        user_id: '',
        status_id: '',
        master_id: '',
        date: '',
        duration: '',
        address: '',
        lat: '',
        long: '',
        salon_id: '',
        salon: {
            title: ''
        }
    },
});

export const ordersListSelector = apiSelector(GET_ORDERS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
