import React, { Fragment } from 'react';
import style from './style.scss';
const ImageUpload = ({ image, onChange}) => {
    const [imageState, setImage] = React.useState(image);
    React.useEffect(() => {
        setImage(imageState);
    }, [image]);

    return (
        <Fragment>
            <label className={style.label}>
                <input
                    onChange={ev => {
                        const file = ev.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = reader => {
                                const str = reader.target.result;
                                const ext = file.name.split('.').pop().toLowerCase();
                                const image = 'data:image/' + ext + ';base64,' + btoa(str);
                                setImage(image);
                                onChange(image);
                            };
                            reader.readAsBinaryString(file);
                        }
                    }}
                    type="file"
                    name="image"
                    accept=".jpeg, .png, .jpg"
                />
            </label>
        </Fragment>
    );
};

ImageUpload.propTypes = {
    // : PropTypes.
};

export default ImageUpload;
