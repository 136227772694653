import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loaderStatusSelector } from 'modules/loader';

const Loader = ({ showLoader }) =>
    showLoader ? (
        <div id="fuse-splash-screen">
            <div className="center">
           {/*     <div className="logo">
                    <img width="128" src="assets/images/logos/logo.svg" alt="logo" />
                </div>*/}

                <div className="spinner-wrapper">
                    <div className="spinner">
                        <div className="inner">
                            <div className="gap" />
                            <div className="left">
                                <div className="half-circle" />
                            </div>
                            <div className="right">
                                <div className="half-circle" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;

Loader.propTypes = {
    showLoader: PropTypes.bool,
};

Loader.defaultProps = {
    showLoader: false,
};
const mapStateToProps = state => ({
    showLoader: loaderStatusSelector(state),
});
export default connect(mapStateToProps)(Loader);
