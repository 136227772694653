import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import AppContainer from 'containers/App';
import { store, persistor } from 'store';

function App() {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <AppContainer />
            </PersistGate>
        </Provider>
    );
}

export default App;
