import React from 'react';
import { addValueToReactComponent } from 'components/Table';
import Icon from '@material-ui/core/Icon';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import { get } from 'lodash';
import { CheckboxDialog } from 'components';

export const usersDatePrepare = (list = [], updateUserRequest) => {
        return Array.isArray(list)
        ? list.map(item => {
                const newItem = { ...item };
                const deviceType = get(item, 'device_token.device_type_id', 0);
                const first_name = get(item, 'first_name', '');
                const last_name = get(item, 'last_name', '');
                const address = get(item, 'address.address', '');
                const city = get(item, 'address.city', '');
                const place = get(item, 'address.place', '');
                const lat = get(item, 'address.lat', '');
                const long = get(item, 'address.long', '');
                const instagram = get(item, 'profile.instagram', '');

                newItem.name = addValueToReactComponent(
                    first_name + ' ' + last_name,
                    newItem.is_deleted
                );

                newItem.is_deleted = addValueToReactComponent(
                    item.is_deleted ? <Icon>done</Icon> : '',
                    newItem.is_deleted
                );
                newItem.device_token = addValueToReactComponent(
                    deviceType === 1 ? <AppleIcon/> : deviceType === 2 ? <AndroidIcon/> : '',
                    newItem.device_token
                );
                newItem.city = addValueToReactComponent(
                    city,
                    newItem.city
                );
                newItem.address = addValueToReactComponent(
                    address + ' | ' + place + ' | ' + lat + ' ' + long,
                    newItem.address
                );
                newItem.instagram = addValueToReactComponent(
                    instagram,
                    newItem.instagram
                );
                newItem.hidden = addValueToReactComponent(
                    <CheckboxDialog active={item.hidden} options={{id: item.id}} setValue={updateUserRequest}/>,
                    newItem.hidden
                );
                return newItem;
        }) : [];
};
