import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { updatePasswordRequest, userLogout } from 'modules/auth';
import { showError, showWarning } from 'modules/notifications';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const phrase = 'The password must include uppercase and lowercase letters, numbers, and symbols.';
const phraseMatch = 'Your password and confirmation password do not match.';

function UpdatePassword({ updatePasswordRequest, userLogout, showWarning }) {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                <form
                    className={classes.form}
                    onSubmit={ev => {
                        const formData = new FormData(ev.target);
                        const conf_pass = formData.get('confirm_password');
                        const password = formData.get('password');
                        if (password.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/) === -1) {
                            showWarning({ message: phrase });
                        } else {
                            if (password === conf_pass) {
                                updatePasswordRequest(
                                    {
                                        currentPassword: formData.get('old_password'),
                                        newPassword: password,
                                    },
                                    { onSuccess: userLogout }
                                );
                            } else {
                                showWarning({
                                    message: phraseMatch,
                                });
                            }
                        }
                        ev.preventDefault();
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="old_password"
                        label="Old password"
                        type="password"
                        id="old_password"
                        autoComplete="current-password"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New password"
                        type="password"
                        id="new_password"
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                        autoComplete="current-password"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirm_password"
                        label="Confirm new password"
                        type="password"
                        id="confirm_password"
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </Container>
    );
}

UpdatePassword.propTypes = {
    updatePasswordRequest: PropTypes.func,
    showError: PropTypes.func,
    showWarning: PropTypes.func,
    userLogout: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    updatePasswordRequest: bindActionCreators(updatePasswordRequest, dispatch),
    showError: bindActionCreators(showError, dispatch),
    showWarning: bindActionCreators(showWarning, dispatch),
    userLogout: bindActionCreators(userLogout, dispatch),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePassword);
