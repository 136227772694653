import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EditSave from '../EidtSaveInput';
import Question from '../Question';
import style from './style.scss';

const Questions = ({
    subCategoryId,
    categoryId,
    items = [],
    onSaveQuestion,
    onDeleteQuestion,
    onAddQuestion,
}) => {
    const [questions, setQuestions] = React.useState([...items]);
    React.useEffect(() => {
        setQuestions([...items]);
    }, [items]);
    return (
        <div>
            {questions.map(i => (
                <Question
                    key={i.id}
                    data={i}
                    categoryId={categoryId}
                    subCategoryId={subCategoryId}
                    onDeleteQuestion={
                        i.empty
                            ? () => {
                                  setQuestions([...questions.filter(item => item.id !== i.id)]);
                              }
                            : onDeleteQuestion
                    }
                    onSaveQuestion={
                        !i.empty
                            ? onSaveQuestion
                            : data => {
                                  onAddQuestion({
                                      id_cat: categoryId,
                                      id_sub: subCategoryId,
                                      ...data,
                                  });
                              }
                    }
                />
            ))}
            <button
                className={style.button}
                onClick={() =>
                    setQuestions([
                        ...questions,
                        { id: questions.length, empty: true, answer: '', question: '' },
                    ])
                }
            >
                Добавить вопрос
            </button>
        </div>
    );
};

Questions.propTypes = {
    // : PropTypes.
};

export default Questions;
