import React from 'react';
import { addValueToReactComponent } from 'components/Table';
import {get} from 'lodash';
import { Close, Done, Delete, ThumbUp, ThumbDown } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

export const advertsDatePrepare = (list = [], handleClickOpenDialog) => {

    const arr = Array.isArray(list)
        ? list.map(item => {
            const newItem = { ...item };
            const type = get(item, 'type.title', 0);

            newItem.type_id = addValueToReactComponent(
                type,
                newItem.type_id
            );

            newItem.is_moderated = addValueToReactComponent(
                item.is_moderated ? <Done/>: <Close/>,
                newItem.is_moderated
            );

            newItem.is_free = addValueToReactComponent(
                item.is_free ? <Done/>: <Close/>,
                newItem.is_free
            );

            newItem.dt_moderated = addValueToReactComponent(
                item.dt_moderated ? (
                        <div>
                            <IconButton onClick={() => handleClickOpenDialog( item.id, 'delete')}>
                                <Delete/>
                            </IconButton>
                        </div>
                    ) :
                    (
                        <div>
                            <IconButton onClick={() => handleClickOpenDialog( item.id, 'approve')}>
                                <ThumbUp/>
                            </IconButton>
                            <IconButton onClick={() => handleClickOpenDialog( item.id, 'decline')}>
                                <ThumbDown/>
                            </IconButton>
                            <IconButton onClick={() => handleClickOpenDialog( item.id, 'delete')}>
                                <Delete/>
                            </IconButton>
                        </div>
                    ),
                newItem.dt_moderated
            );

            return newItem;
        })
        : [];
    return arr;
};