import * as apiHelpers from 'api';
const modules = 'fares';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_FARES_LIST_REQUEST = `${modules}/GET_FARES_LIST_REQUEST`;

export const getFaresListRequest = actionCreator(GET_FARES_LIST_REQUEST);

apiRoutes.add(GET_FARES_LIST_REQUEST, data => ({
    url: `/fares`,
    method: 'get',
    params: { zone: data },
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const faresSelector = apiSelector(GET_FARES_LIST_REQUEST, {
    // initialData: { items: [], size: 0 },
});
