//
import * as apiHelpers from 'api';
import { UPDATE_USER_REQUEST } from './user';
const modules = 'salons';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_SALONS_LIST_REQUEST = `${modules}/GET_SALONS_LIST_REQUEST`;
export const GET_SALON_REQUEST = `${modules}/GET_SALON_REQUEST`;
export const DELETE_SALON_REQUEST = `${modules}/DELETE_SALON_REQUEST`;
export const DELETE_SALON_IMAGE_REQUEST = `${modules}/DELETE_SALON_IMAGE_REQUEST`;
export const UP_SALON_IMAGE_REQUEST = `${modules}/UP_SALON_IMAGE_REQUEST`;
export const CHANGE_TYPE_SALON_IMAGE_REQUEST = `${modules}/CHANGE_TYPE_SALON_IMAGE_REQUEST`;
export const CHANGE_SALON_PORTFOLIO_TAGS_REQUEST = `${modules}/CHANGE_SALON_PORTFOLIO_TAGS_REQUEST`;
export const SET_SALON_SKILLS_REQUEST = `${modules}/SET_SALON_SKILLS_REQUEST`;
export const SET_SALON_SERVICES_REQUEST = `${modules}/SET_SALON_SERVICES_REQUEST`;
export const ADD_SALON_PAYMENT_REQUEST = `${modules}/ADD_SALON_PAYMENT_REQUEST`;
export const UPDATE_SALON_REQUEST = `${modules}/UPDATE_SALON_REQUEST`;
export const SYNC_SALON_REQUEST = `${modules}/SYNC_SALON_REQUEST`;

export const getSalonsListRequest = actionCreator(GET_SALONS_LIST_REQUEST);
export const getSalonRequest = actionCreator(GET_SALON_REQUEST);
export const deleteSalonRequest = actionCreator(DELETE_SALON_REQUEST);
export const deleteSalonImageRequest = actionCreator(DELETE_SALON_IMAGE_REQUEST);
export const upSalonImageRequest = actionCreator(UP_SALON_IMAGE_REQUEST);
export const changeTypeSalonImageRequest = actionCreator(CHANGE_TYPE_SALON_IMAGE_REQUEST);
export const changeSalonPortfolioTagsRequest = actionCreator(CHANGE_SALON_PORTFOLIO_TAGS_REQUEST);
export const setSalonSkillsRequest = actionCreator(SET_SALON_SKILLS_REQUEST);
export const setSalonServicesRequest = actionCreator(SET_SALON_SERVICES_REQUEST);
export const addSalonPaymentRequest = actionCreator(ADD_SALON_PAYMENT_REQUEST);
export const updateSalonRequest = actionCreator(UPDATE_SALON_REQUEST);
export const syncSalonRequest = actionCreator(SYNC_SALON_REQUEST);

apiRoutes.add(GET_SALONS_LIST_REQUEST, data => {
    if (
        data.hasOwnProperty('orderBy') &&
        data.hasOwnProperty('order') &&
        data.hasOwnProperty('dense') &&
        data.orderBy === 'id' &&
        data.order === 'asc'
    ) {
        //set default order as id desc
        data.order = 'desc';
    }

    return {
        url: `admin/salons`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(GET_SALON_REQUEST, id => {
    return {
        url: `admin/salons/${id}`,
        method: 'get',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(DELETE_SALON_REQUEST, data => {
    return {
        url: `admin/salons/${data.id}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(DELETE_SALON_IMAGE_REQUEST, data => {
    return {
        url: `admin/images/${data.image_id}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(UP_SALON_IMAGE_REQUEST, data => {
    return {
        url: `admin/salons/${data.salon_id}/images/${data.image_id}/up`,
        method: 'post',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(CHANGE_TYPE_SALON_IMAGE_REQUEST, data => {
    return {
        url: `admin/salons/${data.salon_id}/images/${data.image_id}/type`,
        method: 'post',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(CHANGE_SALON_PORTFOLIO_TAGS_REQUEST, data => ({
    url: `admin/salons/${data.salon_id}/portfolio/${data.portfolio_id}/tags`,
    method: 'post',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(SET_SALON_SKILLS_REQUEST, data => {
    return {
        url: `admin/salons/${data.salon_id}/skills`,
        method: 'post',
        data: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(SET_SALON_SERVICES_REQUEST, data => {
    return {
        url: `admin/salons/${data.salon_id}/services`,
        method: 'post',
        data: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(ADD_SALON_PAYMENT_REQUEST, data => {
    return {
        url: `admin/salons/${data.salon_id}/purchases`,
        method: 'post',
        data: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(UPDATE_SALON_REQUEST, data => ({
    url: `admin/salons/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));
apiRoutes.add(SYNC_SALON_REQUEST, data => ({
    url: `admin/salons/${data.id}/sync`,
    method: 'post',
    data: { url: data.url, type: data.type },
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const salonDataSelector = apiSelector(GET_SALON_REQUEST, {
    initialData: {
        id: '',
        title: '',
        created_at: '',
        phone: '',
        avatar: '',
        chat_id: 0,
        location: {
            country: '',
            city: '',
            address: '',
            place: '',
            metro_station: {
                title: '',
            },
        },
        info: {
            site: '',
            instagram: '',
            description: '',
            working_time_interval: 0,
            min_time_until_event: 0,
            contact_phone: '',
        },
        skills: [],
        services: [],
        users: [],
        portfolio_images: [],
        working_place_images: [],
        payments: [],
        bills: [],
    },
});

export const salonsListSelector = apiSelector(GET_SALONS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
