import qs from 'query-string';

export const getFitersDataFromUrl = url => {
    const search = qs.parse(url);
    return typeof search.filter !== 'undefined' ? JSON.parse(search.filter) : {};
};

export const addFiltersToUrl = (url, filter) => {
    return Object.keys(filter).length > 0 ? `${url}?filter=${JSON.stringify(filter)}` : url;
};
