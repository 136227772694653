import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';
const ImageUplod = ({ disabled, image, onChange, labelText, text }) => {
    const [imageState, setImage] = React.useState(image);
    React.useEffect(() => {
        setImage(imageState);
    }, [image]);

    return (
        <Fragment>
            <label className={style.label}>
                Изображение
                <input
                    onChange={ev => {
                        const file = ev.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = reader => {
                                const str = reader.target.result;
                                const image = 'data:image/png;base64,' + btoa(str);
                                setImage(image);
                                onChange(image);
                            };
                            reader.readAsBinaryString(file);
                        }
                    }}
                    disabled={disabled}
                    type="file"
                    name="image"
                    accespt=".jpeg, .png , .png"
                />
            </label>
            <div className={style.previewContainer}>
                <div style={{ backgroundImage: `url(${imageState})` }} className={style.preview}>
                    <div className={style.titleText}>{text}</div>
                </div>
            </div>
        </Fragment>
    );
};

ImageUplod.propTypes = {
    // : PropTypes.
};

export default ImageUplod;
