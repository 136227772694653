import * as apiHelpers from 'api';
const modules = 'regions';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_REGIONS_LIST_REQUEST = `${modules}/GET_REGIONS_LIST_REQUEST`;
export const GET_REGIONS_LIST_TICKET_REQUEST = `${modules}/GET_REGIONS_LIST_TICKET_REQUEST`;
export const GET_ZONES_LIST_BY_REGION_REQUEST = `${modules}/GET_ZONES_LIST_BY_REGION_REQUEST`;

export const getRegionsListRequest = actionCreator(GET_REGIONS_LIST_REQUEST);
export const getRegionsRequest = actionCreator(GET_REGIONS_LIST_TICKET_REQUEST);
export const getZonesListByRegionRequest = actionCreator(GET_ZONES_LIST_BY_REGION_REQUEST);

apiRoutes.add(GET_REGIONS_LIST_REQUEST, data => ({
    url: `/alerts/regions`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_REGIONS_LIST_TICKET_REQUEST, data => ({
    url: `/regions`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_ZONES_LIST_BY_REGION_REQUEST, data => ({
    url: `/regions/${data}/zones`,
    method: 'get',
    // params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const regionsSelector = apiSelector(GET_REGIONS_LIST_REQUEST, {
    // initialData: { items: [], size: 0 },
});
export const regionsListSelector = apiSelector(GET_REGIONS_LIST_TICKET_REQUEST);
export const zonesListByRegionSelector = apiSelector(GET_ZONES_LIST_BY_REGION_REQUEST);
