import {
    Event,
    EventAvailable,
    Equalizer,
    Feedback,
    List,
    Notifications,
    Person,
    PeopleOutline,
    Chat,
    AddBox,
    LiveHelp,
    HomeWork
} from '@material-ui/icons';

export default [
    { to: '/statistics', title: 'Statistics', icon: Equalizer },
    { to: '/masters', title: 'Masters', icon: PeopleOutline },
    { to: '/customers', title: 'Customers', icon: Person },
    { to: '/skills', title: 'Skills', icon: List },
    { to: '/orders', title: 'Requests', icon: Event },
    { to: '/events', title: 'Events', icon: EventAvailable },
    { to: '/feedbacks', title: 'Feedbacks', icon: Feedback },
    { to: '/pushes', title: 'Push notifications', icon: Notifications },
    { to: '/chats', title: 'Chats', icon: Chat },
    { to: '/adverts', title: 'Adverts', icon: AddBox },
    { to: '/faq', title: 'Faq', icon: LiveHelp },
    { to: '/salons', title: 'Salons', icon: HomeWork },
];
