import * as apiHelpers from 'api';
const modules = 'statistics';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_STATISTICS_REQUEST = `${modules}/GET_STATISTICS_REQUEST`;

export const getStatisticsRequest = actionCreator(GET_STATISTICS_REQUEST);

apiRoutes.add(GET_STATISTICS_REQUEST, data => {
    return {
        url: `/admin/stats`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

export const statisticsDataSelector = apiSelector(GET_STATISTICS_REQUEST, {
    initialData: {
        users: {
            all: {
                count: '',
                new: {
                    count: ''
                },
                incomplete_registrations: {
                    count: ''
                },
                russia: {
                    count: '',
                    new: {
                        count: ''
                    },
                    previous_day: {
                        new: {
                            count: ''
                        },
                    },
                    previous_2days: {
                        new: {
                            count: ''
                        },
                    }
                },
                belarus: {
                    previous_day: {
                        new: {
                            count: ''
                        },
                    },
                    previous_2days: {
                        new: {
                            count: ''
                        },
                    }
                }
            },
            masters: {
                count: '',
                percent: '',
                new: {
                    count: ''
                },
                russia: {
                    count: '',
                    percent: '',
                    new: {
                        count: ''
                    },
                    previous_day: {
                        new: {
                            count: ''
                        },
                    },
                    previous_2days: {
                        new: {
                            count: ''
                        },
                    }
                },
                belarus: {
                    previous_day: {
                        new: {
                            count: ''
                        },
                    },
                    previous_2days: {
                        new: {
                            count: ''
                        },
                    }
                }
            },
            clients: {
                count: '',
                percent: '',
                new: {
                    count: ''
                },
                russia: {
                    count: '',
                    percent: '',
                    new: {
                        count: ''
                    },
                    previous_day: {
                        new: {
                            count: ''
                        },
                    },
                    previous_2days: {
                        new: {
                            count: ''
                        },
                    }
                },
                belarus: {
                    previous_day: {
                        new: {
                            count: ''
                        },
                    },
                    previous_2days: {
                        new: {
                            count: ''
                        },
                    }
                }
            }
        },
        events: {
            all: {
                count: '',
                new: {
                    count: ''
                }
            },
            completed: {
                count: '',
                new: {
                    count: ''
                }
            },
            requests: {
                count: ''
            }
        },
        adverts: {
            all:{
                count: ''
            },
            active: {
                count: ''
            },
            new: {
                count: ''
            }
        },
        payments:{
            commission: {
                pending: {
                    bel: 0,
                    rus: 0
                },
                payed: {
                    bel: {
                        monthly: 0,
                        yearly: 0
                    },
                    rus: {
                        monthly: 0,
                        yearly: 0
                    }
                }
            }
        }
    },
});
