import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const UserInfo = ({
	id,
	firstName,
	lastName,
	link,
	rating,
	location,
	userStatus,
	avatar,
	classNames,
	avatarOnClick,
	children,
	last_visited_at
}) => (
	<div className={[styles['userInfo__user'], classNames.container].join(' ')}>
		<div className={[styles['userInfo__text'], classNames.textBlock || ''].join(' ')}>
			{userStatus && (
				<div className={[styles['userInfo__user-status'], classNames.status || ''].join(' ')}>
					{userStatus}
				</div>
			)}

			{
				<div>
					<div className={[styles['userInfo__user-name'], classNames.name || ''].join(' ')}>
						<a href={link}>{id + '-' + firstName + ' ' + lastName + ' '}</a>
					</div>
					<div><b>Last visit:</b> {last_visited_at}</div>
				</div>
			}
			{location && (
				<div className={[styles['userInfo__user-name'], classNames.location || ''].join(' ')}>
					{location}
				</div>
			)}
			{children}
		</div>
	</div>
);
UserInfo.defaultProps = {
	firstName: '',
	lastName: '',
	rating: undefined,
	location: '',
	last_visited_at: '',
	classNames: {
		container: '',
		status: '',
		name: '',
		location: '',
		rating: '',
		ratingText: '',
	},
};
UserInfo.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	avatarOnClick: PropTypes.func,
	rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	location: PropTypes.string,
	userStatus: PropTypes.string,
	avatar: PropTypes.string,
	last_visited_at: PropTypes.string,
	classNames: PropTypes.shape({
		container: PropTypes.string,
		textBlock: PropTypes.string,
		avatar: PropTypes.string,
		status: PropTypes.string,
		name: PropTypes.string,
		location: PropTypes.string,
		rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		ratingText: PropTypes.string,
		ratingStars: PropTypes.string,
	}),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default UserInfo;
