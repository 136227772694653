import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { usersListSelector, getUsersListRequest, deleteUsersRequest, updateUserRequest } from 'modules/user';
import { usersDatePrepare } from './helpers';
import { getFitersDataFromUrl } from 'helpers';

const headRows = [
    { id: 'id', numeric: true, disablePadding: true, label: 'Id' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'device_token', numeric: false, disablePadding: false, label: 'Device type' },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'is_deleted', numeric: false, disablePadding: false, label: 'Deleted' },
    { id: 'instagram', numeric: false, disablePadding: false, label: 'Instagram' },
    { id: 'city', numeric: false, disablePadding: false, label: 'City' },
    { id: 'address', numeric: false, disablePadding: false, sort:false, label: 'Address(street, place, coords)' },
    { id: 'hidden', numeric: false, disablePadding: false, label: 'Show in catalog' },
];

class UsersList extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    addUserHandler = () => {
        const { history } = this.props;
        history.push('/create-user');
    };
    editUserHandler = id => {
        const { history } = this.props;
        history.push(`/master/${id}`);
    };
    usersListPrepare = list => {
        const { updateUserRequest } = this.props;
        return  usersDatePrepare(list, updateUserRequest);
    };
    render() {
        const { usersList, getUsersListRequest, deleteUsersRequest, permissions } = this.props;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getUsersListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.usersListPrepare(usersList.items)}
                    size={usersList.size}
                    tableTitle={'Masters list'}
                    editEnable
                    sort={'desc'}
                    sortBy={'id'}
                    deleteAction={deleteUsersRequest}
                    editClickCallback={this.editUserHandler}
                    addButtonListener={this.addUserHandler}
                />
            </React.Fragment>
        );
    }
}

UsersList.propTypes = {
    usersList: PropTypes.object,
    getUsersListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    deleteUsersRequest: PropTypes.func,
    updateUserRequest: PropTypes.func,
    location: PropTypes.object,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    usersList: usersListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getUsersListRequest: bindActionCreators(getUsersListRequest, dispatch),
    deleteUsersRequest: bindActionCreators(deleteUsersRequest, dispatch),
    updateUserRequest: bindActionCreators(updateUserRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersList);
