import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pushesListSelector, getPushesListRequest, typesListSelector, periodicityListSelector } from 'modules/pushes';
import { getFitersDataFromUrl } from 'helpers';
import { pushesDataPrepare } from './helpers';

const headRows = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'type_id', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'users_filter', numeric: false, disablePadding: false, label: 'Filter' },
    { id: 'is_active', numeric: false, disablePadding: false, label: 'Active' },
    { id: 'periodicity', numeric: false, disablePadding: false, label: 'Periodicity' },
    { id: 'is_running', numeric: false, disablePadding: false, label: 'Running' },
    { id: 'deleted_at', numeric: false, disablePadding: false, label: 'Deleted' }
];

class PushesList extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    componentDidMount() {

    }

    addSkillHandler = () => {
        const { history } = this.props;
        history.push('/create-push');
    };

    editSkillHandler = id => {
        const { history } = this.props;
        history.push(`/push/${id}`);
    };

    pushesListPrepare = (list, types, periodicity) => {
        return pushesDataPrepare(list, types, periodicity);
    };

    render() {
        const { pushesList, getPushesListRequest, permissions, types, periodicity } = this.props;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getPushesListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.pushesListPrepare(pushesList.items, types, periodicity)}
                    size={pushesList.size}
                    tableTitle={'Pushes list'}
                    editEnable
                    editClickCallback={this.editSkillHandler}
                    addButtonListener={this.addSkillHandler}
                    showEditHead={false}
                />
            </React.Fragment>
        );
    }
}

PushesList.propTypes = {
    pushesList: PropTypes.object,
    types: PropTypes.array,
    periodicity: PropTypes.array,
    getPushesListRequest: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    pushesList: pushesListSelector(state),
    types: typesListSelector(state),
    periodicity: periodicityListSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getPushesListRequest: bindActionCreators(getPushesListRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PushesList);
