import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import {
    deleteSalonImageRequest,
    getSalonRequest,
    salonDataSelector,
    changeTypeSalonImageRequest,
    upSalonImageRequest,
    changeSalonPortfolioTagsRequest,
    setSalonSkillsRequest,
    setSalonServicesRequest,
    addSalonPaymentRequest,
    syncSalonRequest,
} from 'modules/salons';
import { Select as MUISelect } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import style from '../User/styles.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Add, ArrowUpward, Attachment, Delete, Done, Stars } from '@material-ui/icons';
import Select from 'react-select';
import makeAnimated from 'react-select/animated/dist/react-select.esm';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
});

const animatedComponents = makeAnimated();

class Salon extends React.Component {
    state = {
        open_confirm_dialog: false,
        open_tags_dialog: false,
        mode: '',
        image_id: 0,
        portfolio_id: 0,
        skills: [],
        skills_data: [],
        services: [],
        raw_tags: '',
        bill_id: 0,
        amount: 0,
        payed_at: '',
        syncCompanyName: '',
        syncCompanyLink: '',
    };

    componentDidMount() {
        const { getSalonRequest, match } = this.props;
        getSalonRequest(match.params.id, {
            onSuccess: data => this.setState(data.data),
        });
    }
    handleDialogClick = () => {
        const { mode } = this.state;
        if (mode === 'deleteImage') {
            this.deletePortfolioImage();
        } else if (mode === 'upImage') {
            this.upPortfolioImage();
        } else if (mode === 'changeTypeImage') {
            this.changeTypePortfolioImage();
        } else {
            this.handleCloseDialog();
        }
    };

    handleAttachTags = item => {
        this.setState({ portfolio_id: item.portfolioId });
        this.setState({ selectedTags: item.selectedTags });
        this.setState({ raw_tags: item.raw_tags });
        this.setState({ open_tags_dialog: true });
    };

    prepareSkills = list => {
        return list.map(item => ({ value: item.id, label: item.title }));
    };

    handleClickOpenConfirmDialog = () => {
        this.setState({ open_confirm_dialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ open_confirm_dialog: false });
        this.setState({ open_tags_dialog: false });
    };

    handleDeleteImageClick = image_id => {
        this.setState({ mode: 'deleteImage', image_id: image_id });
        this.handleClickOpenConfirmDialog();
    };

    handleUpImageClick = image_id => {
        this.setState({ mode: 'upImage', image_id: image_id });
        this.handleClickOpenConfirmDialog();
    };

    handleChangeTypeImageClick = image_id => {
        this.setState({ mode: 'changeTypeImage', image_id: image_id });
        this.handleClickOpenConfirmDialog();
    };

    handleChangeSelect = selectedOptions => {
        this.setState({ tags: selectedOptions });
    };
    handleChangeBill = event => {
        this.setState({ bill_id: event.target.value });
    };

    handleChangeInputs = ev => {
        this.handleChangeForm(ev.target.value, ev.target.name);
    };

    handleChangeFileInput = ({ target }) => {
        const fileReader = new FileReader();
        const name = target.name;

        fileReader.readAsText(target.files[0]);
        fileReader.onload = e => {
            this.setState(prevState => ({
                [name]: JSON.parse(e.target.result),
            }));
        };
    };

    handleChangeForm = (data, name) => {
        const { ...state } = this.state;
        state[name] = data;
        this.setState({ ...state });
    };

    deletePortfolioImage = () => {
        const { deleteSalonImageRequest } = this.props;
        const { id, image_id } = this.state;
        deleteSalonImageRequest(
            { image_id: image_id },
            { onSuccess: () => this.successUpdating(id) }
        );
    };

    upPortfolioImage = () => {
        const { upSalonImageRequest } = this.props;
        const { id, image_id } = this.state;
        upSalonImageRequest(
            { image_id: image_id, salon_id: id },
            { onSuccess: () => this.successUpdating(id) }
        );
    };

    changeTypePortfolioImage = () => {
        const { changeTypeSalonImageRequest } = this.props;
        const { id, image_id } = this.state;
        changeTypeSalonImageRequest(
            { image_id: image_id, salon_id: id },
            { onSuccess: () => this.successUpdating(id) }
        );
    };

    handleChangePortfolioTags = () => {
        const { changeSalonPortfolioTagsRequest } = this.props;
        const { id, portfolio_id, tags, raw_tags } = this.state;

        changeSalonPortfolioTagsRequest(
            {
                salon_id: id,
                portfolio_id: portfolio_id,
                tags: tags != null ? tags.map(tagObject => tagObject.value) : null,
                raw_tags: raw_tags,
            },
            { onSuccess: () => this.successUpdating(id) }
        );
    };

    handleSetSkills = () => {
        const { setSalonSkillsRequest } = this.props;
        const { id, skills_data } = this.state;
        setSalonSkillsRequest(
            { salon_id: id, skills: skills_data },
            { onSuccess: () => this.successUpdating(id) }
        );
    };

    handleSetServices = () => {
        const { setSalonServicesRequest } = this.props;
        const { id, services } = this.state;
        setSalonServicesRequest(
            { salon_id: id, services: services },
            { onSuccess: () => this.successUpdating(id) }
        );
    };
    handleCreatePayment = () => {
        const { addSalonPaymentRequest } = this.props;
        const { id, bill_id, amount, payed_at } = this.state;
        addSalonPaymentRequest(
            { salon_id: id, bill_id: bill_id, amount: amount, payed_at: payed_at },
            { onSuccess: () => this.successUpdating(id) }
        );
    };

    successUpdating = id => {
        const { getSalonRequest } = this.props;
        this.setState({ open_confirm_dialog: false });
        this.setState({ open_tags_dialog: false });
        getSalonRequest(id, {
            onSuccess: data => this.setState(data.data),
        });
    };

    handleClickSyncButton = () => {
        const { syncSalonRequest, match } = this.props;
        syncSalonRequest(
            {
                type: this.state.syncCompanyName,
                url: this.state.syncCompanyLink,
                id: match.params.id,
            },
            {
                onSuccess: console.log('success sync'),
            }
        );
    };

    render() {
        const { salonData, classes } = this.props;
        const {
            open_confirm_dialog,
            open_tags_dialog,
            selectedTags,
            raw_tags,
            skills,
            bill_id,
            amount,
            payed_at,
        } = this.state;

        const paymentTypes = [
            {
                id: 1,
                type: 'cash',
            },
            {
                id: 2,
                type: 'card',
            },
            {
                id: 3,
                type: 'terminal',
            },
            {
                id: 4,
                type: 'apple pay',
            },
            {
                id: 5,
                type: 'google pay',
            },
            {
                id: 6,
                type: 'cashless',
            },
        ];
        const UKOptions = [
            { value: 0, label: 'Treatwell', key: 'treatwell' },
            { value: 1, label: 'Fresha', key: 'fresha' },
        ];

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1>{salonData.title}</h1>
                        <Paper className={classes.paper}>
                            <h2>General info</h2>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Id</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Creation date</TableCell>
                                        <TableCell>Chat</TableCell>
                                        <TableCell>Avatar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <b>{salonData.id}</b>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <h3>{salonData.title}</h3>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <b>{salonData.phone}</b>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <b>{salonData.created_at}</b>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() =>
                                                    window.open(`/chats/${salonData.chat_id}`)
                                                }
                                            >
                                                GO TO CHAT
                                            </Button>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {salonData.avatar ? (
                                                <img
                                                    key="avatar"
                                                    src={salonData.avatar}
                                                    alt="img"
                                                    width={'100px'}
                                                />
                                            ) : (
                                                '---'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h2>Events</h2>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <Link
                                onClick={() =>
                                    window.open(
                                        `/events/?filter={"salon_id":"${salonData.id}","status_id":"3"}`
                                    )
                                }
                            >
                                Подтвержденные
                            </Link>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <Link
                                onClick={() =>
                                    window.open(
                                        `/events/?filter={"salon_id":"${salonData.id}","status_id":"4"}`
                                    )
                                }
                            >
                                Завершенные
                            </Link>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <Link
                                onClick={() =>
                                    window.open(
                                        `/events/?filter={"salon_id":"${salonData.id}","status_id":"5"}`
                                    )
                                }
                            >
                                Отмененные клиентом
                            </Link>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paper}>
                            <Link
                                onClick={() =>
                                    window.open(
                                        `/events/?filter={"salon_id":"${salonData.id}","status_id":"6"}`
                                    )
                                }
                            >
                                Отмененные салоном
                            </Link>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h2>Employees</h2>
                        </Paper>
                        <ExpansionPanel className={style.panel}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>-----------</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Phone</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Admin</TableCell>
                                            <TableCell>Link</TableCell>
                                            <TableCell>Avatar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {salonData.users
                                            ? salonData.users.map(item => {
                                                  return (
                                                      <TableRow key={item.id}>
                                                          <TableCell component="th" scope="row">
                                                              {item.id}
                                                          </TableCell>
                                                          <TableCell component="th" scope="row">
                                                              {item.first_name +
                                                                  (item.last_name
                                                                      ? ' ' + item.last_name
                                                                      : '')}
                                                          </TableCell>
                                                          <TableCell component="th" scope="row">
                                                              {item.phone}
                                                          </TableCell>
                                                          <TableCell component="th" scope="row">
                                                              {item.created_at}
                                                          </TableCell>
                                                          <TableCell component="th" scope="row">
                                                              {item.is_admin ? (
                                                                  <IconButton>
                                                                      <Done />
                                                                  </IconButton>
                                                              ) : (
                                                                  ''
                                                              )}
                                                          </TableCell>
                                                          <TableCell>
                                                              <Link
                                                                  onClick={() =>
                                                                      window.open(
                                                                          `/master/${item.id}`
                                                                      )
                                                                  }
                                                              >
                                                                  Link
                                                              </Link>
                                                          </TableCell>
                                                          <TableCell component="th" scope="row">
                                                              {item.avatar ? (
                                                                  <img
                                                                      key="avatar"
                                                                      src={item.avatar}
                                                                      alt="img"
                                                                      width={'100px'}
                                                                  />
                                                              ) : (
                                                                  '---'
                                                              )}
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                              })
                                            : ''}
                                    </TableBody>
                                </Table>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h2>General info</h2>
                            <ExpansionPanel className={style.panel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>-----------</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <h2>Address</h2>
                                            {salonData.location ? (
                                                <div>
                                                    <h3>
                                                        {salonData.location.country},{' '}
                                                        {salonData.location.city}
                                                    </h3>
                                                    <h3>
                                                        {' '}
                                                        {salonData.location.address},{' '}
                                                        {salonData.location.place}
                                                    </h3>
                                                    <h3>
                                                        Метро:{' '}
                                                        {salonData.location.metro_station
                                                            ? salonData.location.metro_station.title
                                                            : ''}
                                                    </h3>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <h2>Salon info</h2>
                                            {salonData.info ? (
                                                <div>
                                                    <h4>Site: {salonData.info.site}</h4>
                                                    <h3>Instagram: {salonData.info.instagram}</h3>
                                                    <h3>
                                                        Working time interval:{' '}
                                                        {salonData.info.working_time_interval}
                                                    </h3>
                                                    <h3>
                                                        Minutes until event:{' '}
                                                        {salonData.info.min_time_until_event}
                                                    </h3>
                                                    <h3>{salonData.info.description}</h3>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <h2>Skills</h2>
                                            {salonData.skills.length > 0 ? (
                                                salonData.skills.map(item => {
                                                    return <p key={item.title}>{item.title}</p>;
                                                })
                                            ) : (
                                                <div>
                                                    <input
                                                        type="file"
                                                        onChange={this.handleChangeFileInput}
                                                        name="skills_data"
                                                    />
                                                    <Button
                                                        onClick={this.handleSetSkills}
                                                        color="primary"
                                                    >
                                                        Send
                                                    </Button>
                                                </div>
                                            )}
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Paper className={classes.paper}>
                                            <h2>Custom skills</h2>
                                            {salonData.services.length > 0 ? (
                                                salonData.services.map(item => {
                                                    return <p key={item.title}>{item.title}</p>;
                                                })
                                            ) : (
                                                <div>
                                                    <input
                                                        type="file"
                                                        onChange={this.handleChangeFileInput}
                                                        name="services"
                                                    />
                                                    <Button
                                                        onClick={this.handleSetServices}
                                                        color="primary"
                                                    >
                                                        Send
                                                    </Button>
                                                </div>
                                            )}
                                        </Paper>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h2>Synchronization</h2>
                            <ExpansionPanel className={style.panel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7a-content"
                                    id="panel7a-header"
                                >
                                    <Typography className={classes.heading}>-----</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid
                                        container
                                        spacing={12}
                                        alignItems="center"
                                        justify="space-between"
                                    >
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-helper-label">
                                                    Sync company
                                                </InputLabel>
                                                <MUISelect
                                                    onChange={ev => {
                                                        this.setState({
                                                            syncCompanyName: ev.target.value,
                                                        });
                                                    }}
                                                    value={this.state.syncCompanyName}
                                                >
                                                    {UKOptions.map(row => (
                                                        <option value={row.key} key={row.key}>
                                                            {row.label}
                                                        </option>
                                                    ))}
                                                </MUISelect>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                label="URL"
                                                disabled={!this.state.syncCompanyName}
                                                onChange={ev => {
                                                    this.setState({
                                                        syncCompanyLink: ev.target.value,
                                                    });
                                                }}
                                                value={this.state.syncCompanyLink}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={this.handleClickSyncButton}
                                                color="primary"
                                                variant="contained"
                                                disabled={
                                                    !this.state.syncCompanyName ||
                                                    !this.state.syncCompanyLink
                                                }
                                            >
                                                Sync
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h2>Pictures</h2>
                            <ExpansionPanel className={style.panel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7a-content"
                                    id="panel7a-header"
                                >
                                    <Typography className={classes.heading}>-----</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid
                                        container
                                        spacing={12}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12} md={12}>
                                            <h3>Working Place</h3>
                                            <div className={classes.root}>
                                                <GridList
                                                    cols={3}
                                                    className={classes.gridList}
                                                    cellHeight={300}
                                                >
                                                    {salonData.working_place_images.map(
                                                        (item, key) => (
                                                            <GridListTile
                                                                key={'working_place' + key}
                                                            >
                                                                <img
                                                                    key={'working_place' + item.id}
                                                                    src={item.path}
                                                                    alt="img"
                                                                    className={
                                                                        'working_place-image-' +
                                                                        item.id
                                                                    }
                                                                />
                                                                <GridListTileBar
                                                                    title={'Место ' + (key + 1)}
                                                                    className={style.titleBar}
                                                                    actionIcon={
                                                                        <div>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    this.handleDeleteImageClick(
                                                                                        item.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Delete
                                                                                    className={
                                                                                        style.title
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    this.handleUpImageClick(
                                                                                        item.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Stars
                                                                                    className={
                                                                                        style.title
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    this.handleChangeTypeImageClick(
                                                                                        item.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <ArrowUpward
                                                                                    className={
                                                                                        style.title
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </div>
                                                                    }
                                                                />
                                                            </GridListTile>
                                                        )
                                                    )}
                                                </GridList>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <h3>Portfolio</h3>
                                            <div className={classes.root}>
                                                <GridList
                                                    cols={3}
                                                    className={classes.gridList}
                                                    cellHeight={300}
                                                >
                                                    {salonData.portfolio_images.map((item, key) => (
                                                        <GridListTile key={'portfolio' + key}>
                                                            <img
                                                                key={'portfolio' + item.id}
                                                                src={item.path}
                                                                alt="img"
                                                                className={
                                                                    'portfolio-image-' + item.id
                                                                }
                                                            />
                                                            <GridListTileBar
                                                                title={'Место ' + (key + 1)}
                                                                subtitle={'Tags:' + item.tags}
                                                                className={style.titleBar}
                                                                actionIcon={
                                                                    <div>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.handleDeleteImageClick(
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <Delete
                                                                                className={
                                                                                    style.title
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.handleUpImageClick(
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <Stars
                                                                                className={
                                                                                    style.title
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.handleChangeTypeImageClick(
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <ArrowUpward
                                                                                className={
                                                                                    style.title
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.handleAttachTags(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            <Attachment
                                                                                className={
                                                                                    style.title
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                }
                                                            />
                                                        </GridListTile>
                                                    ))}
                                                </GridList>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <h2>Payments</h2>
                            <ExpansionPanel className={style.panel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7a-content"
                                    id="panel7a-header"
                                >
                                    <Typography className={classes.heading}>-----</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {salonData.payments.map(item => {
                                                let paymentType = paymentTypes.find(
                                                    i => i.id === item.payment_type_id
                                                );
                                                return (
                                                    <TableRow key={item.id}>
                                                        <TableCell component="th" scope="row">
                                                            <b>{item.id}</b>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <b>{item.amount}</b>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <b>{item.payed_at}</b>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <b>
                                                                {paymentType
                                                                    ? paymentType.type
                                                                    : ''}
                                                            </b>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell align="center">Add</TableCell>
                                                <TableCell align="center">Payment</TableCell>
                                                <TableCell align="center">
                                                    <p>Bill</p>
                                                    <NativeSelect
                                                        onChange={this.handleChangeBill}
                                                        value={bill_id}
                                                    >
                                                        {salonData.bills.map(row => (
                                                            <option value={row.id} key={row.id}>
                                                                {row.date +
                                                                    ' ' +
                                                                    row.amount +
                                                                    ' RUB'}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </TableCell>
                                                <TableCell align="center" size={'small'}>
                                                    <TextField
                                                        variant="outlined"
                                                        name="payed_at"
                                                        margin="normal"
                                                        type="date"
                                                        onChange={this.handleChangeInputs}
                                                        classes={{ root: style.inputs }}
                                                        value={payed_at}
                                                        required
                                                        size={'small'}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" size={'small'}>
                                                    <TextField
                                                        variant="outlined"
                                                        name="amount"
                                                        margin="normal"
                                                        type="number"
                                                        onChange={this.handleChangeInputs}
                                                        classes={{ root: style.inputs }}
                                                        value={amount}
                                                        required
                                                        size={'small'}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton>
                                                        <Add onClick={this.handleCreatePayment} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog
                    open={open_confirm_dialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogClick} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={open_tags_dialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    key={'Tags Dialog'}
                    maxWidth={'md'}
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">{'Select Tags'}</DialogTitle>
                    <DialogContent style={{ height: '300px' }}>
                        <TextField
                            variant="outlined"
                            name="raw_tags"
                            margin="normal"
                            type="string"
                            onChange={this.handleChangeInputs}
                            classes={{ root: style.inputs }}
                            value={raw_tags}
                            size={'small'}
                        />
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={selectedTags}
                            isMulti
                            options={this.prepareSkills(skills)}
                            onChange={this.handleChangeSelect}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleChangePortfolioTags} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

Salon.propTypes = {
    deleteSalonImageRequest: PropTypes.func.isRequired,
    getSalonRequest: PropTypes.func.isRequired,
    syncSalonRequest: PropTypes.func.isRequired,
    upSalonImageRequest: PropTypes.func.isRequired,
    changeTypeSalonImageRequest: PropTypes.func.isRequired,
    changeSalonPortfolioTagsRequest: PropTypes.func.isRequired,
    setSalonSkillsRequest: PropTypes.func.isRequired,
    setSalonServicesRequest: PropTypes.func.isRequired,
    addSalonPaymentRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    ordersRoles: PropTypes.shape({
        items: PropTypes.array,
        size: PropTypes.string,
    }),
    salonData: PropTypes.object,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    salonData: salonDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getSalonRequest: bindActionCreators(getSalonRequest, dispatch),
    syncSalonRequest: bindActionCreators(syncSalonRequest, dispatch),
    deleteSalonImageRequest: bindActionCreators(deleteSalonImageRequest, dispatch),
    upSalonImageRequest: bindActionCreators(upSalonImageRequest, dispatch),
    changeTypeSalonImageRequest: bindActionCreators(changeTypeSalonImageRequest, dispatch),
    changeSalonPortfolioTagsRequest: bindActionCreators(changeSalonPortfolioTagsRequest, dispatch),
    setSalonSkillsRequest: bindActionCreators(setSalonSkillsRequest, dispatch),
    setSalonServicesRequest: bindActionCreators(setSalonServicesRequest, dispatch),
    addSalonPaymentRequest: bindActionCreators(addSalonPaymentRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(useStyles)(Salon));
