import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    withStyles,
    Button,
    Grid
} from '@material-ui/core';

import { FormCore } from 'components';
import {
    getSkillRequest,
    addSkillRequest,
    updateSkillRequest,
    skillsDataSelector,
    deleteSkillRequest,
    getParentSkillsListRequest,
    parentSkillsListSelector
} from 'modules/skills';
import style from './styles.scss';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

class Skill extends React.Component {
    state = {
        title: '',
        description: '',
        parent_skill_id: '',
        uid: '',
        long_description: '',
        expanded: 'panel',
        selectedOption: null,
    };

    componentDidMount() {
        const {
            getSkillRequest,
            route: { isAdd },
            match,
        } = this.props;
        if (!isAdd) {
            getSkillRequest(match.params.id, {
                onSuccess: data => this.setState(data.data),
            });
        }

        this.getParentSkillsData();
    }

    handlerDelete = () => {
        const {
            deleteSkillRequest,
            route: { isAdd },
            match,
            history,
        } = this.props;
        if (!isAdd) {
            deleteSkillRequest(match.params.id, {
                onSuccess: () => {
                    history.push('/skills');
                },
            });
        }
    };

    handlerCancelButton = () => {
        const { history } = this.props;
        history.push('/skills');
    };

    handlerSubmit = () => {
        const { history, route, addSkillRequest, updateSkillRequest } = this.props;
        const handlerSuccess = () => history.push('/skills/');
        const { ...state } = this.state;
        if (route.isAdd) {
            addSkillRequest({ ...state }, { onSuccess: handlerSuccess });
        } else {
            updateSkillRequest({ ...state }, { onSuccess: handlerSuccess });
        }
    };

    handleChangeSelect = selectedOption => {
        this.setState(
            { parent_skill_id: selectedOption.value }
        );
    };

    handleChangeInputs = ev => {
        this.handleChangeForm(ev.target.value, ev.target.name);
    };

    handleChangeForm = (data, name) => {
        const { ...state } = this.state;
        state[name] = data;
        this.setState({ ...state });
    };

    getParentSkillsData = () => {
        const {
            getParentSkillsListRequest,
        } = this.props;
        let data = {
            perPage: 100,
            parent: true
        };
        let parentSkills = [];
        getParentSkillsListRequest(data, {
            onSuccess: data =>
            {
                data.data.items.forEach(element => parentSkills.push({value: element.id, label:element.title}));
                this.setState({
                    parentSkills
                })
            },
        });
    };

    render() {
        const {
            classes,
            permissions,
            route: { isAdd },
        } = this.props;
        const {
            title, description, parent_skill_id, uid, parentSkills = [], long_description
        } = this.state;
        return (
            <div className={classes.root}>
                <FormCore
                    permissions={permissions}
                    handlerSubmit={this.handlerSubmit}
                    handlerCancelButton={this.handlerCancelButton}
                    title={`Skill ${isAdd ? 'creation' : 'edit'}  page`}
                    requiredMap={[
                        { title: 'Title', name: 'title' },
                        { title: 'Description', name: 'description' }
                    ]}
                >
                    <Grid container spacing={8} alignItems="center" justify="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Title"
                                name="title"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={title}
                                required
                                disabled={!permissions.update}
                            />

                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Description"
                                name="description"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={description}
                                required
                                disabled={!permissions.update}
                            />

                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Uid"
                                name="uid"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={uid}
                                disabled={!permissions.update}
                            />

                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Long description"
                                name="long_description"
                                margin="normal"
                                multiline
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={long_description}
                                disabled={!permissions.update}
                            />

                            <div style={{ maxWidth: "60%"}}>
                                <Select
                                    id="standard-name"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={parentSkills.find(item => item.value === parent_skill_id)}
                                    onChange={this.handleChangeSelect}
                                    options={parentSkills}
                                    label="Parent Skill"
                                    name="parent_skill_id"
                                    disabled={!permissions.update}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.handlerDelete}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormCore>
            </div>
        );
    }
}

Skill.propTypes = {
    history: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    addSkillRequest: PropTypes.func.isRequired,
    updateSkillRequest: PropTypes.func.isRequired,
    getSkillRequest: PropTypes.func.isRequired,
    getParentSkillsListRequest: PropTypes.func.isRequired,
    deleteSkillRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};
Skill.defaultProps = {
    skillData: {
        id: 0,
        title: 'Title',
        description: 'description',
        parent_skill_id: 1,
        uid: 'uid',
        long_description: 'long_description',
        selectedOption: null
    },
};

const mapStateToProps = state => ({
    skillData: skillsDataSelector(state),
    parentSkills: parentSkillsListSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getSkillRequest: bindActionCreators(getSkillRequest, dispatch),
    getParentSkillsListRequest: bindActionCreators(getParentSkillsListRequest, dispatch),
    deleteSkillRequest: bindActionCreators(deleteSkillRequest, dispatch),
    addSkillRequest: bindActionCreators(addSkillRequest, dispatch),
    updateSkillRequest: bindActionCreators(updateSkillRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Skill));