import * as apiHelpers from 'api';
const modules = 'feedbacks';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_FEEDBACKS_LIST_REQUEST = `${modules}/GET_FEEDBACKS_LIST_REQUEST`;
export const UPDATE_FEEDBACK_REQUEST = `${modules}/UPDATE_FEEDBACK_REQUEST`;

export const getFeedbacksListRequest = actionCreator(GET_FEEDBACKS_LIST_REQUEST);
export const updateFeedbackRequest = actionCreator(UPDATE_FEEDBACK_REQUEST);

apiRoutes.add(GET_FEEDBACKS_LIST_REQUEST, data => {
    return {
        url: `/admin/feedbacks`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});



apiRoutes.add(UPDATE_FEEDBACK_REQUEST, data => ({
    url: `/admin/feedbacks/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const feedbacksListSelector = apiSelector(GET_FEEDBACKS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
