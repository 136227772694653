import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addValueToReactComponent } from 'components/Table';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle
} from '@material-ui/core';

import { feedbacksListSelector, getFeedbacksListRequest, updateFeedbackRequest} from 'modules/feedbacks';
import { getFitersDataFromUrl } from 'helpers';
import { Close, Done, ThumbDown, ThumbUp } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

const headRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'id', isId: true },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'master_id', numeric: false, disablePadding: false, label: 'Master id' },
    { id: 'user_id', numeric: false, disablePadding: false, label: 'User id' },
    { id: 'is_master_send', numeric: false, disablePadding: false, label: 'Is master sent' },
    { id: 'feedback', numeric: false, disablePadding: false, sort:false, label: 'Feedback text' },
    { id: 'rating', numeric: false, disablePadding: false, sort:false, label: 'Feedback id' },
    { id: 'is_moderated', numeric: false, disablePadding: false, sort:false, label: 'Confirmed' },
    { id: 'dt_moderated', numeric: false, disablePadding: false, sort:false, label: 'Is moderated' },
];

class FeedbacksList extends PureComponent {
    state = {
        open_dialog: false,
        is_moderated: false,
        feedback_id: false,
    };
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }

    handleClickOpenDialog = (id, value) => {
        this.setState({feedback_id: id});
        this.setState({open_dialog: true});
        this.setState({is_moderated: value});
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };

    moderateFeedback = (id, value) => {
        const {updateFeedbackRequest} = this.props;
        updateFeedbackRequest({ id: id, is_moderated: value }, {onSuccess: () => window.location.reload() });
    };
    feedbacksDataPrepare = (list) => {
        return Array.isArray(list)
            ? list.map(item => {
                const newItem = { ...item };
                newItem.is_master_send = addValueToReactComponent(
                    item.is_master_send ? <Done/> : '',
                    newItem.is_master_send
                );

                newItem.is_moderated = addValueToReactComponent(
                    item.is_moderated ? <Done/>: <Close/>,
                    newItem.is_moderated
                );

                newItem.dt_moderated = addValueToReactComponent(
                    item.dt_moderated ? (
                            <IconButton>
                                <Done/>
                            </IconButton>
                        ) :
                        (
                            <div>
                                <IconButton onClick={() => this.handleClickOpenDialog( item.id, true)}>
                                    <ThumbUp/>
                                </IconButton>
                                <IconButton onClick={() => this.handleClickOpenDialog( item.id, false )}>
                                    <ThumbDown/>
                                </IconButton>
                            </div>
                        ),
                    newItem.dt_moderated
                );

                return newItem;
            }) : [];
    };
    render() {
        const { feedbacksList, getFeedbacksListRequest, permissions } = this.props;
        const {
            feedback_id,
            open_dialog,
            is_moderated
        } = this.state;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getFeedbacksListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.feedbacksDataPrepare(feedbacksList.items)}
                    size={feedbacksList.size}
                    tableTitle={'Feedbacks list'}
                    editEnable={false}
                    showEditHead={false}
                />
                <Dialog
                    open={open_dialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.moderateFeedback( feedback_id, is_moderated )} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

FeedbacksList.propTypes = {
    feedbacksList: PropTypes.object,
    getFeedbacksListRequest: PropTypes.func,
    updateFeedbackRequest: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    feedbacksList: feedbacksListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getFeedbacksListRequest: bindActionCreators(getFeedbacksListRequest, dispatch),
    updateFeedbackRequest: bindActionCreators(updateFeedbackRequest, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbacksList);
