import React from 'react';
import { addValueToReactComponent } from 'components/Table';

export const skillsDataPrepare = (list = [], parentSkills) => {
    return Array.isArray(list)
        ? list.map(item => {
            const newItem = { ...item };
            const parenSKill = parentSkills.find(searchItem => {
                return searchItem.id === item.parent_skill_id;
            });

            newItem.parent_skill_id = addValueToReactComponent(
                parenSKill ? parenSKill.title : '',
                newItem.parent_skill_id
            );
            return newItem;
        })
        : [];
};