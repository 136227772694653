import * as apiHelpers from 'api';
const modules = 'pushes';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_PUSHES_LIST_REQUEST = `${modules}/GET_PUSHES_LIST_REQUEST`;
export const GET_PUSH_REQUEST = `${modules}/GET_PUSH_REQUEST`;
export const ADD_PUSH_REQUEST = `${modules}/ADD_PUSH_REQUEST`;
export const UPDATE_PUSH_REQUEST = `${modules}/UPDATE_PUSH_REQUEST`;
export const DELETE_PUSH_REQUEST = `${modules}/DELETE_PUSH_REQUEST`;
export const RUN_PUSH_REQUEST = `${modules}/RUN_PUSH_REQUEST`;
export const GET_PUSH_TYPES_REQUEST = `${modules}/GET_PUSH_TYPES_REQUEST`;
export const GET_PUSH_PERIODICITY_REQUEST = `${modules}/GET_PUSH_PERIODICITY_REQUEST`;

export const getPushesListRequest = actionCreator(GET_PUSHES_LIST_REQUEST);
export const getPushRequest = actionCreator(GET_PUSH_REQUEST);
export const addPushRequest = actionCreator(ADD_PUSH_REQUEST);
export const updatePushRequest = actionCreator(UPDATE_PUSH_REQUEST);
export const deletePushRequest = actionCreator(DELETE_PUSH_REQUEST);
export const runPushRequest = actionCreator(RUN_PUSH_REQUEST);

apiRoutes.add(GET_PUSHES_LIST_REQUEST, data => {
    return {
        url: `admin/pushes`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true }
    };
});

apiRoutes.add(GET_PUSH_REQUEST, data => ({
    url: `admin/pushes/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_PUSH_REQUEST, data => {
    return {
        url: `admin/pushes/${data.id}`,
        method: 'delete',
        params: { id: data },
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(ADD_PUSH_REQUEST, data => ({
    url: `/admin/pushes`,
    method: 'post',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_PUSH_REQUEST, data => ({
    url: `admin/pushes/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(RUN_PUSH_REQUEST, data => ({
    url: `admin/pushes/${data.id}`,
    method: 'post',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));


export const pushDataSelector = apiSelector(GET_PUSH_REQUEST, {
    initialData: {
        id: '',
        title: '',
        description: '',
        users_filter: '',
        type_id: '',
        is_repeated: '',
        periodicity: '',
        is_running: '',
        count_repeats: '',
        dt_start: '',
        dt_end: '',
        deleted_at: ''
    },
});

export const pushesListSelector = apiSelector(GET_PUSHES_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});

export const typesListSelector = apiSelector(GET_PUSH_TYPES_REQUEST, {
    initialData: [
        {
            'value': 1,
            'label': 'Push'
        },
        {
            'value': 2,
            'label': 'SMS'
        },
        {
            'value': 3,
            'label': 'Mail'
        }
    ]
});

export const periodicityListSelector = apiSelector(GET_PUSH_PERIODICITY_REQUEST, {
    initialData: [
        {
            'value': 1,
            'label': 'Once'
        },
        {
            'value': 2,
            'label': 'Every 10 mins'
        },
        {
            'value': 3,
            'label': 'Every hour'
        },
        {
            'value': 4,
            'label': 'Every day'
        },
        {
            'value': 5,
            'label': 'Every week'
        },
        {
            'value': 6,
            'label': 'Every month'
        }
    ],
});