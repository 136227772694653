import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import NoOptionsMessage from './NoOption';
import Option from './Option';
import Control from './Controls';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import MultiValue from './MultiValue';
import Menu from './Menu';
import { useStyles } from './style';
import styles from './style.scss';

const suggestions = [{ label: 'Belarus' }, { label: 'Ukraine' }].map(suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
}));

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

export const optionsPreparingAutocompleate = (arr, keyToLabel = 'label', keyToValue = 'value') =>
    Array.isArray(arr) && arr.map(item => ({ value: item[keyToValue], label: item[keyToLabel] }));

export const valuePreparing = (item, keyToLabel = 'label', keyToValue = 'value') =>
    item
        ? {
              value: item[keyToValue],
              label: item[keyToLabel],
          }
        : {};

export default function IntegrationReactSelect({
    options = suggestions,
    multiSelect = false,
    placeholder = '',
    inputChangeListener = () => {},
    onChange = () => {},
    value = null,
    disabled,
    valueIsObject = false,
    required,
    ...props
}) {
    const classes = useStyles();
    const theme = useTheme();
    let [single, setSingle] = React.useState(value);
    let [multi, setMulti] = React.useState(value);
    single = single || value;
    // HACK: is hack for  set  actual state of  value
    React.useEffect(() => {
        if (!multiSelect) {
            if (value === null) {
                return setSingle(null);
            }
            const res = valueIsObject ? value : options.find(item => item.value === value);
            setSingle(res);
        } else {
            if (value === null) {
                return setMulti(null);
            }
            const res = valueIsObject
                ? value
                : value && options.filter(item => value.indexOf(item.value) !== -1);
            setMulti(res);
        }
    }, [value]);
    function handleChangeSingle(value) {
        if (!disabled) {
            let result = value;
            result = valueIsObject ? result : result.value;
            setSingle(value);
            onChange(result);
        }
    }
    function clearStates() {
        if (!disabled) {
            !multiSelect ? setSingle(null) : setMulti(null);
            onChange(null);
        }
    }
    function handleChangeMulti(value) {
        if (!disabled) {
            let result = value;
            result = valueIsObject ? result : value && value.map(item => item.value);
            setMulti(value);
            onChange(result);
        }
    }

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    let timer;
    const onInputChange = ev => {
        if (!disabled && typeof ev === 'string' && ev.length > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                inputChangeListener(ev);
            }, 300);
        }
    };

    const preparedOptions =
        options.length > 0 ? options : optionsPreparingAutocompleate([{ label: value, value }]);
    return (
        <div className={classes.root}>
            {!multiSelect ? (
                <Select
                    {...props}
                    isDisabled={disabled}
                    className={styles.select}
                    classes={classes}
                    styles={selectStyles}
                    inputId="react-select-single"
                    TextFieldProps={{
                        required: required,
                        variant: 'outlined',
                        disabled: disabled,
                        label: value && <div className={styles.label}>{placeholder}</div>,
                        InputLabelProps: {
                            htmlFor: 'react-select-single',
                            shrink: true,
                        },
                    }}
                    placeholder={placeholder}
                    options={preparedOptions}
                    components={components}
                    value={single}
                    onChange={handleChangeSingle}
                    onInputChange={onInputChange}
                />
            ) : (
                <Select
                    {...props}
                    isDisabled={disabled}
                    className={styles.select}
                    classes={classes}
                    styles={selectStyles}
                    inputId="react-select-multiple"
                    TextFieldProps={{
                        label: '',
                        disabled: disabled,
                        InputLabelProps: {
                            htmlFor: 'react-select-multiple',
                            shrink: true,
                        },
                    }}
                    required={required}
                    placeholder={placeholder}
                    options={preparedOptions}
                    components={components}
                    value={multi}
                    onChange={handleChangeMulti}
                    onInputChange={onInputChange}
                    isMulti
                />
            )}
            {!multiSelect && (
                <div className={styles.closeContainer}>
                    <IconButton
                        disabled={disabled}
                        onClick={clearStates}
                        aria-label="delete"
                        className={styles.close}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </div>
            )}
        </div>
    );
}
IntegrationReactSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
        })
    ),
    value: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
    }),
    valueIsObject: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    multiSelect: PropTypes.bool,
    placeholder: PropTypes.string,
    inputChangeListener: PropTypes.func,
    onChange: PropTypes.func,
    onClearState: PropTypes.func,
};
