import { addValueToReactComponent } from 'components/Table';
import { Close, Done, FiberManualRecord} from '@material-ui/icons';
import React from 'react';

export const pushesDataPrepare = (list, types, periodicity) => {
        return Array.isArray(list)
            ? list.map(item => {
                const newItem = { ...item };
                const type = types.find(type => type.value === item.type_id);
                const periodicityLabel = periodicity.find(periodicity => periodicity.value === item.periodicity);

                newItem.type_id = addValueToReactComponent(
                    type.label,
                    newItem.type_id
                );
                newItem.periodicity = addValueToReactComponent(
                    periodicityLabel.label,
                    newItem.periodicity
                );
                newItem.is_active = addValueToReactComponent(
                    item.is_active ? <Done/> : <Close/>,
                    newItem.is_active
                );
                newItem.is_running = addValueToReactComponent(
                    item.is_running ? <FiberManualRecord htmlColor={"green"}/> : <FiberManualRecord htmlColor={"red"}/>,
                    newItem.is_running
                );
                newItem.deleted_at = addValueToReactComponent(
                    item.deleted_at ? <Done/> : '',
                    newItem.deleted_at
                );
                return newItem;
            }) : [];
};