import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const optionsPreparingSelect = (arr, valueKey, labelKey) =>
    arr.map(item => {
        return typeof item === 'object'
            ? { value: item[valueKey], label: item[labelKey] }
            : { value: item, label: item };
    });

export default function SimpleSelect({
    options,
    placeholder,
    onChange,
    className,
    value,
    required,
    name = 'select',
    ...props
}) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (
        <FormControl
            required={required}
            variant="outlined"
            className={clsx(classes.formControl, className)}
        >
            <InputLabel ref={inputLabel} htmlFor="outlined-select-simple">
                {placeholder}
            </InputLabel>
            <Select
                {...props}
                onChange={ev => onChange(ev)}
                value={value}
                input={
                    <OutlinedInput
                        labelWidth={labelWidth}
                        name={name}
                        id="outlined-select-simple"
                    />
                }
            >
                {options.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
SimpleSelect.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};
