/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withRouter } from 'react-router';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Poppers from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';
// core components
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';

import headerLinksStyle from 'assets/jss/material-dashboard-react/components/headerLinksStyle';

class AdminNavbarLinks extends React.Component {
    state = {
        openNotifcation: false,
        openProfile: false,
    };
    handleToggleNotification = () => {
        this.setState(state => ({ openNotifcation: !state.openNotifcation }));
    };
    handleCloseNotification = event => {
        if (this.anchorNotification.contains(event.target)) {
            return;
        }
        this.setState({ openNotifcation: false });
    };
    handleToggleProfile = () => {
        // const { history } = this.props;
        // history.push('update_password');
        this.setState(state => ({ openProfile: !state.openProfile }));
    };
    handleCloseProfile = event => {
        if (this.anchorProfile.contains(event.target)) {
            return;
        }
        this.setState({ openProfile: false });
    };
    handleUserLogout = event => {
        const { userLogout } = this.props;
        userLogout();
        this.handleCloseProfile(event);
    };
    render() {
        const { classes, history } = this.props;
        const { openNotifcation, openProfile } = this.state;

        return (
            <div>
                {/*<div className={classes.searchWrapper}>
                    <CustomInput
                        formControlProps={{
                            className: classes.margin + ' ' + classes.search,
                        }}
                        inputProps={{
                            placeholder: 'Search',
                            inputProps: {
                                'aria-label': 'Search',
                            },
                        }}
                    />
                    <Button color="white" aria-label="edit" justIcon round>
                        <Search />
                    </Button>
                </div>
                <Button
                    color={window.innerWidth > 959 ? 'transparent' : 'white'}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-label="Dashboard"
                    className={classes.buttonLink}
                >
                    <Dashboard className={classes.icons} />
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Dashboard</p>
                    </Hidden>
                </Button>
                    <Button
                        buttonRef={node => {
                            this.anchorNotification = node;
                        }}
                        color={window.innerWidth > 959 ? 'transparent' : 'white'}
                        justIcon={window.innerWidth > 959}
                        simple={!(window.innerWidth > 959)}
                        aria-owns={openNotifcation ? 'notification-menu-list-grow' : null}
                        aria-haspopup="true"
                        onClick={this.handleToggleNotification}
                        className={classes.buttonLink}
                    >
                        <Notifications className={classes.icons} />
                        <span className={classes.notifications}>5</span>
                        <Hidden mdUp implementation="css">
                            <p onClick={this.handleClick} className={classes.linkText}>
                                Notification
                            </p>
                        </Hidden>
                    </Button> */}
                <div className={classes.manager}>
                    <Poppers
                        open={openNotifcation}
                        anchorEl={this.anchorNotification}
                        transition
                        disablePortal
                        className={
                            classNames({ [classes.popperClose]: !openNotifcation }) +
                            ' ' +
                            classes.popperNav
                        }
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="notification-menu-list-grow"
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleCloseNotification}>
                                        <MenuList role="menu">
                                            <MenuItem
                                                onClick={this.handleCloseNotification}
                                                className={classes.dropdownItem}
                                            >
                                                Mike John responded to your email
                                            </MenuItem>
                                            <MenuItem
                                                onClick={this.handleCloseNotification}
                                                className={classes.dropdownItem}
                                            >
                                                You have 5 new tasks
                                            </MenuItem>
                                            <MenuItem
                                                onClick={this.handleCloseNotification}
                                                className={classes.dropdownItem}
                                            >
                                                You{"'"}re now friend with Andrew
                                            </MenuItem>
                                            <MenuItem
                                                onClick={this.handleCloseNotification}
                                                className={classes.dropdownItem}
                                            >
                                                Another Notification
                                            </MenuItem>
                                            <MenuItem
                                                onClick={this.handleCloseNotification}
                                                className={classes.dropdownItem}
                                            >
                                                Another One
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Poppers>
                </div>
                <div className={classes.manager}>
                    <Button
                        buttonRef={node => {
                            this.anchorProfile = node;
                        }}
                        color={window.innerWidth > 959 ? 'transparent' : 'white'}
                        justIcon={window.innerWidth > 959}
                        simple={!(window.innerWidth > 959)}
                        aria-owns={openNotifcation ? 'profile-menu-list-grow' : null}
                        aria-haspopup="true"
                        onClick={this.handleToggleProfile}
                        className={classes.buttonLink}
                    >
                        <Person className={classes.icons} />
                        <Hidden mdUp implementation="css">
                            <p className={classes.linkText}>Profile</p>
                        </Hidden>
                    </Button>
                    <Poppers
                        open={openProfile}
                        anchorEl={this.anchorProfile}
                        transition
                        disablePortal
                        className={
                            classNames({ [classes.popperClose]: !openProfile }) +
                            ' ' +
                            classes.popperNav
                        }
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="profile-menu-list-grow"
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleCloseProfile}>
                                        <MenuList role="menu">
                                            <MenuItem
                                                onClick={() => history.push('/update_password')}
                                                className={classes.dropdownItem}
                                            >
                                                Profile
                                            </MenuItem>
                                            <Divider light />
                                            <MenuItem
                                                onClick={this.handleUserLogout}
                                                className={classes.dropdownItem}
                                            >
                                                Logout
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Poppers>
                </div>
            </div>
        );
    }
}

AdminNavbarLinks.propTypes = {
    classes: PropTypes.object,
    userLogout: PropTypes.func.isRequired,
    history: PropTypes.object,
};

export default withStyles(headerLinksStyle)(withRouter(AdminNavbarLinks));
