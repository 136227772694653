import * as apiHelpers from 'api';
import { createAction } from 'redux-actions';
import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects';
import history from 'store/history';
import get from 'lodash/get';
const modules = 'auth';
const {
    helpers: { actionCreator, apiSelector, responseActionsTypes },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const POST_AUTH_REQUEST = `${modules}/POST_AUTH_REQUEST`;
export const UPDATE_PASSWORD_REQUEST = `${modules}/UPDATE_PASSWORD_REQUEST`;
export const SAVE_AUTH_TO_PERSIST = `${modules}/SAVE_AUTH_TO_PERSIST`;
export const USER_LOGOUT = `${modules}/USER_LOGOUT`;

export const loginAuthRequest = actionCreator(POST_AUTH_REQUEST);
export const updatePasswordRequest = actionCreator(UPDATE_PASSWORD_REQUEST);

export const saveAuthToPersist = createAction(SAVE_AUTH_TO_PERSIST);
export const userLogout = createAction(USER_LOGOUT);

apiRoutes.add(POST_AUTH_REQUEST, data => ({
    url: `/admin/token`,
    method: 'post',
    data: data,
    headers: { Authorization: false},
}));

apiRoutes.add(UPDATE_PASSWORD_REQUEST, data => ({
    url: `/accounts/password/change`,
    method: 'post',
    data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

//sagas
function* authListenerSuccess() {
    const data = yield select(loginAuthSelector);
    yield put(saveAuthToPersist(data));
    if (data.shouldResetPassword) {
        yield call(history.push, '/update_password');
    } else {
        yield call(history.push, '/statistics');
    }
}

function* authListenerFailed(data) {
    yield delay(100);
}

export function* authSaga() {
    yield all([
        takeLatest(
            action => responseActionsTypes(POST_AUTH_REQUEST).failedAction === action.type,
            authListenerFailed
        ),
        takeLatest(
            action => responseActionsTypes(POST_AUTH_REQUEST).successAction === action.type,
            authListenerSuccess
        ),
    ]);
}

//reducers
const initialState = {
    credentials: null,
    user: null,
};
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_AUTH_TO_PERSIST: {
            return {
                ...state,
                ...{
                    credentials: action.payload,
                },
            };
        }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
//selectors
export const loginAuthSelector = apiSelector(POST_AUTH_REQUEST);
export const checkUserAuth = state => state.auth.credentials !== null;
export const checkPasswordReset = state =>
    get(state, 'auth.credentials.shouldResetPassword', false);
export const getUserCredentials = state => state.auth.credentials;
