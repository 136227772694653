import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Dialog, DialogActions, DialogTitle, Grid, CardMedia} from '@material-ui/core';
import {
    advertDataSelector,
    deleteAdvertRequest,
    getAdvertRequest,
    updateAdvertRequest,
    approveAdvertRequest
} from 'modules/adverts';
import {
    FormCore
} from 'components';
import style from './styles.scss';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

class Advert extends React.Component {
    state = {
        id: '', text: '', status_id: '', images: []
    };

    componentDidMount() {
        this.getAdvertData();
    }

    handleChangeForm = (data, name) => {
        const { ...state } = this.state;
        state[name] = data;
        this.setState({ ...state });
    };
    handleChangeInputs = ev => {
        this.handleChangeForm(ev.target.value, ev.target.name);
    };

    handlerCancelButton = () => {
        const { history } = this.props;
        history.push('/adverts');
    };

    handlerSubmit = () => {
        const { route, updateAdvertRequest } = this.props;
        if (!route.isAdd) {
            updateAdvertRequest({ ...this.state });
        }
    };

    getAdvertData = () => {
        const {
            getAdvertRequest,
            route: { isAdd },
            match,
        } = this.props;
        if (!isAdd) {
            getAdvertRequest(match.params.id, {
                onSuccess: data =>
                {
                    this.setState({
                        ...data.data
                    })
                },
            });
        }
    };

    handleClickOpenDialog = (mode) => {
        this.setState({mode: mode});
        this.setState({open_dialog: true});
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };

    handleDialog = (mode) => {
        const {id} = this.state;
        const {deleteAdvertRequest, approveAdvertRequest, history} = this.props;
        if (mode === 'approve') {
            approveAdvertRequest({ id: id}, {onSuccess: () => history.push('/adverts')});
        } else {
            deleteAdvertRequest({ id: id}, {onSuccess: () => history.push('/adverts')});
        }
    };

    render() {
        const {
            classes,
            permissions,
            route: { isAdd },
        } = this.props;
        const {
            id, text, user, date, type, images, open_dialog, dt_moderated, mode
        } = this.state;
        return (
            <div className={classes.root}>
                <FormCore
                    permissions={permissions}
                    handlerSubmit={this.handlerSubmit}
                    handlerCancelButton={this.handlerCancelButton}
                    title={`Advert ${isAdd ? 'creation' : id + ' edit'}  page`}
                    requiredMap={[
                        { title: 'Text', name: 'text' },
                    ]}
                >
                    <Grid container spacing={8} alignItems="center" justify="center">
                        <Grid item xs={12} md={8}>
                            <p>User:
                                {user ?
                                    ' ' + user.first_name + ' ' + user.last_name :
                                    ''
                                }
                            </p>
                            <p>Date:
                                {date ?
                                    ' ' + date :
                                    ''
                                }
                            </p>
                            <p>Type:
                                {type ?
                                    ' ' + type.title :
                                    ''
                                }
                            </p>
                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Text"
                                name="text"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={text}
                                required
                                multiline
                                disabled={!permissions.update}
                            />

                            <CardMedia >
                                {images.map((image, index) => {
                                    return <img key={index} src={image.path} alt="img" width={"700px"}/>
                                })}
                            </CardMedia>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={() => this.handleClickOpenDialog( 'delete')}
                                >
                                    Delete
                                </Button>
                                {
                                    dt_moderated ? '' :
                                        (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => this.handleClickOpenDialog( 'approve')}
                                            >
                                                Approve
                                            </Button>
                                        )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={open_dialog}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.handleDialog( mode )} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormCore>
            </div>
        );
    }
}

Advert.propTypes = {
    history: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    deleteAdvertRequest: PropTypes.func.isRequired,
    updateAdvertRequest: PropTypes.func.isRequired,
    getAdvertRequest: PropTypes.func.isRequired,
    approveAdvertRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};
Advert.defaultProps = {
    advertData: {
        id: 1,
        text: 'TEsts'
    },
};

const mapStateToProps = state => ({
    advertData: advertDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getAdvertRequest: bindActionCreators(getAdvertRequest, dispatch),
    deleteAdvertRequest: bindActionCreators(deleteAdvertRequest, dispatch),
    updateAdvertRequest: bindActionCreators(updateAdvertRequest, dispatch),
    approveAdvertRequest: bindActionCreators(approveAdvertRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Advert));
