import * as apiHelpers from 'api';

const modules = 'skills';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_SKILLS_LIST_REQUEST = `${modules}/GET_SKILLS_LIST_REQUEST`;
export const GET_PARENT_SKILLS_LIST_REQUEST = `${modules}/GET_PARENT_SKILLS_LIST_REQUEST`;
export const GET_SKILL_REQUEST = `${modules}/GET_SKILL_REQUEST`;
export const ADD_SKILL_REQUEST = `${modules}/ADD_SKILL_REQUEST`;
export const UPDATE_SKILL_REQUEST = `${modules}/UPDATE_SKILL_REQUEST`;
export const DELETE_SKILL_REQUEST = `${modules}/DELETE_SKILL_REQUEST`;

export const getSkillsListRequest = actionCreator(GET_SKILLS_LIST_REQUEST);
export const getParentSkillsListRequest = actionCreator(GET_PARENT_SKILLS_LIST_REQUEST);
export const getSkillRequest = actionCreator(GET_SKILL_REQUEST);
export const addSkillRequest = actionCreator(ADD_SKILL_REQUEST);
export const updateSkillRequest = actionCreator(UPDATE_SKILL_REQUEST);
export const deleteSkillRequest = actionCreator(DELETE_SKILL_REQUEST);

apiRoutes.add(ADD_SKILL_REQUEST, data => ({
    url: `admin/skills`,
    method: 'post',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_SKILLS_LIST_REQUEST, data => ({
    url: `admin/skills`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_PARENT_SKILLS_LIST_REQUEST, data => ({
    url: `admin/skills`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_SKILL_REQUEST, data => ({
    url: `skills/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_SKILL_REQUEST, data => {
    return {
        url: `admin/skills/${data}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(UPDATE_SKILL_REQUEST, data => ({
    url: `admin/skills/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const skillsDataSelector = apiSelector(GET_SKILL_REQUEST, {
    initialData: {
        title: '',
        description: '',
        parent_skill_id: 0,
        uid: '',
        long_description: ''
    },
});

export const skillsListSelector = apiSelector(GET_SKILLS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});

export const parentSkillsListSelector = apiSelector(GET_PARENT_SKILLS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
