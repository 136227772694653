import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { eventsListSelector, getEventsListRequest} from 'modules/events';
import { eventsDataPrepare } from './helpers';
import { getFitersDataFromUrl } from 'helpers';

const headRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'id', isId: true },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'master_id', numeric: false, disablePadding: false, label: 'Master id' },
    { id: 'master', numeric: false, disablePadding: false, sort:false, label: 'Master' },
    { id: 'master_phone', numeric: false, disablePadding: false, sort:false, label: 'Master phone' },
    { id: 'user_id', numeric: false, disablePadding: false, label: 'User id' },
    { id: 'user', numeric: false, disablePadding: false, sort:false, label: 'User' },
    { id: 'user_phone', numeric: false, disablePadding: false, sort:false, label: 'User phone' },
    { id: 'salon_id', numeric: false, disablePadding: false, label: 'Salon' },
    { id: 'status_id', numeric: false, disablePadding: false, label: 'Status id' },
    { id: 'user_fee', numeric: true, disablePadding: false, sort:false, label: 'FEE' },
    { id: 'updated_at', numeric: false, disablePadding: false, sort:true, label: 'Updated date' },
];

class EventsList extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    eventsListPrepare = list => {
        return eventsDataPrepare(list);
    };
    editEventHandler = id => {
        const { history } = this.props;
        history.push(`/order/${id}`);
    };
    render() {
        const { eventsList, getEventsListRequest, permissions } = this.props;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getEventsListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.eventsListPrepare(eventsList.items)}
                    size={eventsList.size}
                    tableTitle={'Orders list'}
                    editEnable
                    showEditHead
                    editClickCallback={this.editEventHandler}
                />
            </React.Fragment>
        );
    }
}

EventsList.propTypes = {
    eventsList: PropTypes.object,
    getEventsListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    eventsList: eventsListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getEventsListRequest: bindActionCreators(getEventsListRequest, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventsList);
