import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import {
    getChatByIdRequest,
    sendMessageRequest,
    deleteMessageRequest,
    getChatsListSelector,
    getChatByIdSelector,
    startListenChat,
    stopListenChat,
    initListChat
} from 'modules/chat';

import ChatItem from './components/Chat';
import { addValueToReactComponent } from 'components/Table';
import { Table } from 'components';
import style from './style.scss';
import { FiberManualRecord} from '@material-ui/icons';
import {CHATS_DEFAULT_IDS} from 'config/chats';

const headRows = [
    { id: 'chat_id', numeric: false, disablePadding: false, label: 'Id', isId: true },
    { id: 'companion_name', numeric: false, disablePadding: false, label: 'Companion' },
    { id: 'companion_id', numeric: false, disablePadding: false, label: 'Companion ID' },
    { id: 'text', numeric: false, disablePadding: false, label: 'Text' },
    { id: 'last_visit', numeric: false, disablePadding: false, label: 'Last visit' },
    { id: 'new', numeric: false, disablePadding: false, label: 'New' },
];

const Chat = ({
    chatList,
    initListChat,
    chatData,
    getChatByIdRequest,
    sendMessageRequest,
    deleteMessageRequest,
    currentUser,
    match,
    history,
    startListenChat,
    stopListenChat,
    useHistory = true,
    filter,
    permissions
}) => {

    const chatId = get(match, 'params.chatId');
    const [activeChat, changeActiveChat] = React.useState(chatId || null);

    React.useEffect(() => {
        if (useHistory) {
            changeActiveChat(chatId);
        }
    }, [chatId]);
    React.useEffect(() => {
        if (activeChat) {
            startListenChat({ id: activeChat });
        } else {
            stopListenChat({ id: activeChat });
        }
    }, [activeChat]);

/*    const setActiveChat = (id) => {
        if (useHistory) {
            history.push(`/chats/${id}`);
        } else {
            changeActiveChat(id);
        }
    };*/
    const goBack = () => {
        if (useHistory) {
            history.push(`/chats/`);
        } else {
            changeActiveChat(null);
        }
    };

    const openHandler = id => {
        history.push(`/chats/${id}`);
    };

    const listPrepare = (list = [], user = {}) => {
        return  Array.isArray(list)
            ? list.map(item => {

                const newItem = { ...item };
                const first_name = get(item, 'companion.first_name', '');
                const last_name = get(item, 'companion.last_name', '');

                newItem.companion_name = addValueToReactComponent(
                    last_name ? first_name + ' ' + last_name : first_name,
                    newItem.companion_name
                );

                newItem.text = addValueToReactComponent(
                    item.type_id === 1 ? item.text.substring(0, 30) + '...' : 'image',
                    newItem.text
                );
                newItem.new = addValueToReactComponent(
                    item.is_read === 0 && item.sender_id === item.companion.id ?
                        <FiberManualRecord htmlColor={"green"}/> : '',
                    newItem.new
                );

                newItem.companion_id = addValueToReactComponent(
                    item.user1 === user.id ? item.user2 : item.user1,
                    newItem.companion_id
                );
                return newItem;
            })
            : [];
    };
    return (
        <div className={style.containerBox}>
            <div >
                {activeChat ? (
                    <ChatItem
                        chatId={activeChat}
                        getChatByIdRequest={getChatByIdRequest}
                        sendMessageRequest={sendMessageRequest}
                        deleteMessageRequest={deleteMessageRequest}
                        chatData={chatData}
                        currentUser={currentUser}
                        goBack={goBack}
                    />
                ) : (
                    /*<ChatList
                        chatList={chatList.items}
                        getChatsListRequest={getChatsListRequest}
                        setActiveChat={setActiveChat}
                    />*/
                    <Table
                        permissions={permissions}
                        updateListenerCallback={initListChat}
                        filter={filter}
                        headRows={headRows}
                        rows={listPrepare(chatList.items, currentUser)}
                        size={chatList.size}
                        tableTitle={'Chats list'}
                        editEnable
                        editClickCallback={openHandler}
                        enableSorting={false}
                    />
                )}
            </div>
        </div>
    );
};

Chat.propTypes = {
    chatList: PropTypes.object.isRequired,
    initListChat: PropTypes.func.isRequired,
    sendMessageRequest: PropTypes.func,
    deleteMessageRequest: PropTypes.func,
    viewPort: PropTypes.shape({
        isMobile: PropTypes.bool,
    }),
    useHistory: PropTypes.bool,
    chatData: PropTypes.object,
    getChatByIdRequest: PropTypes.func.isRequired,
    startListenChat: PropTypes.func.isRequired,
    stopListenChat: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    history: PropTypes.object,
    filter: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            chatId: PropTypes.any,
        }),
    }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    initListChat: bindActionCreators(initListChat, dispatch),
    getChatByIdRequest: bindActionCreators(getChatByIdRequest, dispatch),
    startListenChat: bindActionCreators(startListenChat, dispatch),
    sendMessageRequest: bindActionCreators(sendMessageRequest, dispatch),
    deleteMessageRequest: bindActionCreators(deleteMessageRequest, dispatch),
    stopListenChat: bindActionCreators(stopListenChat, dispatch),
});
const mapStateToProps = (state) => ({
    chatList: getChatsListSelector(state),
    chatData: getChatByIdSelector(state),
    currentUser: {id: CHATS_DEFAULT_IDS.currentUser},
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Chat);
