import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { advertsListSelector, getAdvertsListRequest, deleteAdvertRequest, approveAdvertRequest, declineAdvertRequest, deleteAdvertsRequest } from 'modules/adverts';
import { advertsDatePrepare } from './helpers';
import { getFitersDataFromUrl } from 'helpers';
import { Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core';

const headRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
    { id: 'type_id', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'user_id', numeric: false, disablePadding: false, label: 'User id' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'text', numeric: false, disablePadding: false, label: 'text' },
    { id: 'image', numeric: false, disablePadding: false, label: 'Image', type: 'image' },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'created_at' },
    { id: 'is_free', numeric: false, disablePadding: false, label: 'Free' },
    { id: 'is_moderated', numeric: false, disablePadding: false, label: 'Confirmed' },
    { id: 'dt_moderated', numeric: false, disablePadding: false, sort:false, label: 'Moderate' }
];

class AdvertsList extends PureComponent {
    state = {
        open_dialog: false,
        mode: '',
        advert_id: false
    };
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }

    addAdvertHandler = () => {
        const { history } = this.props;
        history.push('/create-advert');
    };

    editAdvertHandler = id => {
        const { history } = this.props;
        history.push(`/advert/${id}`);
    };

    advertsListPrepare = (list, handleClickOpenDialog) => {
        let result;
        result = advertsDatePrepare(list, handleClickOpenDialog);
        return result;
    };

    handleClickOpenDialog = (id, mode) => {
        this.setState({mode: mode});
        this.setState({advert_id: id});
        this.setState({open_dialog: true});
    };

    handleDialog = (id, mode) => {
        const {deleteAdvertRequest, approveAdvertRequest, declineAdvertRequest} = this.props;
        if (mode === 'approve') {
            approveAdvertRequest({ id: id}, {onSuccess: () => window.location.reload()});
        } else if (mode === 'decline') {
            declineAdvertRequest({ id: id}, {onSuccess: () => window.location.reload() });
        } else {
            deleteAdvertRequest({ id: id}, {onSuccess: () => window.location.reload() });
        }
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };
    render() {
        const { advertsList, getAdvertsListRequest, permissions } = this.props;
        const {
            filter,
            open_dialog,
            mode,
            advert_id
        } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getAdvertsListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.advertsListPrepare(advertsList.items, this.handleClickOpenDialog)}
                    size={advertsList.size}
                    tableTitle={'Adverts list'}
                    editEnable
                    deleteAction={deleteAdvertsRequest}
                    editClickCallback={this.editAdvertHandler}
                    addButtonListener={this.addAdvertHandler}
                    sort={'desc'}
                    perPage={25}
                    sortBy={'created_at'}
                />
                <Dialog
                    open={open_dialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleDialog( advert_id, mode )} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

AdvertsList.propTypes = {
    advertsList: PropTypes.object,
    getAdvertsListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    deleteAdvertRequest: PropTypes.func,
    approveAdvertRequest: PropTypes.func,
    declineAdvertRequest: PropTypes.func,
    deleteAdvertsRequest: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    advertsList: advertsListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getAdvertsListRequest: bindActionCreators(getAdvertsListRequest, dispatch),
    deleteAdvertRequest: bindActionCreators(deleteAdvertRequest, dispatch),
    approveAdvertRequest: bindActionCreators(approveAdvertRequest, dispatch),
    deleteAdvertsRequest: bindActionCreators(deleteAdvertsRequest, dispatch),
    declineAdvertRequest: bindActionCreators(declineAdvertRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvertsList);
