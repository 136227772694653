import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    customersListSelector,
    getCustomersListRequest,
    deleteCustomerRequest,
} from 'modules/customers';
import { customersDatePrepare } from './helpers';
import { getFitersDataFromUrl } from 'helpers';

const headRows = [
    { id: 'id', numeric: true, disablePadding: true, label: 'Id' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
    { id: 'first_name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'last_name', numeric: false, disablePadding: false, label: 'Last name' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    {
        id: 'device_token',
        numeric: false,
        disablePadding: false,
        sort: false,
        label: 'Device type',
    },
    { id: 'is_deleted', numeric: false, disablePadding: false, label: 'Deleted' },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Created at' },
];

class CustomersList extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    addCustomerHandler = () => {
        const { history } = this.props;
        history.push('/create-customer');
    };
    editCustomerHandler = id => {
        const { history } = this.props;
        history.push(`/customer/${id}`);
    };
    customersListPrepare = list => {
        let result = [];
        result = customersDatePrepare(list);
        return result;
    };
    render() {
        const {
            customersList,
            getCustomersListRequest,
            deleteCustomerRequest,
            permissions,
        } = this.props;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getCustomersListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.customersListPrepare(customersList.items)}
                    size={customersList.size}
                    tableTitle={'Customers list'}
                    editEnable
                    deleteAction={deleteCustomerRequest}
                    editClickCallback={this.editCustomerHandler}
                    addButtonListener={this.addCustomerHandler}
                />
            </React.Fragment>
        );
    }
}

CustomersList.propTypes = {
    customersList: PropTypes.object,
    getCustomersListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    deleteCustomerRequest: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    customersList: customersListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getCustomersListRequest: bindActionCreators(getCustomersListRequest, dispatch),
    deleteCustomerRequest: bindActionCreators(deleteCustomerRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersList);
