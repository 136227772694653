import React from 'react';
import PropTypes from 'prop-types';

const Home = ({ ...props }) => {
    const handleClick = () => {};
    return <div></div>;
};

Home.propTypes = {
    // : PropTypes.
};

export default Home;
