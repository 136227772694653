/* global process:reaqdonly */
import { all, put, select, delay, call } from 'redux-saga/effects';
import * as apiHelpers from 'api';
import { set, get } from 'lodash';
import { chatSaga } from 'modules/chat';

import devConf from 'config/dev';
import prodConf from 'config/prod';
import { authSaga, getUserCredentials } from 'modules/auth';
import history from 'store/history';
import { notificationSaga, showError, showSuccess } from '../notifications';
import { loaderSaga, showLoader, hideLoader } from '../loader';
import { initAction, initSaga } from '../init';
import { getRegionsRequest, getZonesListByRegionRequest } from '../regions';
import { getFaresListRequest } from '../fares';
import { faqSaga } from '../faq';
const config = process.env.NODE_ENV === 'development' ? devConf : prodConf;
const {
    modules: { apiWatchRequest },
    axios: { init },
} = apiHelpers;

if (process.env.NODE_ENV == 'development') {
    init('https://apidev.feelqueen.by/api/v2/');
} else if (process.env.NODE_ENV == 'production') {
    init('https://api.feelqueen.by/api/v2/');
}

// TODO:  need refactoring
function* test() {
    // use for init some data
    yield put(initAction());
    yield delay(1000);
}

function* rootSaga() {
    yield all([
        apiWatchRequest({
            additiveCallback: function*({ showLoaderFlag = true, ...data }) {
                //show loader
                if (showLoaderFlag) {
                    yield put(showLoader());
                }

                // add credentials for  request
                const credentials = yield select(getUserCredentials);
                if (credentials) {
                    data.headers = {};
                    set(data, 'headers.Authorization', `Bearer ${credentials.access_token}`);
                }
                return data;
            },
            successCallback: function*(data) {
                yield put(hideLoader());
                if (
                    data.config.method === 'put' ||
                    data.config.method === 'post' ||
                    data.config.method === 'delete'
                ) {
                    yield put(showSuccess({ message: 'Successful operation.' }));
                }
            },
            failedCallback: function*(data) {
                const dataStatus = data.status;
                // redirect to login
                if (dataStatus === 401 || dataStatus === 403) {
                    yield call(history.push, '/login');
                }
                if (dataStatus === 500) {
                    yield put(showError({ message: 'Internal server error.' }));
                }
                if (dataStatus === 406) {
                    yield put(showError({ message: data.response.data.message }));
                }
                // hide loader
                yield put(hideLoader());
                // show error message
                if (data.hasOwnProperty('errors') && typeof data.errors !== 'undefined') {
                    for (var key of Object.keys(data.errors)) {
                        yield put(showError({ message: data.errors[key] }));
                    }
                }
            },
        }),
        notificationSaga(),
        authSaga(),
        loaderSaga(),
        initSaga(),
        chatSaga(),
        test(),
        faqSaga(),
    ]);
}

export default rootSaga;
