import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, CheckboxDialog } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { deleteSalonRequest, getSalonsListRequest, salonsListSelector, updateSalonRequest} from 'modules/salons';
import { getFitersDataFromUrl } from 'helpers';
import { get } from 'lodash';
import { addValueToReactComponent } from '../../components/Table';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Done, Delete } from '@material-ui/icons';

const headRows = [
    { id: 'id', numeric: true, disablePadding: true, label: 'Id'},
    { id: 'title', numeric: false, disablePadding: false, label: 'Title'},
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone'},
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Creation Date'},
    { id: 'admin_id', numeric: true, disablePadding: true, sort: false, label: 'Admin Id'},
    { id: 'admin_name', numeric: true, disablePadding: true, sort: false, label: 'Admin Name'},
    { id: 'city', numeric: false, disablePadding: false, label: 'City' },
    { id: 'address', numeric: false, disablePadding: false, sort:false, label: 'Address' },
    { id: 'is_deleted', numeric: false, disablePadding: false, label: 'Deleted'},
    { id: 'hidden', numeric: false, disablePadding: false, label: 'Show in catalog'}
];

class SalonsList extends PureComponent {
    state = {
        open_dialog: false,
        salon_id: false,
    };
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    handleClickOpenDialog = (id) => {
        this.setState({salon_id: id});
        this.setState({open_dialog: true});
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };

    deleteSalon = (id) => {
        const {deleteSalonRequest} = this.props;
        deleteSalonRequest({ id: id}, {onSuccess: () => window.location.reload() });
    };

    editSalonHandler = id => {
        const { history } = this.props;
        history.push(`/salon/${id}`);
    };

    salonsListPrepare = (list) => {
        const { updateSalonRequest } = this.props;
        return Array.isArray(list)
            ? list.map(item => {
                const newItem = { ...item };
                const admin_id = get(item, 'main_admin.id', 0);
                const admin_name = get(item, 'main_admin.name', '-');
                const address = get(item, 'location.address', '-');
                const city = get(item, 'location.city', '-');
                const place = get(item, 'location.place', '-');
                const metro = get(item, 'location.metro_station.title', '-');

                newItem.admin_id = addValueToReactComponent(
                    admin_id,
                    newItem.admin_id
                );
                newItem.admin_name = addValueToReactComponent(
                    admin_name,
                    newItem.admin_name
                );
                newItem.is_deleted = addValueToReactComponent(
                    item.deleted_at ? (
                            <IconButton>
                                <Done/>
                            </IconButton>
                        ) :
                        (
                            <div>
                                <IconButton onClick={() => this.handleClickOpenDialog( item.id)}>
                                    <Delete/>
                                </IconButton>
                            </div>
                        ),
                    newItem.is_deleted
                );
                newItem.city = addValueToReactComponent(
                    city,
                    newItem.city
                );
                newItem.address = addValueToReactComponent(
                    address + ' | ' + place + ' | ' + metro,
                    newItem.address
                );

                newItem.hidden = addValueToReactComponent(
                    <CheckboxDialog active={item.hidden} options={{id: item.id}} setValue={updateSalonRequest}/>,
                    newItem.hidden
                );

                return newItem;
            }) : [];
    };
    render() {
        const { getSalonsListRequest, permissions, salonsList } = this.props;
        const { filter, salon_id, open_dialog } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getSalonsListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.salonsListPrepare(salonsList.items)}
                    size={salonsList.size}
                    tableTitle={'Salons list'}
                    editEnable
                    sort={'desc'}
                    sortBy={'id'}
                    deleteAction={getSalonsListRequest}
                    editClickCallback={this.editSalonHandler}
                />
                <Dialog
                    open={open_dialog}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteSalon( salon_id )} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

SalonsList.propTypes = {
    salonsList: PropTypes.object,
    getSalonsListRequest: PropTypes.func,
    deleteSalonRequest: PropTypes.func,
    updateSalonRequest: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    salonsList: salonsListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getSalonsListRequest: bindActionCreators(getSalonsListRequest, dispatch),
    deleteSalonRequest: bindActionCreators(deleteSalonRequest, dispatch),
    updateSalonRequest: bindActionCreators(updateSalonRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalonsList);
