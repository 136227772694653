import React, { Fragment } from 'react';
import { addValueToReactComponent } from 'components/Table';
import { Close, Done, FiberManualRecord } from '@material-ui/icons';
import EditSave from './components/EidtSaveInput';
import SubCategory from './components/SubCategory';
import Category from './components/Category';
import {
    addFaqSubCategoryRequest,
    updateFaqCategoryRequest,
    deleteFaqCategoryRequest,
    updateFaqSubCategoryRequest,
    addFaqCategoryRequest,
    deleteFaqSubCategoryRequest,
    updateFaqQuestionRequest,
    addFaqQuestionRequest,
    deleteQuestionRequest,
} from 'modules/faq';

export const faqsDataPrepare = ({ list, reload, dispatch }) => {
    return Array.isArray(list)
        ? list.map(item => {
              const newItem = { ...item };
              newItem.title = addValueToReactComponent(
                  <Category
                      data={{ ...item }}
                      onSave={data => {
                          if (item.empty) {
                              dispatch(
                                  addFaqCategoryRequest({
                                      ...data,
                                  })
                              );
                          } else {
                              dispatch(
                                  updateFaqCategoryRequest({
                                      id: item.id,
                                      ...data,
                                  })
                              );
                          }
                      }}
                  />,
                  newItem.title
              );

              newItem.sub_categories = addValueToReactComponent(
                  <SubCategory
                      items={item.sub_categories}
                      categoryId={item.id}
                      onAddSubCategory={({ ...data }) => {
                          dispatch(addFaqSubCategoryRequest({ ...data }));
                      }}
                      onSaveQuestion={({ ...data }) => {
                          dispatch(updateFaqQuestionRequest({ ...data }));
                      }}
                      onDeleteQuestion={({ ...data }) => {
                          dispatch(deleteQuestionRequest({ ...data }));
                      }}
                      onAddQuestion={({ ...data }) => {
                          dispatch(addFaqQuestionRequest({ ...data }));
                      }}
                      onDelete={({ ...data }) => {
                          dispatch(deleteFaqSubCategoryRequest(data));
                      }}
                      onSave={({ id, title }) =>
                          dispatch(
                              updateFaqSubCategoryRequest({
                                  title,
                                  id,
                                  id_cat: item.id,
                              })
                          )
                      }
                  />,

                  newItem.sub_categories
              );
              return newItem;
          })
        : [];
};
