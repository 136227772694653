import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    FormGroup,
    FormControlLabel,
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import { Delete, Stars, ArrowUpward, Attachment } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
    addUserRequest,
    getUserRequest,
    updateUserRequest,
    updateUserAddressRequest,
    updateUserProfileRequest,
    userDataSelector,
    unLockUserRequest,
    deleteUserRequest,
    restoreUserRequest,
    getListSkillsRequest,
    deleteSkillRequest,
    updateSkillRequest,
    attachSkillRequest,
    deleteAvatarRequest,
    deletePortfolioImageRequest,
    upPortfolioImageRequest,
    changeTypePortfolioImageRequest,
    getListSkillsSelector,
    changePortfolioTagsRequest,
} from 'modules/user';
import { FormCore } from 'components';
import UserSkills from './components/UserSkills';
import style from './styles.scss';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
});
const animatedComponents = makeAnimated();

class User extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        address: '',
        place: '',
        lat: '',
        long: '',
        metro: '',
        education: '',
        status: '',
        experience: '',
        instagram: '',
        is_work_from_home: 0,
        is_work_at_client: 0,
        skills: [],
        skillsList: [],
        working_time_intervals: { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] },
        open_confirm_dialog: false,
        mode: '',
        is_deleted: false,
        image_id: 0,
        open_tags_dialog: false,
        portfolio_id: 0,
        tags: [],
        selectedTags: [],
        raw_tags: '',
        payment_info: {},
        currency_id: 1,
        last_payed_date: '',
    };

    componentDidMount() {
        const {
            getListSkillsRequest,
            route: { isAdd },
            match,
        } = this.props;
        if (!isAdd) {
            this.getUser(match.params.id);
        }
        getListSkillsRequest();
    }

    getUser = id => {
        const { getUserRequest } = this.props;
        getUserRequest(id, {
            onSuccess: data => {
                const { ...userData } = data.data;
                if (userData.address !== null) {
                    userData.metro = userData.address.metro_station
                        ? userData.address.metro_station.title
                        : '';
                    userData.city = userData.address.city;
                    userData.country = userData.address.country;
                    userData.place = userData.address.place;
                    userData.lat = userData.address.lat;
                    userData.long = userData.address.long;
                    userData.address = userData.address.address;
                }
                if (userData.profile !== null) {
                    userData.education = userData.profile.education;
                    userData.status = userData.profile.status;
                    userData.experience = userData.profile.experience;
                    userData.instagram = userData.profile.instagram;
                    userData.is_work_from_home = userData.profile.is_work_from_home;
                    userData.is_work_at_client = userData.profile.is_work_at_client;
                }

                this.setState({
                    ...userData,
                    working_time_intervals: userData.working_time_intervals
                        ? userData.working_time_intervals
                        : {
                              mon: [],
                              tue: [],
                              wed: [],
                              thu: [],
                              fri: [],
                              sat: [],
                              sun: [],
                          },
                });
            },
        });
    };

    handleChangeForm = (data, name) => {
        const { ...state } = this.state;
        state[name] = data;
        this.setState({ ...state });
    };

    handleChangeInputs = ev => {
        this.handleChangeForm(ev.target.value, ev.target.name);
    };

    handleCheck = name => event => {
        const { ...state } = this.state;
        this.setState({ ...state, [name]: event.target.checked });
    };

    handlerCancelButton = () => {
        const { history } = this.props;
        history.push('/masters');
    };

    handleDelete = () => {
        const { route, deleteUserRequest } = this.props;
        const { id } = this.state;
        if (!route.isAdd) {
            deleteUserRequest({ id }, { onSuccess: () => this.successUpdating(id) });
        }
    };

    handleDeleteAvatar = () => {
        const { route, deleteAvatarRequest } = this.props;
        const { id } = this.state;
        if (!route.isAdd) {
            deleteAvatarRequest({ id }, { onSuccess: () => this.successUpdating(id) });
        }
    };

    handleDeletePortfolioImage = () => {
        const { route, deletePortfolioImageRequest } = this.props;
        const { id, image_id } = this.state;

        if (!route.isAdd) {
            deletePortfolioImageRequest(
                { user_id: id, image_id: image_id },
                { onSuccess: () => this.successUpdating(id) }
            );
        }
    };

    handleUpPortfolioImage = () => {
        const { route, upPortfolioImageRequest } = this.props;
        const { id, image_id } = this.state;

        if (!route.isAdd) {
            upPortfolioImageRequest(
                { user_id: id, image_id: image_id },
                { onSuccess: () => this.successUpdating(id) }
            );
        }
    };

    handleChangeTypePortfolioImage = () => {
        const { route, changeTypePortfolioImageRequest } = this.props;
        const { id, image_id } = this.state;

        if (!route.isAdd) {
            changeTypePortfolioImageRequest(
                { user_id: id, image_id: image_id },
                { onSuccess: () => this.successUpdating(id) }
            );
        }
    };

    handleRestore = () => {
        const { route, restoreUserRequest } = this.props;
        const { id } = this.state;
        if (!route.isAdd) {
            restoreUserRequest({ id }, { onSuccess: () => this.successUpdating(id) });
        }
    };

    handleSaveGeneralInfo = () => {
        const { route, updateUserRequest } = this.props;
        const { id, first_name, last_name, email, phone } = this.state;
        if (!route.isAdd) {
            updateUserRequest(
                { id, first_name, last_name, email, phone },
                { onSuccess: () => this.successUpdating(id) }
            );
        }
    };

    handleSaveAddress = () => {
        const { route, updateUserAddressRequest } = this.props;
        const { id, address, city, country, place, lat, long } = this.state;
        if (!route.isAdd) {
            updateUserAddressRequest({ id, address, city, country, place, lat, long });
        }
    };

    handleSaveProfile = () => {
        const { route, updateUserProfileRequest } = this.props;
        const {
            id,
            education,
            status,
            experience,
            instagram,
            is_work_from_home,
            is_work_at_client,
        } = this.state;
        if (!route.isAdd) {
            updateUserProfileRequest(
                {
                    id,
                    education,
                    status,
                    experience,
                    instagram,
                    is_work_from_home,
                    is_work_at_client,
                },
                { onSuccess: () => this.successUpdating(id) }
            );
        }
    };

    handleChangePortfolioTags = () => {
        const { route, changePortfolioTagsRequest } = this.props;
        const { id, portfolio_id, tags, raw_tags } = this.state;

        if (!route.isAdd) {
            changePortfolioTagsRequest(
                {
                    user_id: id,
                    portfolio_id: portfolio_id,
                    tags: tags != null ? tags.map(tagObject => tagObject.value) : null,
                    raw_tags: raw_tags,
                },
                { onSuccess: () => this.successUpdating(id) }
            );
        }
    };

    userSkillsPrepare = list => {
        return Array.isArray(list)
            ? list.map(item => {
                  const newItem = { ...item };
                  let skillsList = this.props.skillsList;
                  const parentSkill = skillsList.find(searchItem => {
                      return searchItem.id === item.parent_skill_id;
                  });

                  newItem.from = newItem.pivot.from;
                  newItem.to = newItem.pivot.to;
                  newItem.duration = newItem.pivot.duration;
                  newItem.skill_id = newItem.pivot.skill_id;
                  newItem.user_id = this.state.id;
                  newItem.parent = parentSkill ? parentSkill.title : '';

                  return newItem;
              })
            : [];
    };

    preparePortfolioImages = (list, type) => {
        let images = [];
        if (Array.isArray(list)) {
            list.forEach(item => {
                let tags = item.tags.map(tagObject => tagObject.title).join(', ');
                let selectedTags = item.tags.map(tagObject => ({
                    value: tagObject.id,
                    label: tagObject.title,
                }));
                let raw_tags = item.tags.map(tagObject => tagObject.id).join(', ');
                let portfolioId = item.id;
                if (type === item.type) {
                    item.images.forEach(imageObject => {
                        imageObject.tags = tags;
                        imageObject.selectedTags = selectedTags;
                        imageObject.raw_tags = raw_tags;
                        imageObject.portfolioId = portfolioId;
                        images.push(imageObject);
                    });
                }
            });
        }
        images.sort((a, b) => b.id - a.id);
        images.sort((a, b) => b.priority - a.priority);

        return images;
    };

    prepareUserSkills = list => {
        return list.map(item => ({ value: item.id, label: item.title }));
    };

    handleClickOpenDialog = () => {
        this.setState({ open_confirm_dialog: true });
    };

    handleDeleteUserClick = () => {
        this.setState({ mode: 'deleteUser' });
        this.handleClickOpenDialog();
    };

    handleDeleteUserAvatarClick = () => {
        this.setState({ mode: 'deleteUserAvatar' });
        this.handleClickOpenDialog();
    };

    handleDeleteUserPortfolioImageClick = image_id => {
        this.setState({ mode: 'deleteUserPortfolioImage', image_id: image_id });
        this.handleClickOpenDialog();
    };

    handleUpPortfolioImageClick = image_id => {
        this.setState({ mode: 'upUserPortfolioImage', image_id: image_id });
        this.handleClickOpenDialog();
    };

    handleChangeTypePortfolioImageClick = image_id => {
        this.setState({ mode: 'changeTypePortfolioImage', image_id: image_id });
        this.handleClickOpenDialog();
    };

    handleAttachTags = item => {
        this.setState({ portfolio_id: item.portfolioId });
        this.setState({ selectedTags: item.selectedTags });
        this.setState({ raw_tags: item.raw_tags });
        this.setState({ open_tags_dialog: true });
    };

    handleCloseDialog = () => {
        this.setState({ open_confirm_dialog: false });
        this.setState({ open_tags_dialog: false });
    };

    handleDialogClick = () => {
        const { mode, is_deleted } = this.state;
        if (mode === 'deleteUser') {
            if (is_deleted) {
                this.handleRestore();
            } else {
                this.handleDelete();
            }
        } else if (mode === 'deleteUserAvatar') {
            this.handleDeleteAvatar();
        } else if (mode === 'deleteUserPortfolioImage') {
            this.handleDeletePortfolioImage();
        } else if (mode === 'upUserPortfolioImage') {
            this.handleUpPortfolioImage();
        } else if (mode === 'changeTypePortfolioImage') {
            this.handleChangeTypePortfolioImage();
        } else {
            this.handleCloseDialog();
        }
    };

    handleChangeSelect = selectedOptions => {
        this.setState({ tags: selectedOptions });
    };

    successUpdating = id => {
        this.setState({ open_confirm_dialog: false });
        this.setState({ open_tags_dialog: false });
        this.getUser(id);
    };

    render() {
        const {
            classes,
            permissions,
            route: { isAdd },
            deleteSkillRequest,
            updateSkillRequest,
            attachSkillRequest,
            skillsList,
        } = this.props;
        const {
            id,
            first_name,
            last_name,
            email,
            phone,
            is_deleted,
            address,
            city,
            country,
            place,
            lat,
            long,
            metro,
            education,
            status,
            experience,
            instagram,
            is_work_at_client,
            is_work_from_home,
            skills,
            open_confirm_dialog,
            avatar,
            portfolio,
            working_time_intervals,
            open_tags_dialog,
            selectedTags,
            raw_tags,
            payment_info,
            currency_id,
            last_payed_date,
        } = this.state;

        return (
            <div className={classes.root}>
                <FormCore
                    permissions={permissions}
                    handlerSubmit={this.handlerSubmit}
                    handlerCancelButton={this.handlerCancelButton}
                    title={`Master ${isAdd ? 'creation' : 'edit'}  page`}
                    requiredMap={[
                        { title: 'Name', name: 'first_name' },
                        { title: 'Lastname', name: 'last_name' },
                        { title: 'Phone', name: 'phone' },
                    ]}
                    displayButtons={false}
                >
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>General info</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={8} alignItems="center" justify="center">
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Name"
                                        name="first_name"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={first_name}
                                        required
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Last name"
                                        name="last_name"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={last_name}
                                        required
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Email"
                                        margin="normal"
                                        type="email"
                                        name="email"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={email}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Phone"
                                        margin="normal"
                                        name="phone"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={phone}
                                        required
                                        disabled={!permissions.update}
                                    />

                                    <Grid
                                        container
                                        spacing={8}
                                        alignItems="center"
                                        justify="center"
                                        classes={{ root: style.buttonContainer }}
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={this.handleSaveGeneralInfo}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid item xs={12}>
                                        <div>
                                            <b>Pending commission: </b>
                                            {payment_info.commission}{' '}
                                            {currency_id === 1 ? ' BYN' : ' RUB'}
                                        </div>
                                        <div>
                                            <b>Payed monthly commission: </b>
                                            {payment_info.monthly_payed}{' '}
                                            {currency_id === 1 ? ' BYN' : ' RUB'}
                                        </div>
                                        <div>
                                            <b>Payed yearly commission: </b>
                                            {payment_info.yearly_payed}{' '}
                                            {currency_id === 1 ? ' BYN' : ' RUB'}
                                        </div>
                                        <div>
                                            <b>Last payed date: </b>
                                            {payment_info.last_payed_date}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.handleDeleteUserClick}
                                    >
                                        {is_deleted ? 'Restore' : 'Delete'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Address</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={8} alignItems="center" justify="center">
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Address"
                                        name="address"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={address}
                                        required
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="City"
                                        name="city"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={city}
                                        required
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Country"
                                        margin="normal"
                                        name="country"
                                        required
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={country}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Place"
                                        margin="normal"
                                        name="place"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={place}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Latitude"
                                        margin="normal"
                                        name="lat"
                                        required
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={lat}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Longitude"
                                        margin="normal"
                                        name="long"
                                        required
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={long}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Metro"
                                        name="metro"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={metro}
                                        disabled
                                    />

                                    <Grid
                                        container
                                        spacing={8}
                                        alignItems="center"
                                        justify="center"
                                        classes={{ root: style.buttonContainer }}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={this.handleSaveAddress}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className={classes.heading}>Profile</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={8} alignItems="center" justify="center">
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Education"
                                        name="education"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={education}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Status"
                                        name="status"
                                        margin="normal"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={status}
                                        disabled={!permissions.update}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Experience"
                                        margin="normal"
                                        name="experience"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={experience}
                                        disabled={!permissions.update}
                                        multiline
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Instagram"
                                        margin="normal"
                                        name="instagram"
                                        onChange={this.handleChangeInputs}
                                        classes={{ root: style.inputs }}
                                        value={instagram}
                                        disabled={!permissions.update}
                                    />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(is_work_from_home)}
                                                    onChange={this.handleCheck('is_work_from_home')}
                                                    value={is_work_from_home}
                                                />
                                            }
                                            label="Master works from home"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(is_work_at_client)}
                                                    onChange={this.handleCheck('is_work_at_client')}
                                                    value={is_work_at_client}
                                                />
                                            }
                                            label="Master works at client"
                                        />
                                    </FormGroup>

                                    <Grid
                                        container
                                        spacing={8}
                                        alignItems="center"
                                        justify="center"
                                        classes={{ root: style.buttonContainer }}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={this.handleSaveProfile}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className={classes.heading}>Skills</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={8} alignItems="center" justify="center">
                                <Grid item xs={12} md={12}>
                                    <UserSkills
                                        rows={this.userSkillsPrepare(skills)}
                                        deleteSkillRequest={({ user_id, skill_id }) => {
                                            deleteSkillRequest(
                                                { user_id, skill_id },
                                                { onSuccess: () => this.getUser(id) }
                                            );
                                        }}
                                        updateSkillRequest={data => {
                                            updateSkillRequest(data, {
                                                onSuccess: () => this.getUser(id),
                                            });
                                        }}
                                        skillsList={skillsList}
                                        attachSkillRequest={data => {
                                            attachSkillRequest(data, {
                                                onSuccess: () => this.getUser(id),
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography className={classes.heading}>Events</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <p>
                                    <Link
                                        onClick={() =>
                                            window.open(`/orders/?filter={"master_id":"${id}"}`)
                                        }
                                    >
                                        Заявки мастеру
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        onClick={() =>
                                            window.open(
                                                `/events/?filter={"master_id":"${id}","status_id":"3"}`
                                            )
                                        }
                                    >
                                        Подтвержденные
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        onClick={() =>
                                            window.open(
                                                `/events/?filter={"master_id":"${id}","status_id":"4"}`
                                            )
                                        }
                                    >
                                        Завершенные
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        onClick={() =>
                                            window.open(
                                                `/events/?filter={"master_id":"${id}","status_id":"6"}`
                                            )
                                        }
                                    >
                                        Отмененные мастером
                                    </Link>
                                </p>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6a-content"
                            id="panel6a-header"
                        >
                            <Typography className={classes.heading}>Working time</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={12} alignItems="center" justify="center">
                                <Grid item xs={3} md={3}>
                                    <h3>Monday</h3>
                                    {working_time_intervals.hasOwnProperty('mon')
                                        ? working_time_intervals.mon.map((item, key) => (
                                              <p key={'working_time_mon' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <h3>Tuesday</h3>
                                    {working_time_intervals.hasOwnProperty('tue')
                                        ? working_time_intervals.tue.map((item, key) => (
                                              <p key={'working_time_tue' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <h3>Wednesday</h3>
                                    {working_time_intervals.hasOwnProperty('wed')
                                        ? working_time_intervals.wed.map((item, key) => (
                                              <p key={'working_time_wed' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <h3>Thursday</h3>
                                    {working_time_intervals.hasOwnProperty('thu')
                                        ? working_time_intervals.thu.map((item, key) => (
                                              <p key={'working_time_thu' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                            </Grid>
                            <Grid container spacing={12} alignItems="center" justify="center">
                                <Grid item xs={3} md={3}>
                                    <h3>Friday</h3>
                                    {working_time_intervals.hasOwnProperty('fri')
                                        ? working_time_intervals.fri.map((item, key) => (
                                              <p key={'working_time_fri' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <h3>Saturday</h3>
                                    {working_time_intervals.hasOwnProperty('sat')
                                        ? working_time_intervals.sat.map((item, key) => (
                                              <p key={'working_time_sat' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <h3>Sunday</h3>
                                    {working_time_intervals.hasOwnProperty('sun')
                                        ? working_time_intervals.sun.map((item, key) => (
                                              <p key={'working_time_sun' + key}>
                                                  {item.start + '-' + item.end}
                                              </p>
                                          ))
                                        : ''}
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel className={style.panel}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7a-content"
                            id="panel7a-header"
                        >
                            <Typography className={classes.heading}>Pictures</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={12} alignItems="center" justify="center">
                                <Grid item xs={12} md={12}>
                                    <Grid item xs={3} md={3}>
                                        <h3>Avatar</h3>
                                        {avatar ? (
                                            <Card className={classes.root}>
                                                <CardMedia>
                                                    <img
                                                        key="avatar"
                                                        src={avatar}
                                                        alt="img"
                                                        width={'300px'}
                                                    />
                                                </CardMedia>
                                                <CardContent>
                                                    <IconButton
                                                        aria-label="Delete"
                                                        onClick={this.handleDeleteUserAvatarClick}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </CardContent>
                                            </Card>
                                        ) : (
                                            ''
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <h3>Working Place</h3>
                                    <div className={classes.root}>
                                        <GridList
                                            cols={2.5}
                                            className={classes.gridList}
                                            cellHeight={300}
                                        >
                                            {this.preparePortfolioImages(
                                                portfolio,
                                                'working_place'
                                            ).map((item, key) => (
                                                <GridListTile key={'working_place' + key}>
                                                    <img
                                                        key={'working_place' + item.id}
                                                        src={item.path}
                                                        alt="img"
                                                        className={'working_place-image-' + item.id}
                                                    />
                                                    <GridListTileBar
                                                        title={'Место ' + (key + 1)}
                                                        className={style.titleBar}
                                                        actionIcon={
                                                            <div>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleDeleteUserPortfolioImageClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleUpPortfolioImageClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <Stars
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleChangeTypePortfolioImageClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <ArrowUpward
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                            </div>
                                                        }
                                                    />
                                                </GridListTile>
                                            ))}
                                        </GridList>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <h3>Portfolio</h3>
                                    <div className={classes.root}>
                                        <GridList
                                            cols={2.5}
                                            className={classes.gridList}
                                            cellHeight={300}
                                        >
                                            {this.preparePortfolioImages(
                                                portfolio,
                                                'portfolio'
                                            ).map((item, key) => (
                                                <GridListTile key={'portfolio' + key}>
                                                    <img
                                                        key={'portfolio' + item.id}
                                                        src={item.path}
                                                        alt="img"
                                                        className={'portfolio-image-' + item.id}
                                                    />
                                                    <GridListTileBar
                                                        title={'Место ' + (key + 1)}
                                                        subtitle={'Tags:' + item.tags}
                                                        className={style.titleBar}
                                                        actionIcon={
                                                            <div>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleDeleteUserPortfolioImageClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleUpPortfolioImageClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <Stars
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleChangeTypePortfolioImageClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <ArrowUpward
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        this.handleAttachTags(item)
                                                                    }
                                                                >
                                                                    <Attachment
                                                                        className={style.title}
                                                                    />
                                                                </IconButton>
                                                            </div>
                                                        }
                                                    />
                                                </GridListTile>
                                            ))}
                                        </GridList>
                                    </div>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Dialog
                        open={open_confirm_dialog}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        key={'Confirmation Dialog'}
                    >
                        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleDialogClick} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={open_tags_dialog}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        key={'Tags Dialog'}
                        maxWidth={'md'}
                        fullWidth
                    >
                        <DialogTitle id="alert-dialog-title">{'Select Tags'}</DialogTitle>
                        <DialogContent style={{ height: '300px' }}>
                            <TextField
                                variant="outlined"
                                name="raw_tags"
                                margin="normal"
                                type="string"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={raw_tags}
                                size={'small'}
                            />
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={selectedTags}
                                isMulti
                                options={this.prepareUserSkills(skills)}
                                onChange={this.handleChangeSelect}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleChangePortfolioTags} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormCore>
            </div>
        );
    }
}

User.propTypes = {
    history: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    updateUserRequest: PropTypes.func.isRequired,
    updateUserAddressRequest: PropTypes.func.isRequired,
    updateUserProfileRequest: PropTypes.func.isRequired,
    getUserRequest: PropTypes.func.isRequired,
    deleteUserRequest: PropTypes.func.isRequired,
    restoreUserRequest: PropTypes.func.isRequired,
    getListSkillsRequest: PropTypes.func.isRequired,
    deleteSkillRequest: PropTypes.func.isRequired,
    updateSkillRequest: PropTypes.func.isRequired,
    attachSkillRequest: PropTypes.func.isRequired,
    deleteAvatarRequest: PropTypes.func.isRequired,
    deletePortfolioImageRequest: PropTypes.func.isRequired,
    upPortfolioImageRequest: PropTypes.func.isRequired,
    changeTypePortfolioImageRequest: PropTypes.func.isRequired,
    changePortfolioTagsRequest: PropTypes.func.isRequired,
    skillsList: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};
User.defaultProps = {
    userData: {
        id: 1,
        first_name: 'TEsts',
        last_name: 'testLastName',
        email: 'test@test.com',
        phone: '+375295298849',
        instagram: 'instagram',
        payment_info: {
            commission: 0,
            monthly_payed: 0,
            yearly_payed: 0,
            last_payed_date: '',
        },
        address: {
            city: '',
            metro_station: '',
        },
        profile: {
            instagram: '',
        },
        raw_tags: '',
        currency_id: 1,
    },
    skills: {
        pivot: {},
    },
};

const mapStateToProps = state => ({
    userData: userDataSelector(state),
    skillsList: getListSkillsSelector(state),
});

const mapDispatchToProps = dispatch => ({
    addUserRequest: bindActionCreators(addUserRequest, dispatch),
    unLockUserRequest: bindActionCreators(unLockUserRequest, dispatch),
    getUserRequest: bindActionCreators(getUserRequest, dispatch),
    updateUserRequest: bindActionCreators(updateUserRequest, dispatch),
    updateUserAddressRequest: bindActionCreators(updateUserAddressRequest, dispatch),
    updateUserProfileRequest: bindActionCreators(updateUserProfileRequest, dispatch),
    deleteUserRequest: bindActionCreators(deleteUserRequest, dispatch),
    restoreUserRequest: bindActionCreators(restoreUserRequest, dispatch),
    getListSkillsRequest: bindActionCreators(getListSkillsRequest, dispatch),
    deleteSkillRequest: bindActionCreators(deleteSkillRequest, dispatch),
    updateSkillRequest: bindActionCreators(updateSkillRequest, dispatch),
    attachSkillRequest: bindActionCreators(attachSkillRequest, dispatch),
    deleteAvatarRequest: bindActionCreators(deleteAvatarRequest, dispatch),
    deletePortfolioImageRequest: bindActionCreators(deletePortfolioImageRequest, dispatch),
    upPortfolioImageRequest: bindActionCreators(upPortfolioImageRequest, dispatch),
    changeTypePortfolioImageRequest: bindActionCreators(changeTypePortfolioImageRequest, dispatch),
    changePortfolioTagsRequest: bindActionCreators(changePortfolioTagsRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(User));
