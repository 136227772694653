import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Dialog, DialogActions, DialogTitle, Grid, Link, Typography } from '@material-ui/core';
import {
    addCustomerRequest,
    customerDataSelector,
    deleteCustomerRequest,
    restoreCustomerRequest,
    getCustomerRequest,
    updateCustomerRequest,
} from 'modules/customers';
import {
    FormCore
} from 'components';
import style from './styles.scss';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
});

class Customer extends React.Component {
    state = {
        first_name: '', last_name: '', email: '', phone: '', is_deleted: false, open_dialog: false
    };

    componentDidMount() {
        this.getCustomerData();
    }

    handleChangeForm = (data, name) => {
        const { ...state } = this.state;
        state[name] = data;
        this.setState({ ...state });
    };
    handleChangeInputs = ev => {
        this.handleChangeForm(ev.target.value, ev.target.name);
    };

    handlerCancelButton = () => {
        const { history } = this.props;
        history.push('/customers');
    };

    handlerSubmit = () => {
        const { route, updateCustomerRequest } = this.props;
        if (!route.isAdd) {
            updateCustomerRequest({ ...this.state });
        }
    };

    handleDelete = () => {
        const { deleteCustomerRequest } = this.props;
        deleteCustomerRequest({ ...this.state }, {onSuccess: () => this.getCustomerData});
        this.handleCloseDialog();
    };

    handleRestore = () => {
        const { restoreCustomerRequest } = this.props;
        restoreCustomerRequest({ ...this.state }, {onSuccess: () => this.getCustomerData});
        this.handleCloseDialog();
    };

    getCustomerData = () => {
        const {
            getCustomerRequest,
            route: { isAdd },
            match,
        } = this.props;
        if (!isAdd) {
            getCustomerRequest(match.params.id, {
                onSuccess: data =>
                {
                    this.setState({
                        ...data.data
                    })
                },
            });
        }
    };

    handleClickOpenDialog = () => {
        this.setState({open_dialog: true});
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };

    render() {
        const {
            classes,
            permissions,
            route: { isAdd },
        } = this.props;
        const {
            id, first_name, last_name, email, phone, is_deleted, open_dialog
        } = this.state;
        return (
            <div className={classes.root}>
                <FormCore
                    permissions={permissions}
                    handlerSubmit={this.handlerSubmit}
                    handlerCancelButton={this.handlerCancelButton}
                    title={`Customer ${isAdd ? 'creation' : 'edit'}  page`}
                    requiredMap={[
                        { title: 'Name', name: 'first_name' },
                        { title: 'Lastname', name: 'last_name' },
                        { title: 'Phone', name: 'phone' }
                    ]}
                >
                    <Grid container spacing={8} alignItems="center" justify="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Name"
                                name="first_name"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={first_name}
                                required
                                disabled={!permissions.update}
                            />

                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Last name"
                                name="last_name"
                                margin="normal"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={last_name}
                                required
                                disabled={!permissions.update}
                            />

                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Email"
                                margin="normal"
                                type="email"
                                name="email"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={email}
                                disabled={!permissions.update}
                            />

                            <TextField
                                id="standard-name"
                                variant="outlined"
                                label="Phone"
                                margin="normal"
                                name="phone"
                                onChange={this.handleChangeInputs}
                                classes={{ root: style.inputs }}
                                value={phone}
                                required
                                disabled={!permissions.update}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid item xs={6}>
                                <p>
                                    <Link
                                        onClick={() => window.open(`/orders/?filter={"user_id":"${id}"}`)}
                                    >
                                        Заявки пользователя
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        onClick={() => window.open(`/events/?filter={"user_id":"${id}","status_id":"3"}`)}
                                    >
                                        Подтвержденные
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        onClick={() => window.open(`/events/?filter={"user_id":"${id}","status_id":"4"}`)}
                                    >
                                        Завершенные
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        onClick={() => window.open(`/events/?filter={"user_id":"${id}","status_id":"5"}`)}
                                    >
                                        Отмененные клиентом
                                    </Link>
                                </p>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.handleClickOpenDialog}
                                >
                                    {is_deleted ? 'Restore' : 'Delete'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={open_dialog}
                        onClose={this.handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={is_deleted ? this.handleRestore : this.handleDelete} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormCore>
            </div>
        );
    }
}

Customer.propTypes = {
    history: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    deleteCustomerRequest: PropTypes.func.isRequired,
    restoreCustomerRequest: PropTypes.func.isRequired,
    updateCustomerRequest: PropTypes.func.isRequired,
    getCustomerRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};
Customer.defaultProps = {
    customerData: {
        id: 1,
        first_name: 'TEsts',
        last_name: 'testLastName',
        email: 'test@test.com',
        phone: '+375295298849'
    },
};

const mapStateToProps = state => ({
    customerData: customerDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
    addCustomerRequest: bindActionCreators(addCustomerRequest, dispatch),
    getCustomerRequest: bindActionCreators(getCustomerRequest, dispatch),
    deleteCustomerRequest: bindActionCreators(deleteCustomerRequest, dispatch),
    restoreCustomerRequest: bindActionCreators(restoreCustomerRequest, dispatch),
    updateCustomerRequest: bindActionCreators(updateCustomerRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Customer));
