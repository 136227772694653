export const ROLES_KEYS = Object.freeze({
    organizations: 'orgSuborg',
    roles: 'roles',
    customers: 'customers',
    bookings: 'bookings',
    tikets: 'tickets',
    users: 'users',
    alerts: 'alerts',
    skills: 'skills',
    orders: 'orders',
    events: 'events',
    feedbacks: 'feedbacks',
    statistics: 'statistics',
    pushes: 'pushes',
    chats: 'chats',
    adverts: 'adverts',
    faq: 'faq',
    salons: 'salons'
});
export const ROLES_NAMES = Object.freeze({
    orgSuborg: 'Organizations and Suborganizations',
    roles: 'Roles',
    customers: 'Customers',
    bookings: 'Bookings',
    tickets: 'Tickets',
    users: 'Users',
    alerts: 'Alerts',
    skills: 'Skills',
    orders: 'Orders',
    events: 'Events',
    feedbacks: 'Feedbacks',
    statitstics: 'Statitstics',
    pushes: 'Pushes',
    chats: 'Chats',
    adverts: 'Adverts',
    faq: 'Faq',
    salons: 'Salons'
});
