import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { skillsListSelector, getSkillsListRequest, deleteSkillRequest, getParentSkillsListRequest, parentSkillsListSelector } from 'modules/skills';
import { skillsDataPrepare } from './helpers';
import { getFitersDataFromUrl } from 'helpers';

const headRows = [
    { id: 'id', numeric: true, disablePadding: true, label: 'Id' },
    { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'parent_skill_id', numeric: true, disablePadding: false, label: 'Parent skill id' },
    { id: 'uid', numeric: false, disablePadding: false, label: 'Uid' }
];

class SkillsList extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    componentDidMount() {
        this.getParentSkillsData();
    }

    addSkillHandler = () => {
        const { history } = this.props;
        history.push('/create-skill');
    };

    editSkillHandler = id => {
        const { history } = this.props;
        history.push(`/skill/${id}`);
    };

    skillsListPrepare = (list, parentSkills) => {
        return skillsDataPrepare(list, parentSkills);
    };

    getParentSkillsData = () => {
        const {
            getParentSkillsListRequest,
        } = this.props;
        let data = {
            perPage: 100,
            parent: true
        };
        getParentSkillsListRequest(data, {
            onSuccess: data =>
            {
                this.setState({
                    ... data.data
                })
            },
        });
    };

    render() {
        const { skillsList, parentSkills, getSkillsListRequest, deleteSkillRequest, permissions } = this.props;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    updateListenerCallback={getSkillsListRequest}
                    filter={filter}
                    headRows={headRows}
                    rows={this.skillsListPrepare(skillsList.items, parentSkills.items)}
                    size={skillsList.size}
                    tableTitle={'Skills list'}
                    editEnable
                    deleteAction={deleteSkillRequest}
                    editClickCallback={this.editSkillHandler}
                    addButtonListener={this.addSkillHandler}
                    showEditHead={false}
                />
            </React.Fragment>
        );
    }
}

SkillsList.propTypes = {
    skillsList: PropTypes.object,
    parentSkills: PropTypes.object,
    getSkillsListRequest: PropTypes.func,
    getParentSkillsListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    deleteSkillRequest: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    skillsList: skillsListSelector(state),
    parentSkills: parentSkillsListSelector(state)
});

const mapDispatchToProps = dispatch => ({
    getSkillsListRequest: bindActionCreators(getSkillsListRequest, dispatch),
    getParentSkillsListRequest: bindActionCreators(getParentSkillsListRequest, dispatch),
    deleteSkillRequest: bindActionCreators(deleteSkillRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SkillsList);
