import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Chat as ChatDefault
} from 'components';

import { getChatsListSelector, getChatsListRequest, sendMessageRequest, getChatByIdRequest } from 'modules/chat';
import { getFitersDataFromUrl } from 'helpers';

class Chat extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }

    render() {
        const { history, match, permissions, sendMessageRequest, getChatByIdRequest } = this.props;
        const { filter } = this.state;

        return (
            <React.Fragment>
                <ChatDefault
                    history={history}
                    match={match}
                    filter={filter}
                    permissions={permissions}
                    sendMessageRequest={sendMessageRequest}
                    getChatByIdRequest={getChatByIdRequest}
                />
            </React.Fragment>
        );
    }
}

Chat.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    sendMessageRequest: PropTypes.func.isRequired,
    getChatByIdRequest: PropTypes.func.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    chatList: getChatsListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getChatsListRequest: bindActionCreators(getChatsListRequest, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Chat);
