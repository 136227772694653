import React from 'react';
import PropTypes from 'prop-types';
import Clear from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';

import get from 'lodash/get';
import { UserInfo } from 'components';

import ChatBox from './ChatCard.js';
import style from './style.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageUpload from './ImageUpload.js';
import {CHATS_DEFAULT_IDS} from 'config/chats';

const Chat = ({
    chatData,
    sendMessageRequest,
    deleteMessageRequest,
    chatId,
    currentUser,
    getChatByIdRequest,
    goBack,
}) => {
    const showLoaders = get(chatData, 'id') != chatId;
    const item = chatData;
    const FQ_BOT_IT = CHATS_DEFAULT_IDS.botId;
    React.useEffect(() => {
        if (chatId) {
            getChatByIdRequest(
                { id: chatId, showLoader: true },
                {
                    onSuccess: () => {},
                },
            );
        }
    }, []);
    const [message, changeMessage] = React.useState('');
    const [image, setImage] = React.useState('');

    const pushEnter = (ev) => {
        if (ev.which === 13 && ev.ctrlKey) {
            sendMessage();
            ev.preventDefault();
        }
    };

    const prepareUserLink = (user) => {
        let id = get(user, 'companion.id', 0);
        let is_master = get(user, 'companion.is_master', 0);
        return is_master ? '/master/' + id : '/customer/' + id;
    };

    const sendMessage = () => {
        let data;
        if (image) {
            data = {
                chatId: chatId,
                type: 'image',
                imageData: image,
            };
        } else {
            data = {
                chatId: chatId,
                type: 'message',
                text: message,
            };
        }
        sendMessageRequest(
            data,
            {
                onSuccess: () => {
                    getChatByIdRequest({ id: chatId });
                    changeMessage('');
                    setImage('');
                },
            },
        );
    };
    return (
        <div className={style.userCard}>
            <UserInfo
                id={
                    showLoaders ? '...' : get(item, 'companion.id', 0)
                }
                link={
                    showLoaders ? '...' : prepareUserLink(item)
                }
                firstName={
                    showLoaders ? '...' : get(item, 'companion.first_name', ' ')
                }
                avatarOnClick={() => {}}
                location={
                    <div>
                        <IconButton
                            className={style.expandButton}
                            size="small"
                            onClick={(ev) => {
                                goBack();
                                ev.stopPropagation();
                            }}
                        >
                            <Clear />
                        </IconButton>
                    </div>
                }
                lastName={
                    showLoaders ? '' : get(item, 'companion.last_name', ' ')
                }
                classNames={{
                    textBlock: style.userInfoContainerFull,
                    avatar: style.avatar,
                    location: style.expandButtonContainer,
                }}
                avatar={showLoaders ? undefined : get(item, 'companion.avatar')}
                last_visited_at={item.last_visit}
            >
                <div>
                    {showLoaders ? (
                        <CircularProgress />
                    ) : (
                        <ChatBox
                            currentUser={currentUser}
                            messages={get(chatData, 'messages', [])}
                            deleteMessageRequest={deleteMessageRequest}
                        />
                    )}
                </div>
            </UserInfo>
            {
                get(item, 'companion.id', 0) === FQ_BOT_IT ? '' :
                    (<div className={style.textSendContainer}>
                        <ImageUpload image={image} onChange={setImage}/>
                        <TextField
                            margin="dense"
                            onKeyPress={pushEnter}
                            id="outlined-multiline-flexible"
                            label=""
                            multiline
                            fullWidth
                            rowsMax="50"
                            variant="outlined"
                            onChange={(ev) => changeMessage(ev.target.value)}
                            value={message}
                        />
                        <div>
                            <IconButton onClick={sendMessage}>
                                <SendIcon htmlColor="rgb(67, 139, 194)"/>
                            </IconButton>
                        </div>
                    </div>)
            }
        </div>
    );
};

Chat.propTypes = {
    chatData: PropTypes.shape({
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        avatar: PropTypes.string,
    }),
    sendMessageRequest: PropTypes.func.isRequired,
    deleteMessageRequest: PropTypes.func.isRequired,
    chatId: PropTypes.number,
    currentUser: PropTypes.object,
    getChatByIdRequest: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
};

export default Chat;
