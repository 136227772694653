import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import UserInfoDefault from './UserInfo';
//import ShowImage from '../ShowImage';
const UserInfo = ({
    firstName,
    lastName,
    rating,
    location,
    userStatus,
    avatar,
    classNames,
    onClickAvatar,
    ...props
}) => {
    const [showAvatar, switchShowAvatar] = React.useState(false);
    const onClick = () => switchShowAvatar(!showAvatar);
    return (
        <Fragment>
            <UserInfoDefault
                firstName={firstName}
                lastName={lastName}
                rating={rating}
                location={location}
                userStatus={userStatus}
                avatar={avatar}
                classNames={classNames}
                avatarOnClick={onClickAvatar || onClick}
                {...props}
            />
        </Fragment>
    );
};
UserInfo.defaultProps = {
    firstName: '',
    lastName: '',
    rating: undefined,
    location: '',
    classNames: {
        container: '',
        status: '',
        name: '',
        location: '',
        rating: '',
        ratingText: '',
    },
    onClickAvatar: undefined,
};

UserInfo.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    location: PropTypes.string,
    userStatus: PropTypes.string,
    avatar: PropTypes.string,
    classNames: PropTypes.object,
    onClickAvatar: PropTypes.func,
};

export default UserInfo;
