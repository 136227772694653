import React from 'react';
import PropTypes from 'prop-types';
import EditSave from '../EidtSaveInput';
import ImageUpload from '../ImageUpload';
const Category = ({ data, onSave, onDelete }) => {
    const [edit, switchEdit] = React.useState(false);
    const [title, setTitle] = React.useState(data.title);
    const [url_path, setUrl] = React.useState(data.url_path);
    const [image, setImage] = React.useState(data.image_path);
    return (
        <div>
            <EditSave
                labelText="Название"
                onChange={data => {
                    setTitle(data);
                }}
                value={title}
                edit={edit}
                showDelete={false}
                onSwitchEdit={() => {
                    switchEdit(!edit);
                    if (edit) {
                        onSave({
                            title,
                            url_path,
                            image: image.match('base64') ? image : undefined,
                        });
                    }
                }}
            />
            <EditSave
                labelText="Ссылка на сайте"
                onChange={data => {
                    setUrl(data);
                }}
                value={url_path}
                showEdit={false}
                edit={edit}
                showDelete={false}
            />
            <ImageUpload disabled={!edit} image={image} text={title} onChange={setImage} />
        </div>
    );
};

Category.propTypes = {
    // : PropTypes.
};

export default Category;
