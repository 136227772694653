// ap/customers?page=0&size=10
import * as apiHelpers from 'api';
import { UPDATE_USER_REQUEST } from './user';
// import { createAction } from 'redux-actions';
// import { all, call, takeLatest, take, put, select, delay } from 'redux-saga/effects';
const modules = 'customers';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_CUSTOMERS_LIST_REQUEST = `${modules}/GET_CUSTOMERS_LIST_REQUEST`;
export const GET_CUSTOMER_REQUEST = `${modules}/GET_CUSTOMER_REQUEST`;
export const ADD_CUSTOMER_REQUEST = `${modules}/ADD_CUSTOMER_REQUEST`;
export const UPDATE_CUSTOMER_REQUEST = `${modules}/UPDATE_CUSTOMER_REQUEST`;
export const DELETE_CUSTOMER_REQUEST = `${modules}/DELETE_CUSTOMER_REQUEST`;
export const UNLOCK_CUSTOMER_REQUEST = `${modules}/UNLOCK_CUSTOMER_REQUEST`;
export const RESTORE_CUSTOMER_REQUEST = `${modules}/RESTORE_CUSTOMER_REQUEST`;
export const GET_CUSSTOMER_DEVICE_LIST_REQUEST = `${modules}/GET_CUSSTOMER_DEVICE_LIST_REQUEST`;

export const getCustomersListRequest = actionCreator(GET_CUSTOMERS_LIST_REQUEST);
export const getCustomerRequest = actionCreator(GET_CUSTOMER_REQUEST);
export const addCustomerRequest = actionCreator(ADD_CUSTOMER_REQUEST);
export const updateCustomerRequest = actionCreator(UPDATE_CUSTOMER_REQUEST);
export const deleteCustomerRequest = actionCreator(DELETE_CUSTOMER_REQUEST);
export const restoreCustomerRequest = actionCreator(RESTORE_CUSTOMER_REQUEST);

export const unlockCustomerRequest = actionCreator(UNLOCK_CUSTOMER_REQUEST);
export const getCustomerDevicesList = actionCreator(GET_CUSSTOMER_DEVICE_LIST_REQUEST);

apiRoutes.add(GET_CUSTOMERS_LIST_REQUEST, data => ({
    url: `admin/customers`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_CUSTOMER_REQUEST, data => ({
    url: `/users/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

// TODO: need prepare  form of request
apiRoutes.add(DELETE_CUSTOMER_REQUEST, data => {
    return {
        url: `admin/users/${data.id}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(UPDATE_CUSTOMER_REQUEST, data => ({
    url: `admin/users/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(RESTORE_CUSTOMER_REQUEST, data => ({
    url: `admin/users/${data.id}/restore`,
    method: 'post',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_CUSSTOMER_DEVICE_LIST_REQUEST, data => ({
    url: `/customers/${data}/devices`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const customerDataSelector = apiSelector(GET_CUSTOMER_REQUEST, {
    initialData: {
        name: '',
        surname: '',
        role: 1,
        group: 1,
        email: '',
    },
});

export const customersListSelector = apiSelector(GET_CUSTOMERS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
export const customersDevicesListSelector = apiSelector(GET_CUSSTOMER_DEVICE_LIST_REQUEST);
