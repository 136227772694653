import React from 'react';
import PropTypes from 'prop-types';
import EditSave from '../EidtSaveInput';

import style from './style.scss';

const Question = ({ data, categoryId, subCategoryId, onDeleteQuestion, onSaveQuestion }) => {
    const [question, setQuestion] = React.useState(data.question);
    const [answer, setAnswer] = React.useState(data.answer);
    const onSave = () => {
        if (!question || !answer) {
            alert('Заполните Вопрос и ответ');
            return;
        }
        onSaveQuestion({
            id_cat: categoryId,
            id_sub: subCategoryId,
            id_quest: data.id,
            question,
            answer,
        });
    };
    return (
        <div className={style.item} key={data.id}>
            <EditSave
                labelText="Вопрос:"
                edit={!question}
                value={question}
                onDelete={() =>
                    onDeleteQuestion({
                        id_cat: categoryId,
                        id_sub: subCategoryId,
                        id_quest: data.id,
                    })
                }
                onChange={setQuestion}
                onSave={onSave}
            />
            <EditSave
                labelText="Ответ:"
                openHtmlEdit
                value={answer}
                onChange={setAnswer}
                showDelete={false}
                onSave={onSave}
            />
        </div>
    );
};

Question.propTypes = {
    // : PropTypes.
};

export default Question;
