import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import {
    getOrderRequest,
    updateOrderRequest,
    orderDataSelector,
} from 'modules/orders';
import style from './styles.scss';

class Order extends React.Component {
    state = {
        open_dialog: false,
        status: 0
    };

    componentDidMount() {
        const {
            getOrderRequest,
            match,
        } = this.props;
        getOrderRequest(match.params.id, {
            onSuccess: data => this.setState(data.data),
        });
    }

    handlerSubmit = val => {
        const {updateOrderRequest, history} = this.props;
        updateOrderRequest({id: this.state.id, status: val}, { onSuccess: () => history.push('/orders') });
    };

    handleConfirm = () => {
        //confirmed
        this.setState({status: 3});
        this.handleClickOpenDialog();
    };

    handleUserReject = () => {
        //user reject
        this.setState({status: 5});
        this.handleClickOpenDialog();
    };

    handleMasterReject = () => {
        //master reject
        this.setState({status: 6});
        this.handleClickOpenDialog();
    };

    handleClickOpenDialog = () => {
        this.setState({open_dialog: true});
    };

    handleCloseDialog = () => {
        this.setState({open_dialog: false});
    };

    render() {
        const {
            orderData
        } = this.props;
        const {
            open_dialog,
            status
        } = this.state;
        return (
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Master id</TableCell>
                            <TableCell>Master name</TableCell>
                            <TableCell>Master phone</TableCell>
                            <TableCell>User id</TableCell>
                            <TableCell>User name</TableCell>
                            <TableCell>User phone</TableCell>
                            <TableCell>Salon</TableCell>
                            <TableCell>Skills</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">{orderData.id}</TableCell>
                            <TableCell component="th" scope="row">{orderData.date}</TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.master ? orderData.master.id : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.master ? (orderData.master.first_name + ' ') : ''}
                                {orderData.master ? orderData.master.last_name : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.user ? orderData.master.phone : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.user ? orderData.user.id : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.user ? (orderData.user.first_name + ' ') : ''}
                                {orderData.user ? orderData.user.last_name : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.user ? orderData.user.phone : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {orderData.salon ? orderData.salon.title + ' - ' + orderData.salon_id : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    orderData.skills
                                        ? orderData.skills.map(item => {
                                            return (<p key={item.title}>{item.title}</p>);
                                        }) : ''
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className={style.buttonsContainer}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleConfirm}
                                disabled={orderData.status_id !== 1}
                            >
                                Confirm
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handleMasterReject}
                                disabled={[4,5,6].includes(orderData.status_id)}
                            >
                                Reject Master
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                onClick={this.handleUserReject}
                                disabled={[4,5,6].includes(orderData.status_id)}
                            >
                                Reject User
                            </Button>
                        </Grid>
                        <Dialog
                            open={open_dialog}
                            onClose={this.handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={this.handleCloseDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={() => this.handlerSubmit(status)} color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </div>
            </div>
        );
    }
}

Order.propTypes = {
    updateOrderRequest: PropTypes.func.isRequired,
    getOrderRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    ordersRoles: PropTypes.shape({
        items: PropTypes.array,
        size: PropTypes.string,
    }),
    orderData: PropTypes.object,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    orderData: orderDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getOrderRequest: bindActionCreators(getOrderRequest, dispatch),
    updateOrderRequest: bindActionCreators(updateOrderRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);
