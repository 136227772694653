// ap/users?page=0&size=10
//
import * as apiHelpers from 'api';
// import { createAction } from 'redux-actions';
// import { all, call, takeLatest, take, put, select, delay } from 'redux-saga/effects';
const modules = 'users';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_USERS_LIST_REQUEST = `${modules}/GET_USERS_LIST_REQUEST`;
export const GET_USER_REQUEST = `${modules}/GET_USER_REQUEST`;
export const ADD_USER_REQUEST = `${modules}/ADD_USER_REQUEST`;
export const UPDATE_USER_REQUEST = `${modules}/UPDATE_USER_REQUEST`;
export const UPDATE_USER_ADDRESS_REQUEST = `${modules}/UPDATE_USER_ADDRESS_REQUEST`;
export const UPDATE_USER_PROFILE_REQUEST = `${modules}/UPDATE_USER_PROFILE_REQUEST`;
export const DELETE_USERS_REQUEST = `${modules}/DELETE_USERS_REQUEST`;
export const DELETE_USER_REQUEST = `${modules}/DELETE_USER_REQUEST`;
export const RESTORE_USER_REQUEST = `${modules}/RESTORE_USER_REQUEST`;
export const UNLOCK_USER_REQUEST = `${modules}/UNLOCK_USER_REQUEST`;
export const RESET_PASSWORD_REQUEST = `${modules}/RESET_PASSWORD_REQUEST`;
export const GET_LIST_SKILLS_REQUEST = `${modules}/GET_LIST_SKILLS_REQUEST`;
export const DELETE_SKILL_REQUEST = `${modules}/DELETE_SKILL_REQUEST`;
export const UPDATE_SKILL_REQUEST = `${modules}/UPDATE_SKILL_REQUEST`;
export const ATTACH_SKILL_REQUEST = `${modules}/ATTACH_SKILL_REQUEST`;
export const DELETE_AVATAR_REQUEST = `${modules}/DELETE_AVATAR_REQUEST`;
export const DELETE_PORTFOLIO_IMAGE_REQUEST = `${modules}/DELETE_PORTFOLIO_IMAGE_REQUEST`;
export const UP_PORTFOLIO_IMAGE_REQUEST = `${modules}/UP_PORTFOLIO_IMAGE_REQUEST`;
export const CHANGE_TYPE__PORTFOLIO_IMAGE_REQUEST = `${modules}/CHANGE_TYPE__PORTFOLIO_IMAGE_REQUEST`;
export const CHANGE_PORTFOLIO_TAGS_REQUEST = `${modules}/CHANGE_PORTFOLIO_TAGS_REQUEST`;

export const getUsersListRequest = actionCreator(GET_USERS_LIST_REQUEST);
export const unLockUserRequest = actionCreator(UNLOCK_USER_REQUEST);
export const getUserRequest = actionCreator(GET_USER_REQUEST);
export const addUserRequest = actionCreator(ADD_USER_REQUEST);
export const updateUserRequest = actionCreator(UPDATE_USER_REQUEST);
export const updateUserAddressRequest = actionCreator(UPDATE_USER_ADDRESS_REQUEST);
export const updateUserProfileRequest = actionCreator(UPDATE_USER_PROFILE_REQUEST);
export const deleteUsersRequest = actionCreator(DELETE_USERS_REQUEST);
export const deleteUserRequest = actionCreator(DELETE_USER_REQUEST);
export const restoreUserRequest = actionCreator(RESTORE_USER_REQUEST);
export const resetPasswordRequest = actionCreator(RESET_PASSWORD_REQUEST);
export const getListSkillsRequest = actionCreator(GET_LIST_SKILLS_REQUEST);
export const deleteSkillRequest = actionCreator(DELETE_SKILL_REQUEST);
export const updateSkillRequest = actionCreator(UPDATE_SKILL_REQUEST);
export const attachSkillRequest = actionCreator(ATTACH_SKILL_REQUEST);
export const deleteAvatarRequest = actionCreator(DELETE_AVATAR_REQUEST);
export const deletePortfolioImageRequest = actionCreator(DELETE_PORTFOLIO_IMAGE_REQUEST);
export const upPortfolioImageRequest = actionCreator(UP_PORTFOLIO_IMAGE_REQUEST);
export const changeTypePortfolioImageRequest = actionCreator(CHANGE_TYPE__PORTFOLIO_IMAGE_REQUEST);
export const changePortfolioTagsRequest = actionCreator(CHANGE_PORTFOLIO_TAGS_REQUEST);

apiRoutes.add(RESET_PASSWORD_REQUEST, data => ({
    url: `users/${data.userId}/reset-password`,
    method: 'get',
    // data, //{ userId:}
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_USERS_LIST_REQUEST, data => {
    if (
        data.hasOwnProperty('orderBy') &&
        data.hasOwnProperty('order') &&
        data.hasOwnProperty('dense') &&
        data.orderBy === 'id' &&
        data.order === 'asc'
    ) {
        //set default order as id desc
        data.order = 'desc';
    }

    return {
        url: `admin/masters`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true }
    };
});

apiRoutes.add(GET_USER_REQUEST, data => ({
    url: `/users/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UNLOCK_USER_REQUEST, data => ({
    url: `/users/${data}/unlock`,
    method: 'patch',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_USER_REQUEST, data => {
    return {
        url: `admin/users/${data.id}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(RESTORE_USER_REQUEST, data => ({
    url: `admin/users/${data.id}/restore`,
    method: 'post',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_USERS_REQUEST, data => {
    return {
        url: `/users/${data.id}`,
        method: 'delete',
        params: { id: data },
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(ADD_USER_REQUEST, data => ({
    url: `/users`,
    method: 'post',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_USER_REQUEST, data => ({
    url: `admin/users/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_USER_ADDRESS_REQUEST, data => ({
    url: `admin/users/${data.id}/address`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_USER_PROFILE_REQUEST, data => ({
    url: `admin/users/${data.id}/profile`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_LIST_SKILLS_REQUEST, data => ({
    url: `skills`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_SKILL_REQUEST, data => ({
    url: `admin/users/${data.user_id}/skills/${data.skill_id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_SKILL_REQUEST, (data) => ({
    url: `admin/users/${data.user_id}/skills/${data.skill_id}`,
    method: 'delete',
}));

apiRoutes.add(ATTACH_SKILL_REQUEST, data => ({
    url: `admin/users/${data.user_id}/skills/`,
    method: 'post',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_AVATAR_REQUEST, (data) => ({
    url: `admin/users/${data.id}/avatar`,
    method: 'delete',
}));

apiRoutes.add(DELETE_PORTFOLIO_IMAGE_REQUEST, (data) => ({
    url: `admin/users/${data.user_id}/images/${data.image_id}`,
    method: 'delete',
}));

apiRoutes.add(UP_PORTFOLIO_IMAGE_REQUEST, (data) => ({
    url: `admin/users/${data.user_id}/images/${data.image_id}/up`,
    method: 'post',
}));

apiRoutes.add(CHANGE_TYPE__PORTFOLIO_IMAGE_REQUEST, (data) => ({
    url: `admin/users/${data.user_id}/images/${data.image_id}/type`,
    method: 'post',
}));

apiRoutes.add(CHANGE_PORTFOLIO_TAGS_REQUEST, data => ({
    url: `admin/users/${data.user_id}/portfolio/${data.portfolio_id}/tags`,
    method: 'post',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

export const userDataSelector = apiSelector(GET_USER_REQUEST, {
    initialData: {
        name: '',
        surname: '',
        role: 1,
        group: 1,
        email: '',
    },
});

export const usersListSelector = apiSelector(GET_USERS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});

export const getListSkillsSelector = apiSelector(GET_LIST_SKILLS_REQUEST, {});