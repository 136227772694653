import { addValueToReactComponent } from 'components/Table';
import { get } from 'lodash';

export const eventsDataPrepare = list => {
    return Array.isArray(list)
        ? list.map(item => {
            const newItem = { ...item };
            const master_name = get(item, 'master.first_name', '');
            const master_last_name = get(item, 'master.last_name', '');
            const master_phone = get(item, 'master.phone', '');

            const user_name = get(item, 'user.first_name', '');
            const user_last_name = get(item, 'user.last_name', '');
            const user_phone = get(item, 'user.phone', '');
            const salonId = item.salon_id ? get(item, 'salon.title', '') + '-' + item.salon_id : '';
            let user_fee = 0;
            if (item.current_charges) {
                    user_fee = get(item, 'current_charges.amount', 0) *
                        get(item, 'current_charges.fee_percent', 0) / 100;
            }
            if (item.processed_charges) {
                    user_fee = get(item, 'processed_charges.amount', 0) *
                            get(item, 'processed_charges.fee_percent', 0) / 100;
            }

            if (item.declined_charges) {
                    user_fee = get(item, 'declined_charges.amount', 0) *
                        get(item, 'declined_charges.fee_percent', 0) / 100;
            }

            newItem.master = addValueToReactComponent(
                master_name + (master_last_name ? ' ' + master_last_name : ''),
                newItem.master
            );
            newItem.master_phone = addValueToReactComponent(
                master_phone,
                newItem.master_phone
            );
            newItem.user = addValueToReactComponent(
                user_name + (user_last_name ? ' ' + user_last_name : ''),
                newItem.user
            );
            newItem.user_phone = addValueToReactComponent(
                user_phone,
                newItem.user_phone
            );

            newItem.user_fee = addValueToReactComponent(
                user_fee,
                newItem.user_fee
            );

            newItem.salon_id = addValueToReactComponent(
                salonId,
                newItem.salon_id
            );

            return newItem;
        }) : [];
};
