import React from 'react';
import PropTypes from 'prop-types';
import MessageCard from './MessageCard';

import style from './style.scss';
const ChatBox = ({ messages = [], currentUser, deleteMessageRequest }) => {
    const handleClick = () => {};
    const [needScroll, setNeedScroll] = React.useState(true);
    React.useEffect(() => {
        if (messages.length > 0) {
            const lastMessage = messages[messages.length - 1];
            const elem = document.querySelector(
                `#message_${lastMessage && lastMessage.id}`,
            );
            if (elem && needScroll) {
                setNeedScroll(false);
                setTimeout(() => {
                    elem.scrollIntoView({ block: 'end', behavior: 'smooth' });
                }, 500);
            }
        }
    }, [messages]);
    return (
        <div>
            <div className={style.chatContainer} onClick={handleClick}>
                <div className={style.offset}>
                    _______________________________________________________
                </div>
                {messages.map((item) => (
                    <MessageCard
                        id={'message_' + item.id}
                        currentUserId={currentUser.id}
                        key={item.id}
                        message={item}
                        deleteMessageRequest={deleteMessageRequest}
                    />
                ))}
            </div>
        </div>
    );
};

ChatBox.propTypes = {
    messages: PropTypes.array.isRequired,
    deleteMessageRequest: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
};

export default ChatBox;
