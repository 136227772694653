import * as apiHelpers from 'api';
// import { createAction } from 'redux-actions';
// import { all, call, takeLatest, take, put, select, delay } from 'redux-saga/effects';
const modules = 'adverts';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_ADVERTS_LIST_REQUEST = `${modules}/GET_ADVERTS_LIST_REQUEST`;
export const GET_ADVERT_REQUEST = `${modules}/GET_ADVERT_REQUEST`;
export const ADD_ADVERT_REQUEST = `${modules}/ADD_ADVERT_REQUEST`;
export const UPDATE_ADVERT_REQUEST = `${modules}/UPDATE_ADVERT_REQUEST`;
export const DELETE_ADVERT_REQUEST = `${modules}/DELETE_ADVERT_REQUEST`;
export const APPROVE_ADVERT_REQUEST = `${modules}/APPROVE_ADVERT_REQUEST`;
export const DECLINE_ADVERT_REQUEST = `${modules}/decline_ADVERT_REQUEST`;
export const DELETE_ADVERTS_REQUEST = `${modules}/DELETE_ADVERTS_REQUEST`;

export const getAdvertsListRequest = actionCreator(GET_ADVERTS_LIST_REQUEST);
export const getAdvertRequest = actionCreator(GET_ADVERT_REQUEST);
export const addAdvertRequest = actionCreator(ADD_ADVERT_REQUEST);
export const updateAdvertRequest = actionCreator(UPDATE_ADVERT_REQUEST);
export const deleteAdvertRequest = actionCreator(DELETE_ADVERT_REQUEST);
export const approveAdvertRequest = actionCreator(APPROVE_ADVERT_REQUEST);
export const declineAdvertRequest = actionCreator(DECLINE_ADVERT_REQUEST);
export const deleteAdvertsRequest = actionCreator(DELETE_ADVERTS_REQUEST);

apiRoutes.add(GET_ADVERTS_LIST_REQUEST, data => ({
    url: `admin/a_posts`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(GET_ADVERT_REQUEST, data => ({
    url: `admin/a_posts/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(DELETE_ADVERT_REQUEST, data => {
    return {
        url: `admin/a_posts/${data.id}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(DELETE_ADVERTS_REQUEST, data => {
    return {
        url: `admin/a_posts/`,
        method: 'delete',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(APPROVE_ADVERT_REQUEST, data => {
    return {
        url: `admin/a_posts/${data.id}/approve`,
        method: 'post',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(DECLINE_ADVERT_REQUEST, data => {
    return {
        url: `admin/a_posts/${data.id}/decline`,
        method: 'post',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(UPDATE_ADVERT_REQUEST, data => {
    let newData = {};
    newData.text = data.text;
    return {
        url: `admin/a_posts/${data.id}`,
        method: 'put',
        data: newData,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

export const advertDataSelector = apiSelector(GET_ADVERT_REQUEST, {
    initialData: {
        id: '',
        text: '',
        user_id: '',
        type: {
            id: '',
            type: '',
            title: '',
        }
    },
});

export const advertsListSelector = apiSelector(GET_ADVERTS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
