import * as apiHelpers from 'api';
const modules = 'events';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_EVENTS_LIST_REQUEST = `${modules}/GET_EVENTS_LIST_REQUEST`;
export const GET_EVENT_REQUEST = `${modules}/GET_EVENT_REQUEST`;
export const UPDATE_EVENT_REQUEST = `${modules}/UPDATE_EVENT_REQUEST`;
export const DELETE_EVENT_REQUEST = `${modules}/DELETE_EVENT_REQUEST`;

export const getEventsListRequest = actionCreator(GET_EVENTS_LIST_REQUEST);
export const getEventRequest = actionCreator(GET_EVENT_REQUEST);
export const updateEventRequest = actionCreator(UPDATE_EVENT_REQUEST);
export const deleteEventRequest = actionCreator(DELETE_EVENT_REQUEST);

apiRoutes.add(GET_EVENTS_LIST_REQUEST, data => {
    data.statuses = [2,3,4,5,6];
    return {
        url: `/admin/events`,
        method: 'get',
        params: data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(GET_EVENT_REQUEST, data => ({
    url: `/events/${data}`,
    method: 'get',
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(UPDATE_EVENT_REQUEST, data => ({
    url: `/admin/events/${data.id}`,
    method: 'put',
    data: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

// TODO
apiRoutes.add(DELETE_EVENT_REQUEST, data => {
    return {
        url: `/orders/${data}`,
        method: 'delete',
        params: { id: data },
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});


export const eventDataSelector = apiSelector(GET_EVENT_REQUEST, {
    initialData: {
        id: '',
        user_id: '',
        status_id: '',
        master_id: '',
        date: '',
        duration: '',
        address: '',
        lat: '',
        long: '',
        salon_id: '',
    },
});

export const eventsListSelector = apiSelector(GET_EVENTS_LIST_REQUEST, {
    initialData: {
        items: [],
        size: 0,
    },
});
