import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getStatisticsRequest, statisticsDataSelector} from 'modules/statistics';
import { colors, Grid, Typography } from '@material-ui/core';
import color from '@material-ui/core/colors/red';

class Statistics extends PureComponent {
    componentDidMount() {
        const {
            getStatisticsRequest,
        } = this.props;
        getStatisticsRequest();
    }
    render() {
        const { statistics} = this.props;
        return (
            <React.Fragment>
                <Grid item xs={12} md={12} container>
                    <Grid item xs={12} md={12}>
                        <h2>Payment</h2>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>Belarus</strong>
                        </Typography>
                        <Typography>
                            <strong>Russia</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Pending</h4>
                        </Typography>
                        <Typography>
                            {statistics.payments.commission.pending.bel}
                        </Typography>
                        <Typography>
                            {statistics.payments.commission.pending.rus}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Payed monthly</h4>
                        </Typography>
                        <Typography>
                            {statistics.payments.commission.payed.bel.monthly}
                        </Typography>
                        <Typography>
                            {statistics.payments.commission.payed.rus.monthly}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Payed all time</h4>
                        </Typography>
                        <Typography>
                            {statistics.payments.commission.payed.bel.yearly}
                        </Typography>
                        <Typography>
                            {statistics.payments.commission.payed.rus.yearly}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} container>
                    <Grid item xs={12} md={12}>
                        <h2>Users</h2>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>Count</strong>
                        </Typography>
                        <Typography>
                            <strong>Percent</strong>
                        </Typography>
                        <Typography>
                            <strong>New</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>All</h4>
                        </Typography>
                        <Typography>
                            {statistics.users.all.count}
                        </Typography>
                        <Typography>
                            -
                        </Typography>
                        <Typography>
                            {statistics.users.all.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Masters:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.masters.count}
                        </Typography>
                        <Typography >
                            {statistics.users.masters.percent}%
                        </Typography>
                        <Typography >
                            {statistics.users.masters.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Clients:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.clients.count}
                        </Typography>
                        <Typography >
                            {statistics.users.clients.percent}%
                        </Typography>
                        <Typography >
                            {statistics.users.clients.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <h2>Russian Users</h2>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>Count</strong>
                        </Typography>
                        <Typography>
                            <strong>Percent</strong>
                        </Typography>
                        <Typography>
                            <strong>New</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>All</h4>
                        </Typography>
                        <Typography>
                            {statistics.users.all.russia.count}
                        </Typography>
                        <Typography>
                            -
                        </Typography>
                        <Typography>
                            {statistics.users.all.russia.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Masters:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.masters.russia.count}
                        </Typography>
                        <Typography >
                            {statistics.users.masters.russia.percent}%
                        </Typography>
                        <Typography >
                            {statistics.users.masters.russia.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Clients:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.clients.russia.count}
                        </Typography>
                        <Typography >
                            {statistics.users.clients.russia.percent}%
                        </Typography>
                        <Typography >
                            {statistics.users.clients.russia.new.count}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} container>
                    <Grid item xs={12} md={12}>
                        <h2>Advanced statistics of users</h2>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <h5>Incomplete registrations count:</h5>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <h3>{statistics.users.all.incomplete_registrations.count}</h3>
                    </Grid>
                    <Grid item xs={12} md={4}/>
                    <Grid item xs={12} md={8}>
                        <h3>Russia</h3>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>New in previous day</strong>
                        </Typography>
                        <Typography>
                            <strong>New in 2 previous days</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>All</h4>
                        </Typography>
                        <Typography>
                            {statistics.users.all.russia.previous_day.new.count}
                        </Typography>
                        <Typography>
                            {statistics.users.all.russia.previous_2days.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Masters:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.masters.russia.previous_day.new.count}
                        </Typography>
                        <Typography >
                            {statistics.users.masters.russia.previous_2days.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Clients:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.clients.russia.previous_day.new.count}
                        </Typography>
                        <Typography >
                            {statistics.users.clients.russia.previous_2days.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}/>
                    <Grid item xs={12} md={8}>
                        <h3>Belarus</h3>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>New in previous day</strong>
                        </Typography>
                        <Typography>
                            <strong>New in 2 previous days</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>All</h4>
                        </Typography>
                        <Typography>
                            {statistics.users.all.belarus.previous_day.new.count}
                        </Typography>
                        <Typography>
                            {statistics.users.all.belarus.previous_2days.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Masters:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.masters.belarus.previous_day.new.count}
                        </Typography>
                        <Typography >
                            {statistics.users.masters.belarus.previous_2days.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Clients:</h4>
                        </Typography>
                        <Typography >
                            {statistics.users.clients.belarus.previous_day.new.count}
                        </Typography>
                        <Typography >
                            {statistics.users.clients.belarus.previous_2days.new.count}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} container>
                    <Grid item xs={12} md={12}>
                        <h2>Events</h2>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>Count</strong>
                        </Typography>
                        <Typography>
                            <strong>New</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>All</h4>
                        </Typography>
                        <Typography>
                            {statistics.events.all.count}
                        </Typography>
                        <Typography>
                            {statistics.events.all.new.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Requests:</h4>
                        </Typography>
                        <Typography >
                            {statistics.events.requests.count}
                        </Typography>
                        <Typography >
                            -
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Completed:</h4>
                        </Typography>
                        <Typography >
                            {statistics.events.completed.count}
                        </Typography>
                        <Typography >
                            {statistics.events.completed.new.count}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} container>
                    <Grid item xs={12} md={12}>
                        <h2>Adverts</h2>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>-</h4>
                        </Typography>
                        <Typography>
                            <strong>Count</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>All</h4>
                        </Typography>
                        <Typography>
                            {statistics.adverts.all.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>Active:</h4>
                        </Typography>
                        <Typography >
                            {statistics.adverts.active.count}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            <h4>New:</h4>
                        </Typography>
                        <Typography >
                            {statistics.adverts.new.count}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

Statistics.propTypes = {
    statistics: PropTypes.object,
    getStatisticsRequest: PropTypes.func,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    statistics: statisticsDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getStatisticsRequest: bindActionCreators(getStatisticsRequest, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Statistics);
