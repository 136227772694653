import React from 'react';
import PropTypes from 'prop-types';
import style from './message.scss';
import { Delete, Done, DoneAll } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import history from 'store/history';
import { ConfirmationDialog } from 'components';
const MessageCard = ({ message, currentUserId, id , deleteMessageRequest}) => {
    const handleClick = () => {};
    const [open_dialog, setOpenDialog] = React.useState(false);
    const isCurrentUser = currentUserId === message.sender_id;

    const handleDelete = (chatId, messageId) => {
        setOpenDialog(false);
        deleteMessageRequest({chatId, messageId}, { onSuccess: () => history.push('/chats/' + chatId) });
    };
    return (
        <React.Fragment>
            <div>
                <div
                    className={[
                        style.container,
                        isCurrentUser ? style.myMessage : '',
                    ].join(' ')}
                    onClick={handleClick}
                >
                    {message.type.type === 'message' ?
                        (
                            <span className={style.textSpan}>
                                {message.text}
                            </span>
                        ) :
                        (
                            <img src={message.image_data} alt="" width={"200px"}/>
                        )
                    }
                    <span>
                        {
                            isCurrentUser ?
                            <IconButton
                                aria-label="Delete"
                                onClick={() => setOpenDialog(true)}
                            >
                                <Delete/>
                            </IconButton> : ''
                        }
                    </span>
                    <span className={style.textSpan}>
                        {isCurrentUser ?
                            (message.is_read ?
                                <DoneAll/>: <Done/>) : ''
                        }
                    </span>
                </div>
            </div>
            <div
                id={id}
                className={isCurrentUser ? style.myDate : style.hisDate}
            >
                {message.created_at}
            </div>
            <ConfirmationDialog
                open_dialog={open_dialog}
                cancelFunc={() => setOpenDialog(false)}
                confirmationFunc={() => handleDelete(message.chat_id, message.id)}
            />
        </React.Fragment>
    );
};

MessageCard.propTypes = {
    currentUserId: PropTypes.object,
    deleteMessageRequest: PropTypes.func.isRequired,
    message: PropTypes.shape({
        text: PropTypes.string,
        sender_id: PropTypes.number,
        created_at: PropTypes.string,
        image_data: PropTypes.string,
        chat_id: PropTypes.number,
        id: PropTypes.number
    }),
    id: PropTypes.string.isRequired,
};

export default MessageCard;
