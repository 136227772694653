// it is modeule use for init data

import { createAction } from 'redux-actions';
import { all, takeEvery, put, select, delay } from 'redux-saga/effects';
import { getmyPermissionsRequest } from './roles';
import { checkUserAuth, saveAuthToPersist, checkPasswordReset } from './auth';

const modules = 'init';
export const INIT_ACTION = `${modules}INIT_ACTION`;
export const initAction = createAction(INIT_ACTION);

export function* initActionsSaga() {
    //HACK use delay for pause  for read persist
    yield delay(800);
    const userIsAuth = yield select(checkUserAuth);
    const needPasswordReset = yield select(checkPasswordReset);
    if (userIsAuth && !needPasswordReset) {
        //yield put(getmyPermissionsRequest());
    }
}

export function* initSaga() {
    yield all([
        takeEvery(saveAuthToPersist, initActionsSaga),
        takeEvery(initAction, initActionsSaga),
    ]);
}
