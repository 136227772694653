import * as apiHelpers from 'api';
// import { createAction } from 'redux-actions';
import { all, call, takeLatest, take, put, select, delay } from 'redux-saga/effects';
const modules = 'faqs';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

const apiRoutes = new ApiRoutes();

export const GET_FAQS_LIST_REQUEST = `${modules}/GET_FAQS_LIST_REQUEST`;
export const GET_FAQS_CATEGORIES_LIST_REQUEST = `${modules}/GET_FAQS_CATEGORIES_LIST_REQUEST`;
export const ADD_FAQ_CATEGORY_REQUEST = `${modules}/ADD_FAQ_CATEGORY_REQUEST`;
export const UPDATE_FAQ_CATEGORY_REQUEST = `${modules}/UPDATE_FAQ_CATEGORY_REQUEST`;
export const DELETE_FAQ_CATEGORY_REQUEST = `${modules}/DELETE_FAQ_CATEGORY_REQUEST`;
export const GET_SUBCATEGORY_REQUEST = `${modules}/GET_SUBCATEGORY_REQUEST`;
export const ADD_SUBCATEGORY_REQUEST = `${modules}/ADD_SUBCATEGORY_REQUEST`;
export const UPDATE_FAQ_SUBCATEGORY_REQUEST = `${modules}/UPDATE_FAQ_SUBCATEGORY_REQUEST`;
export const DELETE_FAQ_SUBCATEGORY_REQUEST = `${modules}/DELETE_FAQ_SUBCATEGORY_REQUEST`;
export const GET_QUESTIONS_REQUEST = `${modules}/GET_QUESTIONS_REQUEST`;
export const UPDATE_FAQ_QUESTION_REQUEST = `${modules}/UPDATE_FAQ_QUESTION_REQUEST`;
export const ADD_QUESTIONS_REQUEST = `${modules}/ADD_QUESTIONS_REQUEST`;
export const DELETE_FAQ_QUESTION_REQUEST = `${modules}/DELETE_FAQ_QUESTION_REQUEST`;

export const ADD_FAQ_CATEGORY_SUCCESS = `${modules}/ADD_FAQ_CATEGORY_SUCCESS`;

export const UPDATE_FAQ_CATEGORY_SUCCESS = `${modules}/UPDATE_FAQ_CATEGORY_SUCCESS`;

export const DELETE_FAQ_CATEGORY_SUCCESS = `${modules}/DELETE_FAQ_CATEGORY_SUCCESS`;

export const ADD_SUBCATEGORY_SUCCESS = `${modules}/ADD_SUBCATEGORY_SUCCESS`;

export const UPDATE_FAQ_SUBCATEGORY_SUCCESS = `${modules}/UPDATE_FAQ_SUBCATEGORY_SUCCESS`;

export const DELETE_FAQ_SUBCATEGORY_SUCCESS = `${modules}/DELETE_FAQ_SUBCATEGORY_SUCCESS`;

export const UPDATE_FAQ_QUESTION_SUCCESS = `${modules}/UPDATE_FAQ_QUESTION_SUCCESS`;

export const ADD_QUESTIONS_SUCCESS = `${modules}/ADD_QUESTIONS_SUCCESS`;

export const DELETE_FAQ_QUESTION_SUCCESS = `${modules}/DELETE_FAQ_QUESTION_SUCCESS`;

export const getFaqsListRequest = actionCreator(GET_FAQS_LIST_REQUEST);
export const getFaqsCategoriesListRequest = actionCreator(GET_FAQS_CATEGORIES_LIST_REQUEST);
export const addFaqCategoryRequest = actionCreator(ADD_FAQ_CATEGORY_REQUEST);
export const updateFaqCategoryRequest = actionCreator(UPDATE_FAQ_CATEGORY_REQUEST);
export const deleteFaqCategoryRequest = actionCreator(DELETE_FAQ_CATEGORY_REQUEST);

export const updateFaqSubCategoryRequest = actionCreator(UPDATE_FAQ_SUBCATEGORY_REQUEST);
export const addFaqSubCategoryRequest = actionCreator(ADD_SUBCATEGORY_REQUEST);
export const deleteFaqSubCategoryRequest = actionCreator(DELETE_FAQ_SUBCATEGORY_REQUEST);

export const updateFaqQuestionRequest = actionCreator(UPDATE_FAQ_QUESTION_REQUEST);
export const addFaqQuestionRequest = actionCreator(ADD_QUESTIONS_REQUEST);
export const deleteQuestionRequest = actionCreator(DELETE_FAQ_QUESTION_REQUEST);

apiRoutes.add(GET_FAQS_LIST_REQUEST, data => ({
    url: `/faq`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));
apiRoutes.add(GET_FAQS_CATEGORIES_LIST_REQUEST, data => ({
    url: `/faq/categories`,
    method: 'get',
    params: data,
    headers: { Authorization: true, 'x-mock-match-request-body': true },
}));

apiRoutes.add(ADD_FAQ_CATEGORY_REQUEST, data => {
    return {
        url: `admin/faq/categories`,
        method: 'post',
        data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(UPDATE_FAQ_CATEGORY_REQUEST, ({ id, ...data }) => {
    return {
        url: `admin/faq/categories/${id}`,
        method: 'put',
        data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(DELETE_FAQ_CATEGORY_REQUEST, ({ id }) => {
    return {
        url: `admin/faq/categories/${id}`,
        method: 'delete',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

apiRoutes.add(ADD_SUBCATEGORY_REQUEST, ({ id_cat, ...data }) => {
    return {
        url: `admin/faq/categories/${id_cat}/sub_categories`,
        method: 'post',
        data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(DELETE_FAQ_SUBCATEGORY_REQUEST, ({ id, id_cat }) => {
    return {
        url: `admin/faq/categories/${id_cat}/sub_categories/${id}`,
        method: 'DELETE',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(UPDATE_FAQ_SUBCATEGORY_REQUEST, ({ id_cat, id, ...data }) => {
    return {
        url: `admin/faq/categories/${id_cat}/sub_categories/${id}`,
        method: 'put',
        data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(ADD_QUESTIONS_REQUEST, ({ id_sub, id_cat, ...data }) => {
    return {
        url: `admin/faq/categories/${id_cat}/sub_categories/${id_sub}/questions`,
        method: 'post',
        data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(UPDATE_FAQ_QUESTION_REQUEST, ({ id_sub, id_cat, id_quest, ...data }) => {
    return {
        url: `admin/faq/categories/${id_cat}/sub_categories/${id_sub}/questions/${id_quest}`,
        method: 'put',
        data,
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});
apiRoutes.add(DELETE_FAQ_QUESTION_REQUEST, ({ id_cat, id_sub, id_quest }) => {
    return {
        url: `admin/faq/categories/${id_cat}/sub_categories/${id_sub}/questions/${id_quest}`,
        method: 'DELETE',
        headers: { Authorization: true, 'x-mock-match-request-body': true },
    };
});

function* initActionsSaga() {
    yield put(getFaqsListRequest());
    //HACK use delay for pause  for read persist
}

export function* faqSaga() {
    yield all([
        takeLatest(
            [
                ADD_FAQ_CATEGORY_SUCCESS,
                UPDATE_FAQ_CATEGORY_SUCCESS,
                DELETE_FAQ_CATEGORY_SUCCESS,
                ADD_SUBCATEGORY_SUCCESS,
                UPDATE_FAQ_SUBCATEGORY_SUCCESS,
                DELETE_FAQ_SUBCATEGORY_SUCCESS,
                UPDATE_FAQ_QUESTION_SUCCESS,
                ADD_QUESTIONS_SUCCESS,
                DELETE_FAQ_QUESTION_SUCCESS,
            ],
            initActionsSaga
        ),
    ]);
}

export const faqsListSelector = apiSelector(GET_FAQS_LIST_REQUEST);
export const faqsCategoriesListSelector = apiSelector(GET_FAQS_CATEGORIES_LIST_REQUEST);
