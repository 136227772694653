import React from 'react';
import { addValueToReactComponent } from 'components/Table';
import Icon from '@material-ui/core/Icon';
import {get} from 'lodash';

export const customersDatePrepare = (list = []) => {
    const arr = Array.isArray(list)
        ? list.map(item => {
            const newItem = { ...item };
            const deviceType = get(item, 'device_token.device_type_id', 0);

            newItem.is_deleted = addValueToReactComponent(
                item.is_deleted ? <Icon>done</Icon> : '',
                newItem.is_deleted
            );
            newItem.device_token = addValueToReactComponent(
                deviceType === 1 ? 'ios' : deviceType === 2 ? 'android' : '',
                newItem.device_token
            );
            return newItem;
        })
        : [];
    return arr;
};