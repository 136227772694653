import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks';
import sidebarStyle from 'assets/jss/material-dashboard-react/components/sidebarStyle';
import sidebar from 'config/sidebar';

const Sidebar = ({ ...props }) => {
    function activeRoute(routeName, path) {
        return path === routeName ? true : false;
    }
    const {
        classes,
        color,
        logo,
        image,
        logoText,
        match: { path },
    } = props;
    let links = (
        <List className={classes.list}>
            {sidebar.map((prop, key) => {
                let activePro = ' ';
                let listItemClasses;
                listItemClasses = classNames({
                    [' ' + classes[color]]: activeRoute(prop.to, path),
                });
                const whiteFontClasses = classNames({
                    [' ' + classes.whiteFont]: activeRoute(prop.to, path),
                });
                return (
                    <NavLink
                        to={prop.to}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            {typeof prop.icon === 'string' ? (
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive,
                                    })}
                                >
                                    {prop.icon}
                                </Icon>
                            ) : (
                                <prop.icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive,
                                    })}
                                />
                            )}
                            <ListItemText
                                primary={prop.title}
                                className={classNames(classes.itemText, whiteFontClasses)}
                                disableTypography
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    let brand = (
        <div className={classes.logo}>
            <NavLink to="/" className={classNames(classes.logoLink)}>
                {/*<div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>*/}
                FeelQueen
            </NavLink>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={props.rtlActive ? 'left' : 'right'}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive,
                        }),
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        <AdminNavbarLinks userLogout={props.userLogout} />
                        {links}
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: 'url(' + image + ')' }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor="right"
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper),
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: 'url(' + image + ')' }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    userLogout: PropTypes.func.isRequired,
    color: PropTypes.string,
    match: PropTypes.shape({ path: PropTypes.string }),
};

export default withStyles(sidebarStyle)(Sidebar);
