import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Add, Delete, Edit, Save} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import style from '../../styles.scss';
import NativeSelect from '@material-ui/core/NativeSelect';

const UserSkillsRow = ({
    row, creation, deleteSkillRequest, updateSkillRequest, skillsList, attachSkillRequest
}) => {
    const [isEdition, setIsEdition] = useState(false);
    const [from, setFrom] = useState(row.from);
    const [to, setTo] = useState(row.to);
    const [duration, setDuration] = useState(row.duration);
    const [skill_id, setSkillId] = useState();

    const handleDelete = (user_id, skill_id) => {
        deleteSkillRequest({user_id, skill_id});
    };

    const handleEdit = () => {
        setIsEdition(true)
    };

    const handleChangeInputs = ev => {
        if (ev.target.name === 'from') {
            setFrom(ev.target.value);
        } else if (ev.target.name === 'to') {
            setTo(ev.target.value);
        } else if (ev.target.name === 'duration') {
            setDuration(ev.target.value);
        }
    };

    const handleSave = (user_id, skill_id) => {
        updateSkillRequest({user_id, skill_id, to, from, duration});
        setIsEdition(false)
    };

    const handleAddSkill = (user_id) => {
        attachSkillRequest({user_id, skill_id, to, from, duration});
        setSkillId('');
        setDuration('');
        setTo('');
        setFrom('');
    };

    const handleChangeSelect = (event) => {
        setSkillId(event.target.value);
    };

    return creation ?
        (
            <TableRow key={row.id} hidden>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">
                    <NativeSelect
                        onChange={handleChangeSelect}
                        value={skill_id}
                    >
                        <option value="" />
                        {skillsList.map(row => (
                            <optgroup label={row.title} key={row.id}>
                                {row.sub_skills.map(skill => (
                                    <option value={skill.id} key={skill.id}>{skill.title}</option>
                                ))}
                            </optgroup>
                        ))}
                    </NativeSelect>
                </TableCell>
                <TableCell align="center" size={'small'}>
                    <TextField
                        variant="outlined"
                        name="from"
                        margin="normal"
                        type="number"
                        onChange={handleChangeInputs}
                        classes={{ root: style.inputs }}
                        value={from}
                        required
                        size={'small'}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        variant="outlined"
                        name="to"
                        margin="normal"
                        type="number"
                        onChange={handleChangeInputs}
                        classes={{ root: style.inputs }}
                        value={to}
                        required
                        size={'small'}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        variant="outlined"
                        name="duration"
                        margin="normal"
                        type="number"
                        onChange={handleChangeInputs}
                        classes={{ root: style.inputs }}
                        value={duration}
                        required
                        size={'small'}
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton>
                        <Add onClick={() => handleAddSkill(row.user_id)}/>
                    </IconButton>
                </TableCell>
            </TableRow>
        )
        :
        (
        <TableRow key={row.id}>
            <TableCell align="center">{row.title}</TableCell>
            <TableCell align="center">{row.description}</TableCell>
            <TableCell align="center">{row.parent}</TableCell>
            <TableCell align="center">
                {
                    isEdition ?
                        <TextField
                            variant="outlined"
                            name="from"
                            margin="normal"
                            type="number"
                            onChange={handleChangeInputs}
                            classes={{ root: style.inputs }}
                            value={from}
                            size={'small'}
                        /> : from
                }
            </TableCell>
            <TableCell align="center">
                {
                    isEdition ?
                        <TextField
                            variant="outlined"
                            name="to"
                            margin="normal"
                            type="number"
                            onChange={handleChangeInputs}
                            classes={{ root: style.inputs }}
                            value={to}
                            size={'small'}
                        /> : to
                }
            </TableCell>
            <TableCell align="center">
                {
                    isEdition ?
                        <TextField
                            variant="outlined"
                            name="duration"
                            margin="normal"
                            type="number"
                            onChange={handleChangeInputs}
                            classes={{ root: style.inputs }}
                            value={duration}
                            size={'small'}
                        /> : duration
                }
            </TableCell>
            <TableCell align="center">
                <IconButton>
                    {
                        isEdition ? <Save onClick={() => handleSave(row.user_id, row.skill_id)}/>
                            : <Edit onClick={() => handleEdit()}/>
                    }
                </IconButton>
                <IconButton
                    aria-label="Delete"
                    onClick={() => handleDelete(row.user_id, row.skill_id)}
                >
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
        );
};
UserSkillsRow.propTypes = {
    row: PropTypes.object.isRequired,
    creation: PropTypes.bool.isRequired,
    deleteSkillRequest: PropTypes.func.isRequired,
    updateSkillRequest: PropTypes.func.isRequired,
    attachSkillRequest: PropTypes.func.isRequired,
    skillsList: PropTypes.array.isRequired
};
export default UserSkillsRow;