import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useDispatch, useSelector } from 'react-redux';

import {
    faqsListSelector,
    getFaqsListRequest,
    addFaqSubCategoryRequest,
    deleteFaqCategoryRequest,
    // getFaqsCategoriesListRequest,
} from 'modules/faq';
import { faqsDataPrepare } from './Prepare.js';
import { getFitersDataFromUrl } from 'helpers';

const headRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'id', isId: true },
    { id: 'title', numeric: false, disablePadding: false, label: 'Категория' },

    {
        id: 'sub_categories',
        numeric: false,
        disablePadding: false,
        sort: false,
        label: 'Подкатегория',
    },
];

const FaqsList = ({ location: { search }, history, permissions }) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getFaqsListRequest());
    }, []);
    const faqsList = useSelector(faqsListSelector);
    const [faqs, setFaqs] = React.useState([...faqsList]);
    React.useEffect(() => {
        setFaqs([...faqsList]);
    }, [faqsList]);
    const [filter] = React.useState(getFitersDataFromUrl(search));
    const faqsListPrepare = list => {
        return faqsDataPrepare({ list, dispatch });
    };

    const addFaqHandler = () => {
        setFaqs([
            ...faqs,
            {
                id: faqs.length + 1,
                empty: true,
                title: '',
                image: '',
                url_path: '',
                sub_categories: [],
            },
        ]);
    };

    return (
        <React.Fragment>
            <Table
                deleteAction={id => {
                    console.log(id);
                    dispatch(deleteFaqCategoryRequest({ id }));
                }}
                editEnable={false}
                permissions={permissions}
                showEditHead={false}
                filter={filter}
                updateListenerCallback={getFaqsListRequest}
                headRows={headRows}
                rows={faqsListPrepare(faqs) || []}
                size={faqsList.length}
                tableTitle={'Faqs list'}
                addButtonListener={addFaqHandler}
            />
        </React.Fragment>
    );
};

FaqsList.propTypes = {
    faqsList: PropTypes.object,
    getFaqsListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

export default FaqsList;
