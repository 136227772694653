import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ordersListSelector, getOrdersListRequest} from 'modules/orders';
import { ordersDataPrepare } from './helpers';
import { getFitersDataFromUrl } from 'helpers';

const headRows = [
    { id: 'id', numeric: false, disablePadding: false, label: 'id', isId: true },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'city', numeric: false, disablePadding: false, label: 'City' },
    { id: 'master_id', numeric: false, disablePadding: false, label: 'Master id' },
    { id: 'master', numeric: false, disablePadding: false, sort:false, label: 'Master' },
    { id: 'master_phone', numeric: false, disablePadding: false, sort:false, label: 'Master phone' },
    { id: 'user_id', numeric: false, disablePadding: false, label: 'User id' },
    { id: 'user', numeric: false, disablePadding: false, sort:false, label: 'User' },
    { id: 'user_phone', numeric: false, disablePadding: false, sort:false, label: 'User phone' },
    { id: 'salon_id', numeric: false, disablePadding: false, label: 'Salon' },
    { id: 'user_fee', numeric: true, disablePadding: false, sort:false, label: 'FEE' },
    { id: 'updated_at', numeric: false, disablePadding: false, sort:true, label: 'Updated date' },
];

class OrdersList extends PureComponent {
    constructor(props) {
        super(props);
        const {
            location: { search },
        } = props;
        this.state = { filter: getFitersDataFromUrl(search) };
    }
    ordersListPrepare = list => {
        return ordersDataPrepare(list);
    };
    editOrderHandler = id => {
        const { history } = this.props;
        history.push(`/order/${id}`);
    };
    render() {
        const { ordersList, getOrdersListRequest, permissions } = this.props;
        const { filter } = this.state;
        return (
            <React.Fragment>
                <Table
                    permissions={permissions}
                    filter={filter}
                    updateListenerCallback={getOrdersListRequest}
                    headRows={headRows}
                    rows={this.ordersListPrepare(ordersList.items)}
                    size={ordersList.size}
                    tableTitle={'Orders list'}
                    editEnable
                    editClickCallback={this.editOrderHandler}
                />
            </React.Fragment>
        );
    }
}

OrdersList.propTypes = {
    ordersList: PropTypes.object,
    getOrdersListRequest: PropTypes.func,
    history: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
        create: PropTypes.bool,
        update: PropTypes.bool,
        read: PropTypes.bool,
        delete: PropTypes.bool,
    }),
};

const mapStateToProps = state => ({
    ordersList: ordersListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    getOrdersListRequest: bindActionCreators(getOrdersListRequest, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersList);
