import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EditSave from '../EidtSaveInput';
import Grid from '@material-ui/core/Grid';
import Questions from '../Questions';
import style from './style.scss';
const SubCategory = ({
    items,
    categoryId,
    onSave,
    onDelete,
    onAddSubCategory,
    onSaveQuestion,
    onDeleteQuestion,
    onAddQuestion,
}) => {
    const [subcategories, setSabCategory] = React.useState([...items]);
    React.useEffect(() => {
        setSabCategory([...items]);
    }, [items]);
    return (
        <Fragment>
            {subcategories.map(i => (
                <Grid key={i.id} className={style.container} container>
                    <Grid xs={4}>
                        <EditSave
                            edit={!i.title}
                            onSave={(data, dispatch) => {
                                if (!i.title) {
                                    onAddSubCategory({
                                        id_cat: categoryId,
                                        title: data,
                                    });
                                } else {
                                    onSave({ title: data, id: i.id }, dispatch);
                                }
                            }}
                            onDelete={() => {
                                if (i.empty) {
                                    setSabCategory([
                                        ...subcategories.filter(item => item.id !== i.id),
                                    ]);
                                } else {
                                    onDelete({
                                        id_cat: categoryId,
                                        id: i.id,
                                    });
                                }
                            }}
                            value={i.title}
                        />
                    </Grid>
                    <Grid xs={8}>
                        <Questions
                            subCategoryId={i.id}
                            categoryId={categoryId}
                            items={i.questions_answers}
                            onSaveQuestion={onSaveQuestion}
                            onDeleteQuestion={onDeleteQuestion}
                            onAddQuestion={onAddQuestion}
                        />
                    </Grid>
                </Grid>
            ))}
            <button
                className={style.button}
                onClick={() => {
                    setSabCategory([
                        ...subcategories,
                        {
                            empty: true,
                            id: subcategories.length + 1,
                            id_cat: categoryId,
                            title: '',
                        },
                    ]);
                }}
            >
                Добавить подкатегорию
            </button>
        </Fragment>
    );
};

SubCategory.propTypes = {
    // : PropTypes.
};

export default SubCategory;
