import { routerReducer } from 'react-router-redux';
import { persistCombineReducers } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import localForage from 'localforage';
import * as apiHelpers from 'api';
import notoficationReducer from '../notifications';
import { authReducer } from '../auth';
import { loaderReducer } from '../loader';

const saveUserInfo = createFilter('auth', ['credentials', 'user']);

const {
    modules: { apiDefaultReducer },
} = apiHelpers;

const persistConfig = {
    key: 'root',
    storage: localForage,
    version: 0,
    whitelist: ['auth'],
    transforms: [saveUserInfo],
};

export default persistCombineReducers(persistConfig, {
    api: apiDefaultReducer,
    router: routerReducer,
    notification: notoficationReducer,
    auth: authReducer,
    loader: loaderReducer,
});
