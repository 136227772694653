import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

const CheckboxDialog = (props) => {
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <React.Fragment>
            <input
                type="checkbox"
                name="check"
                checked={!props.active}
                onChange={() => {setOpenDialog(true)}}
            />
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">Cancel</Button>
                    <Button onClick={() => props.setValue({ ...props.options, hidden: !props.active}, {onSuccess: () => {setOpenDialog(false); window.location.reload()}})} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

CheckboxDialog.propTypes = {
    active: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
};

export default CheckboxDialog;